import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col } from 'reactstrap';

class ProductItem extends Component {
    render() {
        const { nombre, descripcion, portada, url } = this.props;

        return (
            <Col md={4} className="product-item">
                <div className="product-item__container">
                    {typeof url === 'string' && url.includes('http') ?
                        <a href={url} target="_blank" id={nombre}>
                            <img src={portada} className="product-item__icon" alt={nombre} />
                        </a>
                        :
                        <Link to={typeof url === 'undefined' ? '#' : url} id={nombre}>
                            <img src={portada} className="product-item__icon" alt={nombre} />
                        </Link>
                    }
                    <p className="product-item__title"><span className="lato-light">{nombre}</span><strong></strong></p>
                    <div className="product-item__info" dangerouslySetInnerHTML={{ __html: descripcion }}></div>
                    {typeof url === 'string' && url.includes('http')
                        ?
                        <a className="product-item__more button" href={url} target="_blank" id={nombre}>Más info</a>
                        :
                        <Link className="product-item__more button" to={typeof url === 'undefined' ? '#' : url} id={nombre}>Más Info</Link>
                    }

                </div>
            </Col>
        );
    }
}

export default ProductItem;