/* Icono Parque */
import icon from './../../../../assets/images/Parques/IconIquique.png';
/* Logos Iconos Jardines Temáticos */
import sendero from './../../../../assets/images/Parques/icon/sendero.png'; // Sendero Tradicional
import familia from './../../../../assets/images/Parques/icon/familia.png';  // Nuestra Familia
import familiar from './../../../../assets/images/Parques/icon/familiar.png';  // Nuestra Familia
import privado from './../../../../assets/images/Parques/icon/privado.png'; // Jardin Privado
import noble from './../../../../assets/images/Parques/icon/noble.png'; // Jardín Noble
/* Fotos Jardines Temáticos */
import img_sendero from './../../../../assets/images/Parques/Iquique/img_sendero.jpg'; // Img Sendero Tradicional
import img_familia from './../../../../assets/images/Parques/Iquique/img_familia.jpg';  // Img Nuestra Familia
//import img_familiar from './../../../../assets/images/Parques/Iquique/familiar_400x300.png';  // Img Tradición Familiar

import img_privado from './../../../../assets/images/Parques/Iquique/img_privado.jpg'; // Img Jardin Privado

import img_familiar_new from './../../../../assets/images/Parques/new/img_jardin_familiar.png';  // Img Tradición Familiar
import img_privado_new from './../../../../assets/images/Parques/new/new_jardin_privado_17182023.png';
import img_noble from './../../../../assets/images/Parques/new/new_jardin_noble_17082023.png'; // Img Jardín Noble

export default {
    icon: icon,
    carouselItems: [
        {
            image: img_sendero,
            logo: sendero,
            text: 'Es un espacio que cuenta con grandes áreas verdes enmarcadas por hermosas arboleda.',
            nombre: 'Parque del Sendero',
            link: ''
        },
        {
            image: img_familia,
            logo: familia,
            text: 'Un área diseñada especialmente para recibir y proteger sus recuerdos y ofrendas.',
            nombre: 'Nuestra Familia',
            link: ''
        },
        {
            image: img_familiar_new,
            logo: familiar,
            text: 'Un área diseñado para estar en contacto con la naturaleza. La hermosa fuente de agua que rodea el espacio permite una tranquilidad y conexión con sus seres queridos.',
            nombre: 'Tradición Familiar',
            link: ''
        },
        /*{
            image: img_privado,
            logo: privado,
            text: 'Un espacio privilegiado donde se resguarda la memoria y el descanso de sus seres queridos.',
            nombre: 'Jardín Privado',
            link: ''
        },
        {
            image: img_noble,
            logo: noble,
            text: 'Conmemore con elegancia y distinción el recuerdo de sus seres queridos. El espacio es resguardado por un cierre perimetral de fierro, con puerta y escaño para uso exclusivo de su familia.',
            nombre: 'Jardín Noble',
            link: ''
        }*/
        {
            image: img_privado_new,
            logo: privado,
            text: 'Un espacio privilegiado donde se resguarda la memoria y el descanso de sus seres queridos.',
            nombre: 'Jardín Privado',
            link: ''
        },
        {
            image: img_noble,
            logo: noble,
            text: 'Conmemore con elegancia y distinción el recuerdo de sus seres queridos. El espacio es resguardado por un cierre perimetral de fierro, con puerta y escaño para uso exclusivo de su familia.',
            nombre: 'Jardín Noble',
            link: ''
        },
    ],

    jardinesTematicos: [
        sendero,
        familia,
        noble,
        privado
    ],
}





