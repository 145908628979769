import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

import Vineta from './../Assets/Images/vineta_up.png';

import Layout from './../Layout/';
import Api from './../../service/Api';

class PreguntasFrecuentes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: 0
        }
        this.toggle = this.toggle.bind(this);
    }
    componentWillMount() {
        this.getPreguntasFrecuentes();
    }
    async getPreguntasFrecuentes() {
        let response = await Api.getPreguntasFrecuentes();
        this.setState({
            dat_collapse: response
        })
    }
    toggle(e, tab) {
        e.preventDefault();
        if (this.state.collapse !== tab) {
            this.setState({
                collapse: tab
            });
        } else {
            this.setState({
                collapse: 0
            });
        }
    }

    render() {
        const { dat_collapse } = this.state;
        return (
            <>
                <Layout name="Preguntas Frecuentes">
                    <div>

                        {typeof dat_collapse !== 'undefined' && dat_collapse.map((item, index) => (
                            <div className="content-collapse-preguntas" key={index}>
                                <div onClick={(e) => { this.toggle(e, index + 1) }} className={(parseInt(this.state.collapse) === (index + 1) ? 'open-collapse' : '') + ' text-list'}>
                                    {item.name}
                                    <img src={Vineta} className="float-right icon-list" alt="" />
                                </div>
                                <Collapse isOpen={parseInt(this.state.collapse) === (index + 1) ? true : false}>
                                    <div className="collapse-contenido">
                                        <p>
                                            {ReactHtmlParser(item.description)}
                                        </p>
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                    </div>
                </Layout>
            </>
        );
    }
}
export default PreguntasFrecuentes;