import React, { Component } from "react";
import { Collapse, Row, Col, Container } from "reactstrap";
import ReactHtmlParser from 'react-html-parser';

import Vineta from "./../../assets/images/vineta_up.png";

class CollapseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colapse: 0,
    };
  }

  toggle(e, tab) {
    e.preventDefault();
    if (this.state.collapse !== tab) {
      this.setState({
        collapse: tab,
      });
    } else {
      this.setState({
        collapse: 0,
      });
    }
  }
  render() {
    const { data } = this.props;
    return (
      <>
        <Container>
          <Row className="collapse-info">
            {data.length > 0
              ? data.map((item, index) => (
                  <Col sm="12" className="col-collapse" key={index}>
                    <div
                      onClick={(e) => {
                        this.toggle(e, index + 1);
                      }}
                      className={
                        "collap " +
                        (parseInt(this.state.collapse) === index + 1
                          ? "open"
                          : "close")
                      }
                    >
                      {item.name}
                      <img
                        src={Vineta}
                        className="float-right icon-list"
                        alt=""
                      />
                    </div>
                    <Collapse
                      isOpen={
                        parseInt(this.state.collapse) === index + 1
                          ? true
                          : false
                      }
                      className="content"
                    >
                      {ReactHtmlParser(item.description)}
                    </Collapse>
                  </Col>
                ))
              : null}
          </Row>
        </Container>
      </>
    );
  }
}
export default CollapseDetail;
