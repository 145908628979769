import React, { useState } from "react";
import Api from "../../../service/Api";
import ciudad from "./../../Data/Ciudad.json";
import { Col, Row, Spinner } from "reactstrap";

const Step1 = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [sendForm, setSendForm] = useState(false);
  const [dstep1, setDStep1] = useState({
    Parque: props.type === "cremacion" ? "" : "Arica",
    Ext: "+56",
    Telefono: "",
    Terms: true,
    type: props.type,
    completed: false,
    Formulario: props.type === "cremacion" ? "Cremacion" : "Sepultura",
  });

  const [viewErrorTerms, setviewErrorTerms] = useState(true);

  const numbers = [
    { Ext: "+56", name: "+56" },
    { Ext: "+54", name: "+54" },
    { Ext: "+591", name: "+591" },
    { Ext: "+55", name: "+55" },
    { Ext: "+57", name: "+57" },
    { Ext: "+593", name: "+593" },
    { Ext: "+595", name: "+595" },
    { Ext: "+51", name: "+51" },
    { Ext: "+598", name: "+598" },
  ];

  const isValidated = async () => {
    setSendForm(true);
    // if (viewErrorEmail === true) {
    //   return false;
    // }

    // if(props.type === 'sepultura') {
    //   if(viewErrorTerms === false){
    //   return false;
    //   }
    // }

    if (
      dstep1.Telefono.trim().length <= 4 ||
      dstep1.Telefono.trim() === "111111111" ||
      dstep1.Telefono.trim() === "222222222" ||
      dstep1.Telefono.trim() === "333333333" ||
      dstep1.Telefono.trim() === "444444444" ||
      dstep1.Telefono.trim() === "555555555" ||
      dstep1.Telefono.trim() === "666666666" ||
      dstep1.Telefono.trim() === "777777777" ||
      dstep1.Telefono.trim() === "888888888" ||
      dstep1.Telefono.trim() === "999999999" ||
      dstep1.Telefono.trim() === "000000000" ||
      dstep1.Telefono.trim() === "123456789"
    ) {
      return false;
    }

    let bretorno = false;
    setLoading(true);
    // props.wizardData = { ...props.wizardData, ...dstep1 };

    setDStep1({ ...dstep1, completed: true });

    try {
      const response = await Api.CotizarLeads(dstep1);
      localStorage.setItem("numeroLead", response.result);
      localStorage.setItem("Ext", dstep1.Ext);
      localStorage.setItem("Telefono", dstep1.Telefono);
      localStorage.setItem("Parque", dstep1.Parque);
      localStorage.setItem("type", dstep1.type);
      localStorage.setItem("Terms", dstep1.Terms);
      localStorage.setItem("Formulario", dstep1.Formulario);
      bretorno = true;
    } catch (error) {
      console.log(error);
      console.log(dstep1);
      localStorage.setItem("numeroLead", 0);
      bretorno = false;
    } finally {
      setLoading(false);
    }

    bretorno = true;
    return bretorno;
  };

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    setWizardData: dstep1,
  }));

  return (
    <>
      {loading === true && (
        <div className="row">
          <div className="col-md-12 mb-3 text-center">
            <Spinner />
          </div>
        </div>
      )}
      {loading === false && (<>
        <div className="row">
          {props.type === "sepultura" && (
            <div className="col-md-12 mb-3">
              <label htmlFor="">Parque</label>
              <select
                name="Parque"
                id="Parque"
                className="form-control"
                style={{ borderRadius: "0.25rem" }}
                onChange={(e) => {
                  setDStep1({ ...dstep1, Parque: e.currentTarget.value });
                }}
                value={dstep1.Parque || ""}
              >
                {ciudad.map((item, index) => (
                  <option value={item.name} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          </div>
          <Row className="row-form">
            <Col xs={12} sm={12} md={3}>
              {/* <input className="form-control text-center" type={"text"} name={"Ext"} id={"Ext"} placeholder={"+56"}  value={"+56"} disabled/> */}
              <label>Teléfono</label>
              <select
                name="Ext"
                id="Ext"
                className="form-control"
                style={{ borderRadius: "0.25rem" }}
                onChange={(e) => {
                  setDStep1({ ...dstep1, Ext: e.currentTarget.value });
                }}
                // value={setDStep2.Ext || ''}
              >
                {numbers.map((item, index) => (
                  <option value={item.Ext} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={12} sm={12} md={9}>
              <label></label>
              <input
                className="form-control mt-2"
                type={"tel"}
                name={"Telefono"}
                id={"Telefono"}
                placeholder={"Ej: 987654321"}
                maxLength="9"
                pattern="[0-9]+"
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (
                    e.target.value === "" ||
                    regex.test(e.currentTarget.value)
                  ) {
                    setDStep1({ ...dstep1, Telefono: e.currentTarget.value });
                  }
                }}
                value={dstep1.Telefono}
              />
              {(dstep1.Telefono.trim() === "" ||
                dstep1.Telefono.trim().length <= 4 ||
                dstep1.Telefono.trim() === "111111111" ||
                dstep1.Telefono.trim() === "222222222" ||
                dstep1.Telefono.trim() === "333333333" ||
                dstep1.Telefono.trim() === "444444444" ||
                dstep1.Telefono.trim() === "555555555" ||
                dstep1.Telefono.trim() === "666666666" ||
                dstep1.Telefono.trim() === "777777777" ||
                dstep1.Telefono.trim() === "888888888" ||
                dstep1.Telefono.trim() === "999999999" ||
                dstep1.Telefono.trim() === "000000000" ||
                dstep1.Telefono.trim() === "123456789") &&
                sendForm && (
                  <>
                    <p class="text-danger">
                      Debe Ingresar un número de teléfono válido.
                    </p>
                  </>
                )}
            </Col>
          </Row>
          <div className="row">
            <div className="col-md-12">
              <label class="container">
                <input
                  type="checkbox"
                  name="Terms"
                  checked={viewErrorTerms}
                  onChange={(e) => {
                    setDStep1({ ...dstep1, Terms: e.currentTarget.checked });
                    setviewErrorTerms(!viewErrorTerms);
                  }}
                />
                <span class="checkmark"></span>{" "}
                <span className="cls_accept_terms">Acepto Publicidad</span>
              </label>
              {/* {viewErrorTerms === false && (<>
                <p class="text-danger" style={{marginTop: '20px'}}>Debe Aceptar Publicidad.</p>
              </>)} */}
            </div>
           </div>
      </>)}
    </>
  );
});

export default Step1;
