import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Session from '../../service/Session';
import Api from '../../service/Api';
import { HashLink as Link } from 'react-router-hash-link';

import LogoSendero from './../Assets/Images/LogoSendero.png'
import ResponseCodeTransbank from './../../components/responseCodeTransbank.jsx';

class End extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buyOrder: 0,
            detalle: [],
            pago: [],
        }
        this.getDetalle = this.getDetalle.bind(this);
    }
    componentDidMount() {
        this.getDetalle();
    }
    async getDetalle() {
        const { match } = this.props;
        const { params } = match;
        let data = {};
        data.buyOrder = params.buy_order;
        let response = await Api.getDetalle(data);
        this.setState({ detalle: response.detalle, pago: response.pago, buyOrder: params.buy_order });
        console.info(response)
    }
    FormatDate(fecha, type) {
        let date = new Date(fecha)
        if (type === 'date') {
            return (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());
        } else {
            let min = date.getMinutes();
            console.info(min < 10)
            return (date.getHours() + ":" + (min < 10 ? '0' + min : min));
        }
    }
    TraduccionTypeCode(nomenclatura) {
        let val = '';
        switch (nomenclatura) {
            case 'VD':
                val = 'Venta Débito';
                break;
            case 'VP':
                val = 'Venta Prepago';
                break;
            case 'VN':
                val = 'Venta Normal (Sin Cuotas)';
                break;
            case 'VC':
                val = 'Venta Con Cuotas (con interés Banco)';
                break;
            case 'SI':
                val = '3 Cuotas sin interés';
                break;
            case 'S2':
                val = '2 Cuotas sin interés';
                break;
            case 'NC':
                val = 'N Cuotas sin interés';
                break;
            default:
                val = 'Sin Descripción';
                break;
        }

        return val;
    }

    render() {
        const { detalle, pago, buyOrder } = this.state;
        console.info(this.state)
        const { location } = this.props;
        const { search } = location;
        const coder = new URLSearchParams(search).get('code');
        return (
            <>
                {detalle.length > 0 && pago.length > 0 ? (
                    <Container>
                        <Row>
                            <Col className="text-center">
                                <img src={LogoSendero} className="logoSendero-detalle" alt="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-title-detalle text-center">
                                    <h2>COMPROBANTE DE PAGO</h2>
                                    <div className="text-center">Estimado (a) {Session.getInfoUser().usuario}, Junto con saludarle adjuntamos su comprobante electrónico que registra el pago de su cuenta en Mi Sendero</div>
                                </div>
                                <div>
                                    <p className="mis-datos"><strong>Mis Datos</strong></p>
                                </div>
                                <div className="back-detalle">
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <th>Nombre Cliente : </th>
                                                <td>{Session.getInfoUser().usuario}</td>
                                            </tr>
                                            <tr>
                                                <th>Rut Cliente : </th>
                                                <td>{Session.getInfoUser().rut}</td>
                                            </tr>
                                            <tr>
                                                <th>Fecha : </th>
                                                <td>{this.FormatDate(pago[0]?.transactiondate, 'date')}</td>
                                            </tr>
                                            <tr>
                                                <th>Hora Transacción : </th>
                                                <td>{this.FormatDate(pago[0]?.transactiondate, 'hours')}</td>
                                            </tr>
                                            <tr>
                                                <th>N° Transacción : </th>
                                                <td>{pago[0].buyorder}</td>
                                            </tr>
                                            <tr>
                                                <th>Código de Transacción : </th>
                                                <td>{pago[0].authorizationcode}</td>
                                            </tr>
                                            <tr>
                                                <th>4 últimos dígito tarjeta : </th>
                                                <td>{pago[0].cardNumber}</td>
                                            </tr>
                                            <tr>
                                                <th>Número Cuotas : </th>
                                                <td>{pago[0].installmentsNumber}</td>
                                            </tr>
                                            <tr>
                                                <th>Monto Total : </th>
                                                <td>${new Intl.NumberFormat().format(pago[0].amount)}</td>
                                            </tr>
                                            <tr>
                                                <th>Tipo de Pago : </th>
                                                <td>{this.TraduccionTypeCode(pago[0].paymenttypecode)}</td>
                                            </tr>
                                            <tr>
                                                <th>Forma de Pago : </th>
                                                <td>Transbank</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className="table-detalle">
                                    <thead>
                                        <tr>
                                            <th>N° Contrato</th>
                                            <th>Tipo</th>
                                            <th>Cuota</th>
                                            <th>Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detalle.map((item, index) => (
                                            item.monto_cuota > 0 ? (
                                                <tr key={index}>
                                                    <td>{item.numero_contrato}</td>
                                                    <td>{item.concepto_recaudacion}</td>
                                                    <td>{item.numero_cuota}</td>
                                                    <td>${new Intl.NumberFormat().format(item.monto_cuota)}</td>
                                                </tr>
                                            ) : null
                                        ))}
                                    </tbody>
                                </table>
                            </Col><br /><br />
                        </Row>
                    </Container>
                ) : (
                    <Container>
                        <Row>
                            <Col className="text-center">
                                <img src={LogoSendero} className="logoSendero-detalle" alt="" />
                            </Col>
                        </Row>
                        <ResponseCodeTransbank code={coder} buyorder={buyOrder} />
                        <Row>
                            <Col>
                                <br />
                                <Link to={'/mi-sendero/servicios-contratados'} className="btn btn-verde button btn btn-secondary">Volver</Link>
                            </Col>
                        </Row>
                    </Container>
                )}
            </>
        );
    }
}
export default End;