import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ParquePage from './Page/ParquePage';
import Parque from './Page/';
// Assets
import infoParqueMaipu from './Page/data/maipu';
import infoParqueSanBernardo from './Page/data/san-bernardo';
import infoParqueArica from './Page/data/arica';
import infoParqueIquique from './Page/data/iquique';
import infoParqueVillaAlemana from './Page/data/villa-alemana';
import infoParqueQuilpue from './Page/data/quilpue';
import infoParqueValparaiso from './Page/data/valparaiso';
import infoParqueSanAntonio from './Page/data/san-antonio';
import infoParqueRancagua from './Page/data/rancagua';
import infoParqueConcepcion from './Page/data/concepcion';
import infoParqueTemuco from './Page/data/temuco';
import NotFound from '../404/NotFound';

const renderContent = (props) => {
    const Path = props.match.path;
    return (
        <Switch>
            <Route exact path={Path} component={Parque} />
            <Route exact path={Path + "arica/"} component={() => (<ParquePage info={infoParqueArica} slug={"arica"} />)} />
            <Route exact path={Path + "iquique/"} component={() => (<ParquePage info={infoParqueIquique} slug={"iquique"} />)} />
            <Route exact path={Path + "villa-alemana/"} component={() => (<ParquePage info={infoParqueVillaAlemana} slug={"villa-alemana"} />)} />
            <Route exact path={Path + "quilpue/"} component={() => (<ParquePage info={infoParqueQuilpue} slug={"quilpue"} />)} />
            <Route exact path={Path + "valparaiso/"} component={() => (<ParquePage info={infoParqueValparaiso} slug={"valparaiso"} />)} />
            <Route exact path={Path + "san-antonio/"} component={() => (<ParquePage info={infoParqueSanAntonio} slug={"san-antonio"} />)} />
            <Route exact path={Path + "maipu/"} component={() => (<ParquePage info={infoParqueMaipu} slug={"maipu"} />)} />
            <Route exact path={Path + "san-bernardo/"} component={() => (<ParquePage info={infoParqueSanBernardo} slug={"san-bernardo"} />)} />
            <Route exact path={Path + "rancagua/"} component={() => (<ParquePage info={infoParqueRancagua} slug={"rancagua"} />)} />
            <Route exact path={Path + "concepcion/"} component={() => (<ParquePage info={infoParqueConcepcion} slug={"concepcion"} />)} />
            <Route exact path={Path + "temuco/"} component={() => (<ParquePage info={infoParqueTemuco} slug={"temuco"} />)} />
            <Route component={NotFound} />
        </Switch>
    );
}

export default renderContent;