import React, { Component } from 'react';
import { Container } from 'reactstrap';
import BannerTop from './../../components/BannerTop';
/* Import Component */
import GrillaFuneral from './GrillaFuneral';
/* Import Imagenes */
import Parques from '../../assets/images/Sobre-Sendero.jpg';
import ParqueMobile from '../../assets/images/FUNERALESMobile.jpg';

class FuneralesDelDia extends Component {
    componentDidMount() {
        document.title = 'Sendero | Funerales del día'
        window.scrollTo(0, 0);

    }

    render() {
        return (
            <Container fluid className="container-funerales-dia">
                <BannerTop
                    title1="Funerales"
                    title2="Del Día"
                    image={window.innerWidth >= 760 ? Parques : ParqueMobile}
                />
                <p className="info">
                    Aquí podrá encontrar los horarios y sectores de sepultación que se realizarán el día de hoy.
                </p>
                <GrillaFuneral />
            </Container>
        );
    }
}
export default FuneralesDelDia;