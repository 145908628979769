import React, { Component } from "react";
import SliderSlick from "react-slick";
import ReactHtmlParser from 'react-html-parser';
import iconArrow from "./../../../assets/images/next.png";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const { items,iframe } = this.props;
    const cantidad = items.length;
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="jardines-container">
        <>
          {cantidad > 1 && !iframe ? (
            <img
              src={iconArrow}
              onClick={this.previous}
              className="arrow-left arrow"
              alt=""
            />
          ) : null}
          <div>
            <SliderSlick ref={c => (this.slider = c)} {...settings}>
              {items.map((item, index) => (
                <div key={index}>
                  {iframe ? (
                    <>
                      {ReactHtmlParser(item.iframe)}
                    </>
                  ) 
                  : 
                  (
                    <img
                    key={index}
                    src={item}
                    className="img-landing"
                    alt=""
                  />
                  )}
                </div>
              ))}
            </SliderSlick>
          </div>
          {cantidad > 1 && !iframe ? (
            <img
              src={iconArrow}
              onClick={this.next}
              className="arrow-right arrow"
              alt=""
            />
          ) : null}
        </>
      </div>
    );
  }
}
export default Slider;
