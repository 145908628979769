import React, { Component } from "react";
import Api from "../../service/Api";
import Validate from "../../service/Validate";
import { Button, Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import RegionComuna from "./../Data/RegionComuna.json";
class FormularioContratar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        nombreCliente: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rutCliente: "",
        correo: "",
        correoconfirmar: "",
        nacionalidad: "",
        genero: "",
        estadoCivil: "",
        fechaNacimiento: "",
        calle: "",
        numeroCalle: "",
        blockTorre: "",
        comunaCliente: "",
        regionCliente: "",
        telefonoFijoContacto: "",
        necesidad: this.props.necesidad,
        data_producto: JSON.stringify({
          id: this.props.data.id,
          producto_id: this.props.data.producto_id,
          producto_codigo_id: this.props.data.producto_codigo_id,
          title: this.props.data.title,
          precio: this.props.data.precio,
          uf: this.props.data.uf,
        }),
      },
      txtMsj: "",
      AlertClass: "",
      disabledButton: true,
      nameButton: "CONTRATAR",
      comunas_selected: {},
      region_id_selected: "-",
      modal: false,
      content_pop_up: "",
    };
    this.form = React.createRef();
    this.setForm = this.setForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.verCondiciones = this.verCondiciones.bind(this);
    this.aceptaCondiciones = this.aceptaCondiciones.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  verCondiciones() {
    this.setState({
      content_pop_up: this.props.data.pdf,
      modal: true,
    });
  }
  aceptaCondiciones() {
    this.setState({
      disabledButton: !this.state.disabledButton,
      modal: false,
    });
  }
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  onChangeRegion(event) {
    if (event.target.value === "-") {
      this.setState({
        comunas_selected: {},
        region_id_selected: "-",
      });
    } else {
      var comuna = RegionComuna[event.target.value].COMUNAS;
      const { data } = this.state;
      data.regionCliente = RegionComuna[event.target.value].CODIGO_REGION;
      data.comunaCliente = 0;
      this.setState({
        comunas_selected: {},
        data,
      });
      this.setState({
        comunas_selected: comuna,
        region_id_selected: RegionComuna[event.target.value].CODIGO_REGION,
        data,
      });
    }
  }
  async setForm() {
    let mensaje = "";
    let rules = {
      texto: "nombreCliente",
      texto1: "apellidoPaterno",
      texto2: "apellidoMaterno",
      texto3: "fechaNacimiento",
      texto4: "calle",
      texto5: "numeroCalle",
      rut: "rutCliente",
      email1: "correo",
      email2: "correoconfirmar",
      numerico: "telefonoFijoContacto",
      selector1: "nacionalidad",
      selector2: "regionCliente",
      selector3: "comunaCliente",
      selector4: "estadoCivil",
      selector5: "genero",
    };
    let validate = {};
    for (var [type, input] of Object.entries(rules)) {
      let resul = Validate.ValidateForm(
        this.state.data[input],
        type.replace(/[0-9]/g, ""),
        input
      );
      if (resul !== "") {
        mensaje += resul;
        validate[input] = [resul];
      }
    }
    Validate.validate(this.form.current, validate);
    if (mensaje !== "") {
      this.setState({
        txtMsj: "Se encuentran campos con errores, favor revisar",
        AlertClass: "alert-danger",
      });
      return false;
    }
    if (this.state.data.correoconfirmar !== this.state.data.correo) {
      this.setState({
        txtMsj: "Los correos no coinciden, favor revisar",
        AlertClass: "alert-danger",
      });
      return false;
    }
    this.setState({ disabledButton: true, nameButton: "ENVIANDO.." });
    const response = await Api.setBuyPlan(this.state.data);
    if (response.result === "success") {
      this.setState({
        token_ws: response.token_ws,
        url: response.next_page,
      });
      document.getElementById("form_webpay").submit();
    } else {
      if (response.result === "error") {
        this.setState({
          txtMsj: response.init,
          AlertClass: "alert-danger",
          disabledButton: false,
        });
      } else {
        this.setState({
          txtMsj: "Ocurrió un problema, favor intentar nuevamente",
          AlertClass: "alert-danger",
          disabledButton: false,
        });
      }
    }
  }
  onChange(event) {
    const { name, value } = event.target;
    const { data } = this.state;
    if (name === "rutCliente") {
      data[name] = value
        .replace(/[.-]/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    } else {
      data[name] = value;
    }
    this.setState({
      data,
    });
  }
  render() {
    const {
      nameButton,
      disabledButton,
      comunas_selected,
      region_id_selected,
    } = this.state;
    var arr = [];
    var arr_comuna = [];
    Object.keys(RegionComuna).forEach(function (key) {
      arr.push(RegionComuna[key]);
    });
    Object.keys(comunas_selected).forEach(function (key) {
      arr_comuna.push(comunas_selected[key]);
    });

    return (
      <>
        <form
          name="form_webpay"
          action={this.state.url}
          method="POST"
          id="form_webpay"
        >
          <input type="hidden" name="token_ws" value={this.state.token_ws} />
        </form>
        <div className="form-contratar">
          <form ref={this.form}>
            <div className="row-form row">
              <div className="col-12 col-sm-12">
                <label>Nombre *</label>
                <input
                  className="form-control"
                  type="text"
                  name="nombreCliente"
                  id="nombreCliente"
                  onChange={this.onChange}
                  value={this.state.data.nombreCliente}
                />
              </div>
              <div className="col-6 col-sm-6">
                <label>Apellido Paterno *</label>
                <input
                  className="form-control"
                  type="text"
                  name="apellidoPaterno"
                  id="apellidoPaterno"
                  onChange={this.onChange}
                  value={this.state.data.apellidoPaterno}
                />
              </div>
              <div className="col-6 col-sm-6">
                <label>Apellido Materno *</label>
                <input
                  className="form-control"
                  type="text"
                  name="apellidoMaterno"
                  id="apellidoMaterno"
                  onChange={this.onChange}
                  value={this.state.data.apellidoMaterno}
                />
              </div>
              <div className="col-12 col-sm-12">
                <label>RUT *</label>
                <input
                  className="form-control"
                  type="text"
                  name="rutCliente"
                  id="rutCliente"
                  onChange={this.onChange}
                  value={this.state.data.rutCliente}
                />
              </div>
              <div className="col-6 col-sm-6">
                <label>Correo Electrónico *</label>
                <input
                  className="form-control"
                  type="email"
                  name="correo"
                  id="correo"
                  onChange={this.onChange}
                  value={this.state.data.correo}
                />
              </div>
              <div className="col-6 col-sm-6">
                <label>Confirmar Correo Electrónico *</label>
                <input
                  className="form-control"
                  type="email"
                  name="correoconfirmar"
                  id="correoconfirmar"
                  onChange={this.onChange}
                  value={this.state.data.correoconfirmar}
                />
              </div>
              <div className="col-12 col-sm-12">
                <label>Nacionalidad *</label>
                <select
                  className="form-control"
                  name="nacionalidad"
                  id="nacionalidad"
                  onChange={this.onChange}
                >
                  <option value="0">Seleccione Nacionalidad</option>
                  <option value="CH">Chilena</option>
                  <option value="EX">Extranjera</option>
                </select>
              </div>
              <div className="col-6 col-sm-6">
                <label>Genero *</label>
                <select
                  className="form-control"
                  name="genero"
                  id="genero"
                  onChange={this.onChange}
                >
                  <option value="0">Seleccione Genero</option>
                  <option value="F">Femenino</option>
                  <option value="M">Masculino</option>
                </select>
              </div>
              <div className="col-6 col-sm-6">
                <label>Estado Civil *</label>
                <select
                  className="form-control"
                  name="estadoCivil"
                  id="estadoCivil"
                  onChange={this.onChange}
                >
                  <option value="0">Seleccione Estado Civil</option>
                  <option value="S">Soltero(a)</option>
                  <option value="N">Unión Civil</option>
                  <option value="C">Casado(a)</option>
                  <option value="D">Divorciado(a)</option>
                  <option value="V">Viudo(a)</option>
                </select>
              </div>
              <div className="col-12 col-sm-12">
                <label>Fecha de Nacimiento *</label>
                <input
                  className="form-control"
                  type="date"
                  name="fechaNacimiento"
                  id="fechaNacimiento"
                  onChange={this.onChange}
                  value={this.state.data.fechaNacimiento}
                />
              </div>
              <div className="col-12 col-sm-12">
                <label>Teléfono Contacto *</label>
                <input
                  className="form-control"
                  type="text"
                  name="telefonoFijoContacto"
                  id="telefonoFijoContacto"
                  onChange={this.onChange}
                  value={this.state.data.telefonoFijoContacto}
                />
              </div>
              <div className="col-6 col-sm-6">
                <label>Calle *</label>
                <input
                  className="form-control"
                  type="text"
                  name="calle"
                  id="calle"
                  onChange={this.onChange}
                  value={this.state.data.calle}
                />
              </div>
              <div className="col-6 col-sm-6">
                <label>Número Calle *</label>
                <input
                  className="form-control"
                  type="text"
                  name="numeroCalle"
                  id="numeroCalle"
                  onChange={this.onChange}
                  value={this.state.data.numeroCalle}
                />
              </div>
              <div className="col-12 col-sm-12">
                <label>Casa o Departamento</label>
                <input
                  className="form-control"
                  type="text"
                  name="blockTorre"
                  id="blockTorre"
                  onChange={this.onChange}
                  value={this.state.data.blockTorre}
                />
              </div>
              <div className="col-12 col-sm-12">
                <label>Región *</label>
                <select
                  className="form-control"
                  id="regionCliente"
                  name="regionCliente"
                  onChange={(e) => {
                    this.onChangeRegion(e);
                  }}
                >
                  <option value="-">Seleccionar Región</option>
                  {arr.map((item, index) => (
                    <option key={index} value={index}>
                      {item.NOMBRE_REGION}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-12">
                <label>Comuna *</label>
                <select
                  className="form-control"
                  id="comunaCliente"
                  name="comunaCliente"
                  disabled={region_id_selected === "-" ? true : false}
                  onChange={this.onChange}
                >
                  <option value="0">Seleccionar Comuna</option>
                  {arr_comuna.map((item, index) => (
                    <option key={index} value={item.CODIGO_COMUNA}>
                      {item.NOMBRE_COMUNA}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-12">
                {this.state.txtMsj !== "" && (
                  <Alert className={this.state.AlertClass}>
                    {this.state.txtMsj}
                  </Alert>
                )}
              </div>
              <div className="text-center col-12 col-sm-12">
                <p>
                  <input type="checkbox" onClick={this.aceptaCondiciones} />
                  He leído y acepto las condiciones especiales del contrato.(
                  <span
                    onClick={this.verCondiciones}
                    className="acepta-condicion"
                  >
                    Revisa aquí el contrato de condiciones
                  </span>
                  )
                </p>
              </div>
              <div className="text-center col-12 col-sm-12">
                <div className="content-button text-center"> 
                  <Button
                    className="btn btn-verde button"
                    onClick={this.setForm}
                    disabled={disabledButton}
                  >
                    {nameButton}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          backdrop={"static"}
          fade={false}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            <iframe src={this.state.content_pop_up} width="100%" height="500" />
            <Button onClick={this.toggle}>Cerrar</Button>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default FormularioContratar;
