/* Icono Parque */
import icon from './../../../../assets/images/Parques/IconMaipu.png';
/* Logos Iconos Jardines Temáticos */
import sendero from './../../../../assets/images/Parques/icon/sendero.png'; // Parque del Sendero
import jerusalen from './../../../../assets/images/Parques/icon/jerusalen.png';  // Nueva Jerusalen
import familia from './../../../../assets/images/Parques/icon/familia.png';  // Nuestra Familia
import delcielo from './../../../../assets/images/Parques/icon/delcielo.png';  // Jardin del Cielo
import familiar from './../../../../assets/images/Parques/icon/familiar.png'; // Tradición Familiar
import mausoleo from './../../../../assets/images/Parques/icon/mausoleo.png'; // Jardin Mausoleo
import monumento from './../../../../assets/images/Parques/icon/monumento.png'; // Jardin Monumento
import privado from './../../../../assets/images/Parques/icon/privado.png'; // Jardin Privado
import noble from './../../../../assets/images/Parques/icon/noble.png'; // Jardin Noble
import columbario from './../../../../assets/images/Parques/icon/columbario.png'; // Jardin Columbario
/* Fotos Jardines Temáticos */
import img_sendero from './../../../../assets/images/Parques/Maipu/img_sendero.jpg'; // Parque del Sendero
import img_familia from './../../../../assets/images/Parques/Maipu/img_familia.jpg';  // Img Nuestra Familia
import img_jerusalen from './../../../../assets/images/Parques/Maipu/img_jerusalen.jpg'; // Img Nueva Jerusalen
// import img_familiar from './../../../../assets/images/Parques/Maipu/img_familiar.jpg'; // Img Tradición Familiar
import img_mausoleo from './../../../../assets/images/Parques/Maipu/img_mausoleo.jpg'; // Jardin Mausoleo
import img_delcielo from './../../../../assets/images/Parques/Maipu/img_delcielo.jpg';  // Img Jardin del Cielo
import img_monumento from './../../../../assets/images/Parques/Maipu/img_monumento.jpg';  // Img Monumento
import img_noble from './../../../../assets/images/Parques/Maipu/img_noble.jpg'; // Img Jardín Noble
// import img_privado from './../../../../assets/images/Parques/Maipu/img_privado.jpg'; // Img Jardin Privado
import img_columbario from './../../../../assets/images/Parques/Maipu/img_columbario.jpg'; // Img Jardin Columbario

import img_familiar_new from './../../../../assets/images/Parques/new/img_jardin_familiar.png';  // Img Tradición Familiar
import img_privado_new from './../../../../assets/images/Parques/new/img_jardin_privado.png'; // Img Jardin Privado

export default {
    icon: icon,
    carouselItems: [
        {
            image: img_sendero,
            logo: sendero,
            text: 'Es un espacio que cuenta con grandes áreas verdes enmarcadas por hermosas arboleda.',
            nombre: 'Parque del Sendero',
            link: ''
        },
        {
            image: img_jerusalen,
            logo: jerusalen,
            text: 'Un espacio dedicado al pueblo evangélico, que alberga a quienes ya partieron al reencuentro eterno.',
            nombre: 'Nueva Jerusalén',
            link: ''
        },
        {
            image: img_familia,
            logo: familia,
            text: 'Un área diseñada especialmente para recibir y proteger sus recuerdos y ofrendas.',
            nombre: 'Nuestra Familia',
            link: ''
        },
        {
            image: img_delcielo,
            logo: delcielo,
            text: 'Hermosas y extensas áreas verdes, pérgolas, fuentes de agua y jardines que generan un entorno de paz y tranquilidad es lo  que puede encontrar en este jardín; un apacible lugar diseñado para recordar y homenajear a sus seres queridos. ',
            nombre: 'Jardines del Cielo',
            link: ''
        },
        {
            image: img_familiar_new,
            logo: familiar,
            text: 'Un área diseñado para estar en contacto con la naturaleza. La hermosa fuente de agua que rodea el espacio permite una tranquilidad y conexión con sus seres queridos.',
            nombre: 'Tradición Familiar',
            link: ''
        },

        {
            image: img_mausoleo,
            logo: mausoleo,
            text: 'Lo mejor de lo tradicional, acompañado por la belleza natural que ofrece un parque cementerio.',
            nombre: 'Jardín Mausoleo',
            link: ''
        },
        {
            image: img_monumento,
            logo: monumento,
            text: 'Un espacio único y distinguido, donde la memoria de sus seres queridos permanecerá por siempre rodeada de naturaleza. ',
            nombre: 'Jardín Monumento',
            link: ''
        },
        {
            image: img_privado_new,
            logo: privado,
            text: 'Un espacio privilegiado donde se resguarda la memoria y el descanso de sus seres queridos.',
            nombre: 'Jardín Privado',
            link: ''
        },
        {
            image: img_noble,
            logo: noble,
            text: 'Conmemore con elegancia y distinción el recuerdo de sus seres queridos. El espacio es resguardado por un cierre perimetral de fierro, con puerta y escaño para uso exclusivo de su familia.',
            nombre: 'Jardín Noble',
            link: ''
        },
    ],

    jardinesTematicos: [
        sendero,
        jerusalen,
        familia,
        delcielo,
        familiar,
        mausoleo,
        monumento,
        privado,
        noble,
    ],
}





