import React ,  { Component } from 'react'
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import bannerMPUXMOBILE from '../Assets/Images/banner_new_pago_rapido_mobile_375.png';

import bannerLEYMOBILE from '../Assets/Images/banner_ley_21420_mobile.png';
import { urlIvas } from '../../config';

class SliderMobile  extends Component {
    constructor(props) {
      super(props);
      this.state = {
        activeIndex: 0,
        items: [
            {
              link : 'https://sendero.cl/cotizar?utm_source=banner_site&utm_medium=banner_pago_rapido&utm_campaign=banner',
              portadadesktop : bannerMPUXMOBILE,
              name : '',
              target: ''
            },
            {
              link : urlIvas,
              portadadesktop : bannerLEYMOBILE,
              name : 'Ivas',
              target: 'Ivas'
          }
        ]
      };
      //this.getSliderHome = this.getSliderHome.bind(this);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
      this.goToIndex = this.goToIndex.bind(this);
      this.onExiting = this.onExiting.bind(this);
      this.onExited = this.onExited.bind(this);
    }
  
    componentDidMount() {
      this.goToIndex(1);
    }
  
    // async getSliderHome() {
    //   const response = await Api.getSlider();
    //   this.setState({ items: response.result });
    // }
    onExiting() {
      this.animating = true;
    }
  
    onExited() {
      this.animating = false;
    }
  
    next() {
      if (this.animating) return;
      const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
      this.setState({ activeIndex: nextIndex });
    }
  
    previous() {
      if (this.animating) return;
      const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
      this.setState({ activeIndex: nextIndex });
    }
  
    goToIndex(newIndex) {
      if (this.animating) return;
      this.setState({ activeIndex: newIndex });
    }
    render() {
      const { activeIndex, items } = this.state;
      return (<>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          interval="4000"
          autoPlay={true}
        >
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {items.map((item,index) => (
            <CarouselItem
              onExiting={this.onExiting}
              onExited={this.onExited}
              key={index}
            >
              <a href={item.link} target={item.target}>
                <img src={item.portadadesktop} alt={item.name} style={{ width: 100 + "%" }} />
              </a>
            </CarouselItem>
          ))}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>
        <br />
        </>
      );
    }
  }
  export default SliderMobile;