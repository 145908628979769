import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Forms from './../components/Forms/Form';
import Parques from '../assets/images/Contacto.jpg';
import BannerTop from '../components/BannerTop';

class Forme extends Component {

    render() {
        return (
            <><Container fluid ><BannerTop title1="" title2="Contacto" image={Parques} />
                <Container>
                    <Forms
                        nameButton='ENVIAR'
                        destino_email='uobkukzx@robot.zapier.com'
                        type_form='Formulario Call Center'
                    />
                </Container>
            </Container>
            </>
        );
    }
}
export default Forme;