import React, { useEffect } from 'react';
import ServiceCremacion from './Components/ServiceCremacion';
import FormularioCheckout from './Components/FormularioCheckout';

const Checkout = (props) => {
  console.info(props.location.state);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FormularioCheckout {...props.location.state} />
      <ServiceCremacion />
    </>
  );
};
export default Checkout;
