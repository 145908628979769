import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Input, Alert } from 'reactstrap';
import Session from './../../service/Session';
import Api from './../../service/Api';
import Validate from '../../service/Validate';
import Layout from './../Layout/';

class Formulario extends Component {

    constructor(props) {
        super(props);

        this.state = {
            enableAbout: false,
            enableMotivo: false,

            Nombre: Session.getInfoUser().usuario,
            Rut: Session.getInfoUser().rut,
            Email: '',
            Telefono: '',
            Mensaje: '',
            Motivo: 0,
            Sobre: '',
            txtMsj: '',
            AlertClass: '',
            disabledButton: false
        }
        this.handleSelectMotivo = this.handleSelectMotivo.bind(this);
        this.setForm = this.setForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleSelectMotivo(event) {
        const motivo = event.target.value;
        this.setState({
            enableAbout: (motivo !== "Consulta" && motivo !== "Cotizar"),
            enableMotivo: (motivo !== "Consulta" && motivo !== "Cotizar")
        })
    }
    setForm() {
        let mensaje = "";
        mensaje += Validate.ValidateForm(this.state.Nombre, 'texto', 'Nombre')
        mensaje += Validate.ValidateForm(this.state.Rut, 'rut', 'Rut')
        mensaje += Validate.ValidateForm(this.state.Email, 'email', 'Email')
        mensaje += Validate.ValidateForm(this.state.Telefono, 'numerico', 'Teléfono')
        mensaje += Validate.ValidateForm(this.state.Parque, 'selector', 'Parque')
        mensaje += Validate.ValidateForm(this.state.Motivo, 'selector', 'Motivo')
        if (mensaje !== "") {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + mensaje, AlertClass: "alert-danger" });
            return false;
        }

        let data = Api.setForm(this.state);
        if (data) {
            this.setState({
                Email: '',
                Telefono: '',
                Mensaje: '',
                Motivo: 0,
                Sobre: 0,
                txtMsj: "Correo enviado correctamente",
                AlertClass: "alert-success",
                disabledButton: false
            });
        } else {
            this.setState({
                txtMsj: "Ocurrió un problema, favor intentar nuevamente"
            });
        }
    }
    handleInputChange(event) {
        this.setState({
            txtMsj: ""
        });
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (target.name === 'Motivo') {
            this.handleSelectMotivo(event);
        }
        this.setState({
            [name]: value
        });
        if (this.state.Email && this.state.Telefono) {
            this.setState({
                disabledButton: true
            });
        } else {
            this.setState({
                disabledButton: false
            });
        }
    }
    render() {
        return (
            <>
                <Layout name="Mis Datos">
                    <Row>
                        <Col xs={12} md={12}>
                            <Form>
                                {/*<FormGroup row>
                                    <Col>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </Col>
                                </FormGroup>*/}
                                <FormGroup row>
                                    <Col sm={6}>
                                        <Input type="text" name="Nombre" id="Nombre" placeholder="Nombre*" onChange={this.handleInputChange} value={this.state.Nombre || ''} disabled />
                                    </Col>
                                    <Col sm={6}>
                                        <Input type="text" name="Rut" id="Rut" placeholder="RUT*" onChange={this.handleInputChange} value={this.state.Rut || ''} disabled />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={6}>
                                        <Input type="email" name="Email" id="Email" placeholder="Email*" onChange={this.handleInputChange} value={this.state.Email || ''} />
                                    </Col>
                                    <Col sm={6}>
                                        <Input type="text" name="Telefono" id="Telefono" placeholder="Teléfono" onChange={this.handleInputChange} value={this.state.Telefono || ''} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="select" name="Motivo" id="Motivo" onChange={this.handleInputChange}>
                                            <option value="0">Motivo</option>
                                            <option value="Cotizar">Cotizar</option>
                                            <option value="Reclamo">Reclamo</option>
                                            <option value="Sugerencia">Sugerencia</option>
                                            <option value="Consulta">Consulta</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row hidden={!this.state.enableMotivo} >
                                    <Col sm={12}>
                                        <Input disabled={!this.state.enableAbout} defaultValue="0" type="select" name="Sobre" id="Sobre" onChange={this.handleInputChange}>
                                            <option disabled value="0">Sobre</option>
                                            <option value="Reclamo">Seguridad del Parque</option>
                                            <option value="Sugerencia">Atención al Cliente</option>
                                            <option value="Mantención">Mantención</option>
                                            <option value="Proceso de Venta">Proceso de Venta</option>
                                            <option value="Contrato">Contrato</option>
                                            <option value="Otro">Otro</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="textarea" name="Mensaje" id="Mensaje" placeholder="Mensaje..." onChange={this.handleInputChange} value={this.state.Mensaje || ''} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12} className="text-center">
                                        {this.state.txtMsj !== "" ? <Alert className={'alert ' + this.state.AlertClass}>{this.state.txtMsj}</Alert> : ""}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12} className="text-right">
                                        <Button className="btn btn-verde button" onClick={this.setForm} disabled={!this.state.disabledButton}>Enviar</Button>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Layout>
            </>
        );
    }
}
export default Formulario;