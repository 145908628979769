import React, { useEffect } from "react";
import { Row } from "reactstrap";
import Ticket from "../../../assets/images/correcto.PNG";

const Step3 = React.forwardRef((props, ref) => {
  console.log(props.type);

  const linkedinTo = () => {
    let url = "";

    if (process.env.REACT_APP_API_URL_VOYAGER.includes("sendero-api-test")) {
      url = "https://test.funerariasendero.cl";
    } else {
      url = "https://www.funerariasendero.cl";
    }

    return url;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Row className="justify-content-center">
        <img src={Ticket} alt="" className="img-steps-thanks" />
      </Row>
      <Row className="justify-content-center">
        <h2 className="title text-center" style={{ fontSize: "16px" }}>
          Gracias por contactarse con nosotros, nuestros asesores lo contactarán
          lo antes posible.
        </h2>
      </Row>
      <Row className="justify-content-center" style={{ display: "none" }}>
        {/* <h2 className="title text-center" style={{fontSize: '16px' , textTransform: 'initial'}}>¿Le interesa también una {<Link to={props.type === 'cremacion' ? '/cotizar-sepultura' : '/cotizar-cremacion'} >{ props.type === 'cremacion' ? 'Sepultura' : 'Cremación'}</Link>} ?</h2> */}
        <h2
          className="title text-center"
          style={{ fontSize: "16px", textTransform: "initial" }}
        >
          ¿ Le interesa también un{" "}
          {
            <a
              target="_blanck"
              href={linkedinTo() + "/cotizar-servicio"}
              rel="nofollow noopener noreferrer"
            >
              Servicio Funeario
            </a>
          }{" "}
          ?
        </h2>
      </Row>
    </>
  );
});

export default Step3;
