/* Icono Parque */
import icon from './../../../../assets/images/Parques/IconArica.png';
/* Logos Iconos Jardines Temáticos */
import sendero from './../../../../assets/images/Parques/icon/sendero.png'; // Parque del Sendero
import familia from './../../../../assets/images/Parques/icon/familia.png';  // Nuestra Familia
import privado from './../../../../assets/images/Parques/icon/privado.png'; // Jardin Privado
import noble from './../../../../assets/images/Parques/icon/noble.png'; // Jardin Noble
/* Fotos Jardines Temáticos */
import img_sendero from './../../../../assets/images/Parques/Maipu/img_sendero.jpg'; // Parque del Sendero
import img_familia from './../../../../assets/images/Parques/Maipu/img_familia.jpg';  // Img Nuestra Familia

// import img_privado from './../../../../assets/images/Parques/Maipu/img_privado.jpg'; // Img Jardin Privado


import img_privado_new from './../../../../assets/images/Parques/new/new_jardin_privado_17182023.png';
import img_noble from './../../../../assets/images/Parques/new/new_jardin_noble_17082023.png';

export default {
    icon: icon,
    carouselItems: [
        {
            image: img_sendero,
            logo: sendero,
            text: 'Es un espacio que cuenta con grandes áreas verdes enmarcadas por hermosas arboleda.',
            nombre: 'Parque del Sendero',
            link: ''
        },
        {
            image: img_familia,
            logo: familia,
            text: 'Un área diseñada especialmente para recibir y proteger sus recuerdos y ofrendas.',
            nombre: 'Nuestra Familia',
            link: ''
        },
        {
            image: img_privado_new,
            logo: privado,
            text: 'Un espacio privilegiado donde se resguarda la memoria y el descanso de sus seres queridos.',
            nombre: 'Jardín Privado',
            link: ''
        },
        {
            image: img_noble,
            logo: noble,
            text: 'Conmemore con elegancia y distinción el recuerdo de sus seres queridos. El espacio es resguardado por un cierre perimetral de fierro, con puerta y escaño para uso exclusivo de su familia.',
            nombre: 'Jardín Noble',
            link: ''
        }
    ],

    jardinesTematicos: [
        sendero,
        familia,
        noble,
        privado
    ],
}





