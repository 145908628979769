import React, { Children, Component } from 'react';
import Api from '../service/Api';
import FeaturedItem from '../pages/Home/Desktop/Featured/FeaturedItem';
import ProductItem from '../pages/Home/Desktop/Products/ProductItem';
import FeaturedColorItem from '../pages/Home/Desktop/FeaturedColor/FeaturedColorItem';
import ServicioItem from '../pages/Sepultura/Servicios/ServicioItem';
import Tipo from '../pages/Tipo-Mantencion/Tipo';
import BoxOption from '../pages/MantencionOfrenda/BoxOption';
import BoxPunto from '../pages/MantencionOfrenda/BoxPunto';
import BoxImage from '../pages/MantencionOfrenda/BoxImage';
import BoxLists from '../pages/MantencionOfrenda/BoxLists';

class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
    this.getBoxes = this.getBoxes.bind(this);
  }

  componentDidMount() {
    this.getBoxes();
  }

  async getBoxes() {
    const dat = {};
    dat.type = this.props.type;
    const response = await Api.getBoxes(dat);
    this.setState({ items: response });
  }

  render() {
    const { typeBox, visible, children } = this.props;
    const { items } = this.state;
    return (
      <>
        {typeBox === 'Featured' && (
          <div className="row home-featured">
            {items && items.map((item, index) => (
              <FeaturedItem {...item} key={index} position={index} />
            ))}
          </div>
        )}
        {typeBox === 'Products' && (
          <div className="products-home mt-5">
            {/*<h2 className="products-home__title">Productos</h2>*/}
            <div className="row products-home__container">
              {items && items.map((item, index) => (
                <ProductItem {...item} key={index} />
              ))}
            </div>
          </div>
        )}
        {typeBox === 'FeaturedColorItem' && (
          <div className="home-featured3">
            <div className="row home-featured3-container">
              {items && items.map((item, index) => (
                <FeaturedColorItem {...item} key={index} posicion={index} />
              ))}
            </div>
          </div>
        )}
        {typeBox === 'ServicioItem' && (
          <div className="row servicios-container">
            {items && items.map((item, index) => (
              <ServicioItem {...item} key={index} posicion={index} />
            ))}
          </div>
        )}
        {typeBox === 'TypeMaintenanceItem' && (
          <div className="row">
            {items && items.map((item, index) => (
              <Tipo {...item} key={index} />
            ))}
          </div>
        )}
        {typeBox === 'ParqueTodosItem' && (
          <div className="row home-featured">
            {items && items.map((item, index) => (
              <BoxOption {...item} key={index} />
            ))}
          </div>
        )}
        {typeBox === 'MO Item Comunidad Parque' && (
          <div className="row home-featured">
            {items && items.map((item, index) => (
              index <= 3 && (
                <BoxPunto {...item} key={index} color={'orange'} col={3} />
              )
            ))}
            {children}
            {items && items.map((item, index) => (
              index >= 4 && (
                <BoxPunto {...item} key={index} color={'orange'} col={3} />
              )
            ))}
          </div>
        )}
        {typeBox === 'MO Item Retiro de Ofrendas' && (
          <div className="row home-featured">
            {items && items.map((item, index) => (
              <BoxPunto {...item} key={index} color={'blue'} col={4} />
            ))}
          </div>
        )}
        {typeBox === 'MO Item Cuidado del Parque' && (
          <div className="row home-featured">
            {items && items.map((item, index) => (
              <BoxPunto {...item} key={index} color={'green'} col={4} />
            ))}
          </div>
        )}
        {typeBox === 'MO Item Cuidamos Juntos' && (
          <div className="row home-featured">
            {items && items.map((item, index) => (
              <BoxPunto {...item} key={index} color={'blue'} col={4} />
            ))}
          </div>
        )}
        {typeBox === 'MO Text Image' && (
          <div className="row justify-content-center align-items-center content-image">
            {items && items.map((item, index) => (
              <BoxImage {...item} key={index} visible={visible} />
            ))}
          </div>
        )}
        {typeBox === 'MO Lists' && (
          <div className="row mt-5">
            {items && items.map((item, index) => (
              <BoxLists {...item} key={index} />
            ))}
          </div>
        )}
      </>
    );
  }
}
export default Box;