import React, { Component } from 'react';
import { BaseURLVoyager } from '../../../../config';
class DocumentPie extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	render() {
		const {contrato, rut} = this.props;
		return (
			<>
				<h3>DOCUMENTACIÓN</h3>
				<div className="row">
					<div className="col-6 col-md-12">
						{/* <p><a href={BaseURLVoyager+"api/contrato/"+rut+'/'+contrato} target="_blank">Documento Contrato</a></p> */}
						<p>Estimado cliente, si requiere una copia de su contrato favor contactarse directamente con nuestros ejecutivos al número 600 736 3376</p>
						<br />
					</div>
				</div>

			</>
		)
	}
}
export default DocumentPie;