import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class responseCodeTransbank extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { code, buyorder } = this.props;
    var msg = "";
    switch (code) {
      case "-1":
        msg = "Tarjeta inválida";
        break;
      case "-2":
        msg = "Error de conexión";
        break;
      case "-3":
        msg = "Excede monto máximo";
        break;
      case "-4":
        msg = "Fecha de expiración inválida";
        break;
      case "-5":
        msg = "Problema en autenticación";
        break;
      case "-6":
        msg = "Rechazo general";
        break;
      case "-7":
        msg = "Tarjeta bloqueada";
        break;
      case "-8":
        msg = "Tarjeta vencida";
        break;
      case "-9":
        msg = "Transacción no soportada";
        break;
      case "-10":
        msg = "Problema en la transacción";
        break;
      default:
        msg = "Código no encontrado";
        break;
    }
    return (
      <>
        <Row>
          <Col>
            <br />
            <h2>
              Transacción Rechazada N° <strong>{buyorder}</strong>
            </h2>
            {code !== null && (
              <>
                <br />
                <h3>Respuesta de transbank:</h3>
                <p>- {msg}</p>
              </>
            )}
            <br />
            <h3>
              {code !== null ? "Otras" : "Las"} posibles causas de este rechazo
              son:
            </h3>
            <p>
              - Error en el ingreso de los datos de su tarjeta de crédito o
              Debito (fecha y/o código de seguridad).
            </p>
            <p>
              - Su tarjeta de crédito o debito no cuenta con el cupo necesario
              para cancelar la compra.
            </p>
            <p>- Tarjeta aún no habilitada en el sistema financiero</p>
          </Col>
        </Row>
      </>
    );
  }
}
export default responseCodeTransbank;
