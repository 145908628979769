import Arica from '../../../assets/images/Parques/Arica.png';
import IconArica from '../../../assets/images/Parques/IconArica.png';
import IconIquique from '../../../assets/images/Parques/IconIquique.png';
import Iquique from '../../../assets/images/Parques/Iquique.png';
import IconVillaAlemana from '../../../assets/images/Parques/IconVillaAlemana.png';
import VillaAlemana from '../../../assets/images/Parques/VillaAlemana.png';
import Quilpue from '../../../assets/images/Parques/Quilpue.png';
import IconQuilpue from '../../../assets/images/Parques/IconQuilpue.png';
import Valparaiso from '../../../assets/images/Parques/Valparaiso.png';
import IconValparaiso from '../../../assets/images/Parques/IconValparaiso.png';
import SanAntonio from '../../../assets/images/Parques/SanAntonio.png';
import IconSanAntonio from '../../../assets/images/Parques/IconSanAntonio.png';
import Maipu from '../../../assets/images/Parques/Maipu.png';
import IconMaipu from '../../../assets/images/Parques/IconMaipu.png';
import SanBernardo from '../../../assets/images/Parques/SanBernardo.png';
import IconSanBernardo from '../../../assets/images/Parques/IconSanBernardo.png';
import Rancagua from '../../../assets/images/Parques/Rancagua.png';
import IconRancagua from '../../../assets/images/Parques/IconRancagua.png';
import Concepcion from '../../../assets/images/Parques/Concepcion.png';
import IconConcepcion from '../../../assets/images/Parques/IconConcepcion.png';
import Temuco from '../../../assets/images/Parques/Temuco.png';
import IconTemuco from '../../../assets/images/Parques/IconTemuco.png';

export default [
    {
        link: 'arica',
        icon: IconArica,
        image: Arica,
        title: 'Arica'
    },
    {
        link: 'iquique',
        icon: IconIquique,
        image: Iquique,
        title: 'Iquique'
    },
    {
        link: 'villa-alemana',
        icon: IconVillaAlemana,
        image: VillaAlemana,
        title: 'Villa Alemana'
    },
    {
        link: 'quilpue',
        icon: IconQuilpue,
        image: Quilpue,
        title: 'Quilpué'
    },
    {
        link: 'valparaiso',
        icon: IconValparaiso,
        image: Valparaiso,
        title: 'Valparaíso'
    },
    {
        link: 'san-antonio',
        icon: IconSanAntonio,
        image: SanAntonio,
        title: 'San Antonio'
    },
    {
        link: 'maipu',
        icon: IconMaipu,
        image: Maipu,
        title: 'Maipú'
    },
    {
        link: 'san-bernardo',
        icon: IconSanBernardo,
        image: SanBernardo,
        title: 'San Bernardo'
    },
    {
        link: 'rancagua',
        icon: IconRancagua,
        image: Rancagua,
        title: 'Rancagua'
    },
    {
        link: 'concepcion',
        icon: IconConcepcion,
        image: Concepcion,
        title: 'Concepción'
    },
    {
        link: 'temuco',
        icon: IconTemuco,
        image: Temuco,
        title: 'Temuco'
    }
];