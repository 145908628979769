
/* Logos Iconos Jardines Temáticos */
import sendero from './../../assets/images/Parques/icon/sendero.png'; // Parque Sendero
import jerusalen from './../../assets/images/Parques/icon/jerusalen.png';  // Nueva Jerusalen
import familia from './../../assets/images/Parques/icon/familia.png';  // Nuestra Familia
import delcielo from './../../assets/images/Parques/icon/delcielo.png';  // Jardin del Cielo
import familiar from './../../assets/images/Parques/icon/familiar.png'; // Tradición Familiar
import mausoleo from './../../assets/images/Parques/icon/mausoleo.png'; // Jardin Mausoleo
import monumento from './../../assets/images/Parques/icon/monumento.png'; // Jardin Monumento
import privado from './../../assets/images/Parques/icon/privado.png'; // Jardin Privado
import noble from './../../assets/images/Parques/icon/noble.png'; // Jardin Noble
import columbario from './../../assets/images/Parques/icon/columbario.png'; // Jardin Columbario
import sanlorenzo from './../../assets/images/Parques/icon/sanlorenzo.png'; // Jardin Columbario
import sanexpedito from './../../assets/images/Parques/icon/sanexpedito.png'; // Jardin Columbario
import nuestramadre from './../../assets/images/Parques/icon/nuestramadre.png'; // Jardin Columbario

/* Fotos Jardines Temáticos */
import img_sendero from './../../assets/images/Parques/Maipu/img_sendero.jpg'; // Parque Sendero
import img_familia from './../../assets/images/Parques/Maipu/img_familia.jpg';  // Img Nuestra Familia
import img_jerusalen from './../../assets/images/Parques/Maipu/img_jerusalen.jpg'; // Img Nueva Jerusalen
import img_familiar from './../../assets/images/Parques/Maipu/img_familiar.jpg'; // Img Tradición Familiar
import img_mausoleo from './../../assets/images/Parques/Maipu/img_mausoleo.jpg'; // Jardin Mausoleo
import img_delcielo from './../../assets/images/Parques/Maipu/img_delcielo.jpg';  // Img Jardin del Cielo
import img_monumento from './../../assets/images/Parques/Maipu/img_monumento.jpg';  // Img Monumento
import img_noble from './../../assets/images/Parques/Maipu/img_noble.jpg'; // Img Jardín Noble
import img_privado from './../../assets/images/Parques/Maipu/img_privado.jpg'; // Img Jardin Privado
import img_columbario from './../../assets/images/Parques/Maipu/img_columbario.jpg'; // Img Jardin Columbario
import img_sanlorenzo from './../../assets/images/Parques/Rancagua/img_sanlorenzo.jpg'; // Img Jardin Columbario
import img_sanexpedito from './../../assets/images/Parques/VillaAlemana/img_sanexpedito.jpg'; // Img Jardin Columbario
import img_nuestramadre from './../../assets/images/Parques/Temuco/img_nuestramadre.jpg'; // Img Jardin Columbario

export default [
    {
        image: img_sendero,
        logo: sendero,
        text: 'Es un espacio que cuenta con grandes áreas verdes enmarcadas por hermosas arboleda.',
        link: '',
        nombre: 'Parque del Sendero'
    },
    {
        image: img_jerusalen,
        logo: jerusalen,
        text: 'Un espacio dedicado al pueblo evangélico, que alberga a quienes ya partieron al reencuentro eterno.',
        link: '',
        nombre: 'Nueva Jerusalen'
    },
    {
        image: img_familia,
        logo: familia,
        text: 'Un área diseñada especialmente para recibir y proteger sus recuerdos y ofrendas.',
        link: '',
        nombre: 'Nuestra Familia'
    },
    {
        image: img_delcielo,
        logo: delcielo,
        text: 'Hermosas y extensas áreas verdes, pérgolas, fuentes de agua y jardines que generan un entorno de paz y tranquilidad es lo  que puede encontrar en este jardín; un apacible lugar diseñado para recordar y homenajear a sus seres queridos. ',
        link: '',
        nombre: 'Jardines del Cielo'
    },
    {
        image: img_familiar,
        logo: familiar,
        text: 'Un área diseñado para estar en contacto con la naturaleza. La hermosa fuente de agua que rodea el espacio permite una tranquilidad y conexión con sus seres queridos.',
        link: '',
        nombre: 'Tradición Familiar'
    },
    {
        image: img_mausoleo,
        logo: mausoleo,
        text: 'Lo mejor de lo tradicional, acompañado por la belleza natural que ofrece un parque cementerio.',
        link: '',
        nombre: 'Jardín Mausoleo'
    },
    {
        image: img_monumento,
        logo: monumento,
        text: 'Un espacio único y distinguido, donde la memoria de sus seres queridos permanecerá por siempre rodeada de naturaleza. ',
        link: '',
        nombre: 'Jardín Monumento'
    },
    {
        image: img_privado,
        logo: privado,
        text: 'Un espacio privilegiado donde se resguarda la memoria y el descanso de sus seres queridos.',
        link: '',
        nombre: 'Jardín Privado'
    },
    {
        image: img_noble,
        logo: noble,
        text: 'Conmemore con elegancia y distinción el recuerdo de sus seres queridos. El espacio es resguardado por un cierre perimetral de fierro, con puerta y escaño para uso exclusivo de su familia.',
        link: '',
        nombre: 'Jardín Noble'
    },
    {
        image: img_columbario,
        logo: columbario,
        text: 'Un espacio especialmente diseñado para resguardar el ánfora de su ser querido, siendo un lugar de encuentro y reflexión para la familia. Está ubicado al interior de Sendero Maipú, en un ambiente de paz y naturaleza, cercano a la capilla ecuménica y a los principales accesos del parque.',
        link: '',
        nombre: 'Jardín Columbario'
    },
    {
        image: img_sanlorenzo,
        logo: sanlorenzo,
        text: 'Para las familias mineras que prefieren honrar a sus seres queridos en un entorno de espiritualidad. Un área ubicada a los pies del altar de San Lorenzo, con oratorio y entorno natural. Un espacio exclusivo para honrar a sus seres queridos al amparo del Santo Patrono de los mineros.',
        link: '',
        nombre: 'Jardines San Lorenzo'
    },
    {
        image: img_sanexpedito,
        logo: sanexpedito,
        text: 'Ubicada a los pies del altar de San Expedito, con oratorio y un entorno natural. Un espacio diseñado para la devoción y honrar a sus seres queridos al amparo del Santo Patrono de las causas urgentes e imposibles.',
        link: '',
        nombre: 'Jardines San Expedito'
    },
    {
        image: img_nuestramadre,
        logo: nuestramadre,
        text: 'Un área ubicada a los pies de la Virgen María en un entorno natural. Un espacio grato y reconfortante para honrar a sus seres queridos al amparo de la Virgen.',
        link: '',
        nombre: 'Jardines Nuestra Madre'
    }

];



