import React, { Component } from "react";
import logoSendero from '../../assets/images/logo-sendero.svg';

export class LogoSendero extends Component {
    render() {
        return (
            <>
                <div className="d-block d-md-none">
                    <div className="content-logo text-center">
                        <img src={logoSendero} alt="" style={{maxWidth: '200px'}} />
                    </div>
                </div>
                <div className="d-none d-md-block">
                    <div className="content-logo text-center">
                        <img src={logoSendero} alt="" />
                    </div>
                </div>
            </>
            
        )
    }
}