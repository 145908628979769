import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container } from 'reactstrap';
import PostsItems from './Items';

import './Noticias.scss';
class PostComponent extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        return (
            <>
                <div className="content-posts">
                    <div className="content-items-posts">
                        <PostsItems type="2" title="MÁS APOYO" cant="3"/>
                        <div className="text-center content-btn">
                            {/* <Link to={"posts/sendero-contigo"} className="btn button">VER TODO</Link> */}
                            <a href="https://masapoyo.sendero.cl/" target="_blank" rel="noopener noreferrer" className="btn button">VER TODO</a>
                        </div>
                    </div>
                    <Container><hr/></Container>
                    <div className="content-items-posts">
                        <PostsItems type="1" title="Noticias" cant="3"/>
                        <div className="text-center content-btn">
                            <Link to={"posts/noticias"} className="btn button">VER TODO</Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default PostComponent;