import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

class IngresoVentas extends Component {
    componentDidMount() {
        document.title = 'Sendero | Ingreso Ventas'
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <Container fluid>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <iframe title="Ingreso Ventas" src="https://docs.google.com/forms/d/e/1FAIpQLSfcbZNIN0Vk-4_XH7crCQSI7CWNpOTt_kgjwqBBpPPXZTquuQ/viewform" width="100%" height="2700" frameBorder="0" />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}
export default IngresoVentas;