import React, { Component } from 'react';
import axios from 'axios';
import { BaseURLVoyager } from '../../../config';
import ContenidoGrillaFuneral from './contenido';

class GrillaSepultura extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSepultura: [],
        }
    }

    componentWillMount() {
        this.setState({
            loading: true,
        });
        axios.get(BaseURLVoyager + 'api/web/Obituario')
            .then(res => {
                this.setState({
                    dataSepultura: res.data,
                    loading: false
                });
            })
    }
    render() {
        return (
            <>
                <ContenidoGrillaFuneral dataSepultura={this.state.dataSepultura} />
            </>
        );
    }
}
export default GrillaSepultura;