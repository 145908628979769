import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
/* Import Imagenes */
import IconsUbicacion from "../../../assets/images/icons/ico_ver_mapa.png";

class ContenidoGrillaSepultura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSepultura: [],
      showAlert: false,
      modal: false,
      image: "",
    };
    this.toggle = this.toggle.bind(this);
  }
  componentWillReceiveProps(props) {
    this.setState({
      dataSepultura: props.dataSepultura,
    });
  }
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { dataSepultura, modal, image } = this.state;
    return (
      <Container>
        <Row>
          <Col>
            {window.innerWidth >= 760 ? (
              <table className="table table-sepulturas">
                {dataSepultura.length > 0 ? (
                  <tr className="header-table">
                    <th>NOMBRE</th>
                    <th>FECHA DEFUNCIÓN</th>
                    <th>UBICACIÓN</th>
                    <th>VER MAPA</th>
                  </tr>
                ) : null}
                {dataSepultura.length === 0 && this.state.busquedaActiva ? (
                  <tr>
                    <td>
                      <p>
                        No se encontraron resultados para{" "}
                        <strong>{this.state.txtNombre}</strong>, favor intente
                        nuevamente
                      </p>
                    </td>
                  </tr>
                ) : null}
                {dataSepultura.map((person) => (
                  <tr key={person.CONTRATO}>
                    <td>{person.INHUMADO}</td>
                    <td align="center">{person.FECHA_DEFUNCION}</td>
                    <td align="center">{person.UBICACION}</td>
                    <td align="center">
                      <img
                        src={IconsUbicacion}
                        onClick={(e) => {
                          this.setState({ image: person.PLANO, modal: true });
                        }}
                        className="icon-ubicacion"
                      />
                      <br />
                      ver mapa
                    </td>
                  </tr>
                ))}
              </table>
            ) : (
              dataSepultura.map((person) => (
                <Col xs={12}>
                  <div key={person.CONTRATO} className="content-grilla">
                    <p>
                      <strong>{person.INHUMADO}</strong>
                    </p>
                    <p>{person.FECHA_DEFUNCION}</p>
                    <p>{person.UBICACION}</p>
                    <div className="content-link-mapa">
                      <img
                        src={IconsUbicacion}
                        onClick={(e) => {
                          this.setState({ image: person.PLANO.replace('https', 'http'), modal: true });
                        }}
                        className="icon-ubicacion"
                      />
                    </div>
                  </div>
                </Col>
              ))
            )}
          </Col>
        </Row>
        {modal && (
          <Modal isOpen={modal} toggle={this.toggle} className="modal-lg">
            <ModalHeader toggle={this.toggle}></ModalHeader>
            <ModalBody>
              <img src={image.replace('https', 'http')} width="100%" />
            </ModalBody>
          </Modal>
        )}
      </Container>
    );
  }
}
export default ContenidoGrillaSepultura;
