import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import SSFF from './SSFF';
import Icon247 from './../../assets/images/icons/24-7.png';

class ServicioFunerario extends Component {
    render() {
        return (
            <div id="cotizar">
                <Row>
                    <Col className="text-center">
                        <div className="content-title-form">
                            <h2>Servicios funerarios</h2>
                            <h2><strong>Estamos con usted cuando mas lo necesita</strong></h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" className="text-left">
                        <div className="content-entrada-form">
                            <h5><strong>Contáctese con nosotros, le ayudaremos y orientaremos en todos los requerimientos necesarios durante su momento difícil.</strong></h5>
                            <div className="contact contact-landing desktop">
                                <div>
                                    <img src="" className="img img-247" alt="" />
                                </div>
                                <div>
                                    <p className="contacto-contactenos text-left"><strong>CONTÁCTENOS LAS 24 HORAS</strong></p>
                                    <p className="contacto-phone"><img src={Icon247} alt='' /><a href="tel:6007363376"> 600 736 3376</a></p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6">
                        <SSFF />
                    </Col>
                </Row>
                <Row className="mobile">
                    <Col>
                        <div className="contact contact-landing">
                            <div>
                                <img src="" className="img img-247" alt="" />
                            </div>
                            <div>
                                <p className="contacto-contactenos text-left"><strong>CONTÁCTENOS LAS 24 HORAS</strong></p>
                                <p className="contacto-phone"><img src={Icon247} alt='' /><a href="tel:6007363376"> 600 736 3376</a></p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default ServicioFunerario;