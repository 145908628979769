import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from "react-router-dom";

class ParqueItem extends Component {
    render() {
        const { link, icon, image, title } = this.props;
        return (
            <Col className="px-nth4-left-1" xs={6} md={4}>
                <Link to={'parques/'+link} target="_self" className="link-parques">
                    <div className="content-parque">
                        <div className="content-icon">
                            <img src={icon} alt=''/>
                        </div>
                        <div className="content-info">
                            <img src={image} style={{width:100+'%'}} alt=""/>
                            <div className="sombra"></div>
                        </div>
                        <h2 className="lato-light">Sendero <strong>{title}</strong></h2>
                    </div>
                </Link>
            </Col>
        );
    }
}

export default ParqueItem;