import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import llamenos from './../../../assets/images/icons/llamenos.svg';
import cotiza from './../../../assets/images/icons/cotiza.svg';
import Chat from '../../Chat';

class FooterFixed extends Component {
  render() {
    return (
      <div className="content-footer-fix">
        <Container>
          <Row>
            <Col>
              <a href="tel:600 736 3376">
                <div>
                  <img src={llamenos} alt="" />
                </div>
                <p>EMERGENCIA</p>
              </a>
            </Col>
            <Col className="b-r-l">
              <Link to={'/cotizar'}>
                <div>
                  <img src={cotiza} alt="" />
                </div>
                <p>COTIZAR</p>
              </Link>
            </Col>
            <Col>
              <div>
                <Chat type='mobile'/>
              </div>
              <p>WHATSAPP</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default FooterFixed;