import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import BannerTop from './../../components/BannerTop';

/* Import Component*/
import ListadoJardines from './../Jardines';
import Json from './Json';
import Servicios from './Servicios';
/* Import Imagenes*/
import Parques from '../../assets/images/Sepultura.jpg';

import dataCarousel from './data';

class Sepultura extends Component {
    componentDidMount() {
        document.title = 'Sendero | Sepultura'
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Container fluid>
                    <BannerTop title1="" title2="Sepultura" image={Parques} />
                </Container>
                <Container>
                    <div className="servicio-sepultura">
                        <p className="info">Encuentre el mejor lugar para honrar la memoria de sus seres queridos. Contamos con más 180 hectáreas a lo largo de Chile, con amplios y hermosos espacios pensados para el descanso eterno y la tranquilidad de cada familia.</p>
                        <Servicios />
                        <br></br>
                        <br></br>
                        <Row className="seccion">
                            <Col className="text-center">
                                <a id="escoja-su-cobertura" href="/#"><h2>Jardines <strong>Temáticos</strong></h2></a>
                                <p>En Sendero contamos con espacios diseñados especialmente para resguardar el descanso según sus creencias y tradiciones.</p>
                            </Col>
                        </Row>
                        <ListadoJardines items={dataCarousel} />
                    </div>
                </Container>
                <Json />
            </>
        );
    }
}
export default Sepultura;