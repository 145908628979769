import React, { Component } from 'react';
import { Container } from 'reactstrap';
import BannerTop from './../../components/BannerTop';
/* Import Component*/
import GrillaSepultura from './GrillaSepultura';
/* Import Imagenes*/
import Parques from '../../assets/images/Ubicacion-Sepultura.jpg';
import ParqueMobile from '../../assets/images/UBICACION_SEPULTURASMobile.jpg';

class UbicacionSepultura extends Component {
    componentDidMount() {
        document.title = 'Sendero | Ubicación de sepultura'
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Container fluid className="ubicacion-sepulturas">
                <BannerTop
                    title1="Ubicación de"
                    title2="Sepulturas"
                    image={window.innerWidth >= 760 ? Parques : ParqueMobile}
                />
                <GrillaSepultura />
            </Container>
        );
    }
}
export default UbicacionSepultura;