import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class PdfPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false,
        }
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }


    render() {
        return (
            <>
                <i id="TooltipExample" onClick={this.toggle} className="fa fa-info-circle" style={{ 'color': '#94C01F', 'fontSize': 15 + 'px' }}></i>
                <Modal isOpen={this.state.tooltipOpen} toggle={this.toggle} className={this.props.className} backdrop={'static'} fade={false}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <ModalBody>
                        <iframe src="https://sendero-api.gux.cl//storage/Como_pagar_su_cuota_con_Khipu_VF.pdf" width="100%" height="500" />
                    </ModalBody>
                </Modal>
            </>
        );
    }
}
export default PdfPopUp;