import React, { Component } from 'react';
import PopUpCotizar from './../../components/PopUpCotizar/';

import { baseUrl } from './../../config';
class JardinItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            txtCantidad: '',
            txtCantidadFija: '',
            txtCiudad: '',
            dataPlanes: '',
            NombrePlan: '',
            Logo: '',
            Text: '',
            activo: false,
            ActivarCotizarPopUp: false
        }
        this.truncate = this.truncate.bind(this);
    }
    onClickCotizar(NombrePlan, Logo, Text) {
        this.setState({
            NombrePlan: NombrePlan,
            Logo: Logo,
            Text: Text,
            ActivarCotizarPopUp: !this.state.ActivarCotizarPopUp
        });
    }

    truncate(text) {
        return text.slice(0, 65) + '...';
    }

    render() {
        const { image, logo, text, nombre } = this.props;

        const parque = window.location.href.replace(baseUrl, '').replace('sepultura/', '').replace('parques/', '').replace('#escoja-su-cobertura', '').replace('-', ' ');
        return (
            <div className="content-jardin-tematico">
                <img src={image} style={{ width: 100 + "%" }} alt="" />
                <div>
                    <img src={logo} className="logo-jardin-tematico" alt="" />
                </div>
                <div>
                    <div>
                        <p className="content-jardin-tematico__text">{this.truncate(text)}</p>
                    </div>

                    <span className="btn button" onClick={(e) => { this.onClickCotizar(nombre, logo, text) }}>COTIZAR</span>
                </div>
                <PopUpCotizar
                    ActivaPopUp={this.state.ActivarCotizarPopUp}
                    producto={nombre}
                    logo={logo}
                    text={text}
                    parque={parque}
                    tipo={'Jardines'}
                    destino_email={'hzsame7e@robot.zapier.com'}
                    type_form='Formulario Jardines'
                />
            </div>
        );
    }
}

export default JardinItem;