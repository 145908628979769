import React, { Component } from 'react';
import { Collapse, Spinner } from 'reactstrap';
import ReactHtmlParser from "react-html-parser";

class DetalleFirmanteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: 1,
            disableButton: true,
            dsc_larga_estado: 'visible'
        };
        this.ocultar = this.ocultar.bind(this);
        this.toggle = this.toggle.bind(this);
        this.selected = this.selected.bind(this);
    }

    toggle(e, tab) {
        e.preventDefault();
        if (this.state.collapse !== tab) {
            this.setState({
                collapse: tab
            });
        } else {
            this.setState({
                collapse: 0
            });
        }
    }
    ocultar(e, val) {
        if (val === 1) {
            this.setState({ dsc_larga_estado: 'oculto' })
        } else {
            this.setState({ dsc_larga_estado: 'visible' })
        }
    }

    selected(e, item) {
        if (e) {
            this.setState({
                disableButton: false
            })
        } else {
            this.setState({
                disableButton: true
            })
        }
        this.props.agregarFirma(e, item);
    }
    render() {
        const { loading_btn, confirmarFirma, firmante } = this.props;
        const { disableButton, dsc_larga_estado } = this.state;
        return (
            <>
                <div className="d-block d-md-none">
                    <h3 className="title-acceso-firmante">Seleccione las opciones que desee firmar</h3>
                    {typeof firmante !== 'undefined' && firmante.length ? (
                        <div className="row">
                            <div className="text-center box-firmante">

                                {firmante && firmante.map((item, index) => (
                                    <>
                                        <div
                                            onClick={(e) => { this.toggle(e, index + 1) }}
                                            className={(parseInt(this.state.collapse) === (index + 1) ? 'open-list' : '') + ' text-list my-2'}
                                            key={index}
                                        >
                                            OPCIÓN {(index + 1)}
                                            <i className="fas fa-greater-than"></i>
                                        </div>
                                        <Collapse isOpen={parseInt(this.state.collapse) === (index + 1) ? true : false}>
                                            <table className="table" id="table-blue">
                                                <tbody className="body-tr">
                                                    <tr>
                                                        <td className="gris">NOMBRES</td>
                                                        <td>{item.CLNT_NMB}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gris">RUT</td>
                                                        <td>{item.CLNT_NMR_RUT + "-" + item.CLNT_DGT_VERIFICADOR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gris">A. PATERNO</td>
                                                        <td>{item.CLNT_APL_PATERNO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gris">A. MATERNO</td>
                                                        <td>{item.CLNT_APL_MATERNO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gris">DIRECCIÓN</td>
                                                        <td>{item.CLNT_NMB_CALLE_PASAJE_PAR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gris">N° DIRECCIÓN</td>
                                                        <td>N° {item.CLNT_NMR_CALLE_PAR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gris">REGIÓN</td>
                                                        <td>{item.RGNS_CDG_PAR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gris">COMUNA</td>
                                                        <td>{item.CMNS_CDG_PAR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gris">E-MAIL</td>
                                                        <td>{item.CLNT_DRC_EMAIL_PAR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gris">PARENTESCO</td>
                                                        <td>{item.PRNT_DSC}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" className="gris">TIPO FIRMANTE</td>
                                                    </tr>
                                                    {dsc_larga_estado === 'visible' ? (
                                                        <tr>
                                                            <td colSpan="2">
                                                                <p>{item.TPFR_DSC}</p>
                                                                <p onClick={(e) => { this.ocultar(e, 1) }} className="ver-mas">Ver Más</p>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>{ReactHtmlParser(item.TPFR_DSC_LARGA)}</p>
                                                                    <p onClick={(e) => { this.ocultar(e, 2) }} className="ver-mas">Ocultar</p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    <tr className="selected">
                                                        <td>SELECCIONAR</td>
                                                        <td className="check">
                                                            <input
                                                                type="checkbox"
                                                                onClick={(e) => { this.selected(e.target.checked, [item]) }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Collapse>
                                    </>
                                ))}

                                <div className="row">
                                    <div className="col-12 offset-md-4 col-md-4 text-center">
                                        <div className="form-group btn-confirm">
                                            <button className="btn btn-verde" onClick={confirmarFirma} disabled={disableButton}>
                                                {
                                                    loading_btn ? (<Spinner style={{ width: '2rem', height: '2rem' }} />) : 'CONFIRMAR'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h3>NO CONTIENE CONTRATOS DISPONIBLES PARA FIRMAR</h3>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="d-none d-md-block">
                    {typeof firmante !== 'undefined' && firmante.length ? (
                        <div className="row">
                            <div className="text-center box-firmante">
                                <table className="table" id="table-blue">
                                    <thead>
                                        <tr className="head">
                                            <th>NOMBRES</th>
                                            <th width="100">RUT</th>
                                            <th>DIRECCIÓN</th>
                                            <th>REGIÓN</th>
                                            <th>COMUNA</th>
                                            <th>E-MAIL</th>
                                            <th>PARENTESCO</th>
                                            <th>SELECCIONAR</th>
                                        </tr>
                                    </thead>
                                    <tbody className="body-tr">
                                        {firmante && firmante.map((item, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>{item.CLNT_NMB} {item.CLNT_APL_PATERNO} {item.CLNT_APL_MATERNO}</td>
                                                    <td>{item.CLNT_NMR_RUT + "-" + item.CLNT_DGT_VERIFICADOR}</td>
                                                    <td>{item.CLNT_NMB_CALLE_PASAJE_PAR} - N° {item.CLNT_NMR_CALLE_PAR}</td>
                                                    <td>{item.RGNS_CDG_PAR}</td>
                                                    <td>{item.CMNS_CDG_PAR}</td>
                                                    <td>{item.CLNT_DRC_EMAIL_PAR}</td>
                                                    <td>{item.PRNT_DSC}</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            onClick={(e) => { this.selected(e.target.checked, [item]) }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="8">
                                                    <p>{ReactHtmlParser(item.TPFR_DSC_LARGA)}</p>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="col-12 offset-md-4 col-md-4 text-center">
                                        <div className="form-group btn-confirm">
                                            <button className="btn btn-verde" onClick={confirmarFirma} disabled={disableButton}>
                                                {
                                                    loading_btn ? (<Spinner style={{ width: '2rem', height: '2rem' }} />) : 'CONFIRMAR'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h3>NO CONTIENE CONTRATOS DISPONIBLES PARA FIRMAR</h3>
                                </div>
                            </div>
                        )
                    }
                </div>
            </>
        );
    }
}

export default DetalleFirmanteComponent;
