import React, { Component } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import Session from '../service/Session';
import Api from '../service/Api';
import { HashLink as Link } from 'react-router-hash-link';

import LogoSendero from './Assets/Images/LogoSendero.png';
import ResponseCodeTransbank from './../components/responseCodeTransbank.jsx';

class End extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buyOrder: 0,
            detalle: [],
            pago: [],
            show: true
        }
        this.getDetalle = this.getDetalle.bind(this);
    }
    componentDidMount() {
        this.setState({ show: true });
        this.getDetalle();
    }
    async getDetalle() {
        const { location } = this.props;
        const { search } = location;
        const buyOrder = new URLSearchParams(search).get('buyOrder');
        let data = {};
        data.buyOrder = buyOrder;
        let response = await Api.getDetalle(data);
        this.setState({ detalle: response.detalle, pago: response.pago, buyOrder: buyOrder, show: false });
    }

    render() {
        const { detalle, pago, buyOrder, show } = this.state;
        const { location } = this.props;
        const { search } = location;
        const coder = new URLSearchParams(search).get('code');
        return (
            <>
                {show ? (
                    <div className="text-center spinner">
                        <Spinner style={{ width: '5rem', height: '5rem' }} />
                    </div>
                ) : (
                    detalle.length > 0 && pago.length > 0 ? (
                        <Container>
                            <Row>
                                <Col className="text-center">
                                    <img src={LogoSendero} className="logoSendero-detalle" alt="" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-title-detalle text-center">
                                        <h2>COMPROBANTE DE PAGO</h2>
                                        <div className="text-center">Estimado (a) {Session.getInfoUserPagoRapido().Pusuario}, Junto con saludarle adjuntamos su comprobante electrónico que registra el pago de su cuenta en Mi Sendero</div>
                                    </div>
                                    <div>
                                        <p className="mis-datos"><strong>Mis Datos</strong></p>
                                    </div>
                                    <div className="back-detalle">
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <th>Nombre Cliente : </th>
                                                    <td>{Session.getInfoUserPagoRapido().Pusuario}</td>
                                                </tr>
                                                <tr>
                                                    <th>Rut Cliente : </th>
                                                    <td>{Session.getInfoUserPagoRapido().Prut}</td>
                                                </tr>
                                                <tr>
                                                    <th>Fecha : </th>
                                                    <td>{pago[0].fecha}</td>
                                                </tr>
                                                <tr>
                                                    <th>Hora Transacción : </th>
                                                    <td>{pago[0].hora}</td>
                                                </tr>
                                                <tr>
                                                    <th>N° Transacción : </th>
                                                    <td>{pago[0].buyorder}</td>
                                                </tr>
                                                <tr>
                                                    <th>Forma de Pago : </th>
                                                    <td>Transbank</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table className="table-detalle">
                                        <thead>
                                            <tr>
                                                <th>N° Contrato</th>
                                                <th>Tipo</th>
                                                <th>Cuota</th>
                                                <th>Monto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detalle.map((item, index) => (
                                                item.monto_cuota > 0 ? (
                                                    <tr key={index}>
                                                        <td>{item.numero_contrato}</td>
                                                        <td>{item.concepto_recaudacion}</td>
                                                        <td>{item.numero_cuota}</td>
                                                        <td>${new Intl.NumberFormat().format(item.monto_cuota)}</td>
                                                    </tr>
                                                ) : null
                                            ))}
                                        </tbody>
                                    </table>
                                </Col><br /><br />
                            </Row>
                            <Row>
                                <Col>
                                    <br />
                                    <Link to={'/pago-rapido/'} className="btn btn-verde button btn btn-secondary">Volver</Link>
                                </Col>
                            </Row>
                        </Container>
                    ) : (
                        <Container>
                            <Row>
                                <Col className="text-center">
                                    <img src={LogoSendero} className="logoSendero-detalle" alt="" />
                                </Col>
                            </Row>
                            <ResponseCodeTransbank code={coder} buyorder={buyOrder} />
                            <Row>
                                <Col>
                                    <br />
                                    <Link to={'/pago-rapido/'} className="btn btn-verde button btn btn-secondary">Volver</Link>
                                </Col>
                            </Row>
                        </Container>
                    )
                )}
            </>
        );
    }
}
export default End;