import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
import { Container } from 'reactstrap';
import Api from '../../service/Api';
import BannerTop from '../BannerTop';
import ReactHtmlParser from 'react-html-parser';
import NotFound from '../../pages/404/NotFound';
import ReglamentoVoyager from './Reglamentos/Reglamento';
import OficinasVoyager from './Oficina/Oficinas';
import ServicioFunerarioVoyager from './ServicioFunerarios/ServicioFunerario';
import CrematoriosVoyager from './Crematorios/Plan';
import CollapseDetail from './Collapse.jsx';
import CotizarJerusalen from '../Forms/CotizarJerusalen';

function searchShortCode(string) {
  if (typeof string !== 'undefined') {
    var ni = string.indexOf('[');
    var nf = string.indexOf(']');
    if (ni >= 0 && nf >= 0) {
      var shortcode = string.slice(ni, nf + 1);
      var params = shortcode.replace('[', '').replace(']', '').split(' ');
      var html = string.replace(shortcode, short(params));
      return html;
    } else {
      return string;
    }
  }
}
function short(params) {
  switch (params[0]) {
    case 'CONTACT':
      return renderToString(<CotizarJerusalen />);
    default:
      return renderToString('');
  }
}
class ModelPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: {},
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.getContent();
    }
  }
  componentDidMount() {
    if (this.props.match.params.slug === 'servicios-funerarios') {
      window.location.href = ' https://www.funerariasendero.cl ';
    }
    this.getContent();
  }
  async getContent() {
    window.scrollTo(0, 0);
    const data = {};
    data.slug = this.props.match.params.slug;
    const response = await Api.getPage(data);
    this.setState({
      items: response.result[0],
    });
  }

  render() {
    const { items } = this.state;
    const { slug } = this.props.match.params;
    return (
      <>
        {typeof items !== 'undefined' && slug !== '' ? (
          <>
            <Container fluid style={{ position: 'relative' }}>
              <BannerTop
                title1={items.nombre}
                title2={''}
                image={
                  window.innerWidth >= 760 ? items.portada : items.portadaMobile
                }
              />
              {slug === 'servicios-funerarios' && (
                <>
                  <div className="btn-flotante-banner">
                    <a href="tel:600 736 3376" className="contacto">
                      <i className="fa fa-phone"></i> Llámenos
                    </a>{' '}
                    <a
                      href="https://api.whatsapp.com/send?phone=56940318444&text=Hola%2C%20necesito más información"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="whatsapp"
                    >
                      <i
                        className="fab fa-whatsapp"
                        style={{ fontSize: 18 }}
                      ></i>{' '}
                      Escríbanos
                    </a>
                  </div>
                </>
              )}
            </Container>
            <Container>
              <div className="">{ReactHtmlParser(items.contenido)}</div>
              {slug === 'nueva-jerusalen' && (
                <>
                  <br />
                  <CotizarJerusalen />
                </>
              )}
              {slug === 'oficinas' && <OficinasVoyager />}
              {slug === 'contratos-y-reglamentos' && <ReglamentoVoyager />}
              {slug === 'servicios-funerarios' && <ServicioFunerarioVoyager />}
              {slug === 'parque-crematorio' && <CrematoriosVoyager />}
              {items.detalle && <CollapseDetail data={items.detalle} />}
            </Container>
            <br />
          </>
        ) : (
          <NotFound />
        )}
      </>
    );
  }
}
export default ModelPage;
