import React, { Component } from 'react'
import Api from './../../../../service/Api';
import Session from '../../../../service/Session';
import { ViewIvas } from '../../../../config';

let contratos = Session.getContratos().contrato;
const checkboxes ={};

class List extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			params: {
				typeGrilla: 'financiamientoDetalle',
				rut: Session.getInfoUser().rut,
				campana: contratos[props.position].CIA,
				parque: contratos[props.position].PARQUE,
				codigo: contratos[props.position].SERIE,
				numero: props.id,
			},
			count: 0,
			montoFin: 0,
			contAnterior: 0,
			contVigente: 0,
		}
		this.validadorEstado = this.validadorEstado.bind(this);
	}
	async componentWillMount() {
		let data = await Api.getDataGrilla(this.state.params);
		data.map((todo) => (
			todo.CTAS_IND_ESTADO_PAGO !== 'P' ? (
				checkboxes[todo.CTAS_NMR] = this.validadorEstado(todo.CTAS_IND_ESTADO_PAGO)
			) : null
		))
		this.setState({
			items: data
		});
	}
	validadorEstado(valor) {
		let retorno = '';
		if (valor !== 'P') {
			if (this.state.count === 0) {
				retorno = false;
				this.state.count = 1;
			} else {
				retorno = true;
			}
		}
		switch (valor) {
			case 'P':
				retorno = true;
				break;
			case 'M':
				retorno = retorno;
				break;
			case 'V':
				retorno = retorno;
				break;
			default:
				break;
		}
		return retorno;
	}
	onClick(e, todo) {
		checkboxes[parseInt(todo.CTAS_NMR) + 1] = !e.target.checked
		checkboxes[parseInt(todo.CTAS_NMR) - 1] = e.target.checked
		var valor = todo.CTAS_MNT_SALDO;
		valor = parseInt(valor.replace('$', '').replace(',', ''))
		this.setState({
			montoFin: e.target.checked ? this.state.montoFin + valor : this.state.montoFin - valor
		});
		this.props.calcularTotal(e, valor, todo);
	}

	renderCabecera() {
		const date = new Date();

        if(date.getFullYear() >= ViewIvas ) {
            return (<>
				<thead>
						<tr className="head">
							<th scope="col">
								CUOTA
							</th>
							<th scope="col">
								FECHA VENCIMIENTO
							</th>
							<th scope="col">
								MONTO NETO
							</th>
							<th scope="col">
								IVA A BENEFICIO FISCAL (*) 
							</th>
							<th scope="col">
								MONTO A PAGAR
							</th>
							<th scope="col">
								ESTADO
							</th>
							<th scope="col">
								SELECCIONAR
							</th>
						</tr>
					</thead>
			</>)
        }


        
        return (<>
                    <thead>
							<tr className="head">
								<th scope="col">
									CUOTA
								</th>
								<th scope="col">
									FECHA VENCIMIENTO
								</th>
								<th scope="col">
									MONTO
								</th>
								<th scope="col">
									ESTADO
								</th>
								<th scope="col">
									SELECCIONAR
								</th>
							</tr>
						</thead>
                </>)
	}

	renderDetalleFinanciamiento(todo,index) {

		const date = new Date();

		if(date.getFullYear() >= ViewIvas ) {

			return(<><tr key={index}>
				<td>{todo.CTAS_NMR}</td>
				<td>{todo.CTAS_FCH_VENCIMIENTO}</td>
				<td align="center">{todo.MNT_NETO === null ? "$" + new Intl.NumberFormat().format(0) : "$" + new Intl.NumberFormat().format(todo.MNT_NETO)}</td>
				<td align="center">{todo.MONTO_IVA === null ? "$" + new Intl.NumberFormat().format(0) : "$" + new Intl.NumberFormat().format(todo.MONTO_IVA)}</td>
				<td align="center">{todo.MONTO_CUOTA === null ? "$" + new Intl.NumberFormat().format(0) : "$" + new Intl.NumberFormat().format(todo.MONTO_CUOTA)}</td>
				<td>{todo.CTAS_IND_ESTADO_PAGO}</td>
				<td align="center">
					<input type="checkbox"
						disabled={
							todo.CTAS_IND_ESTADO_PAGO !== 'P' ? (checkboxes[todo.CTAS_NMR]) : true
						}
						onClick={
							(e) => { this.onClick(e, todo) }
						}
					/>
				</td>
			</tr></>);

		} else {

			return(<><tr key={index}>
				<td>{todo.CTAS_NMR}</td>
				<td>{todo.CTAS_FCH_VENCIMIENTO}</td>
				<td align="center">{todo.CTAS_MNT_SALDO}</td>
				<td>{todo.CTAS_IND_ESTADO_PAGO}</td>
				<td align="center">
					<input type="checkbox"
						disabled={
							todo.CTAS_IND_ESTADO_PAGO !== 'P' ? (checkboxes[todo.CTAS_NMR]) : true
						}
						onClick={
							(e) => { this.onClick(e, todo) }
						}
					/>
				</td>
			</tr></>);

		}												
	}

	render() {
		return (
			<>
				<div className="content-box-grilla">
					<h3 className="title-lists">
						Resumen Financiamiento Sendero
					</h3>
					<table className="table" id="table-gray">
						{
							this.renderCabecera()
						}
						<tbody className="body-tr">
							{
								this.state.items.length === 0 ? "" :
									this.state.items.map((todo, index) => (
										typeof todo.CTAS_MNT_SALDO !== "undefined" ? ( 
											this.renderDetalleFinanciamiento(todo,index)
										) : null
									))
							}
						</tbody>
					</table>
					<div className="content-total-grilla text-right">Total Financiamiento Sendero ${new Intl.NumberFormat().format(this.state.montoFin)}</div>
				</div>
			</>
		)
	}
}
export default List