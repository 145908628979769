import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Desktop from './routesDesktop';
import Header from './components/Header/';
import Footer from './components/Footer/';
import MiSendero from './mi-sendero/routes';
import MiSendero2 from './mi-sendero2.0/routes';
import PagoRapido from './pago-rapido/routes';
import Forme from './pages/Form';

import { BaseUrlNewSendero } from './config';
import Login from './mi-sendero2.0/Page/Login/Login/Login';
import Register from './mi-sendero2.0/Page/Register/Register';
import Recover from './mi-sendero2.0/Page/Recover/Recover';
import Layout from './pago-rapido/Layout';

const renderContent = () => {

    return (
        <Router>
            <Switch>
                <Route path="/mi-sendero" component={MiSendero} />
                <Route path={`/${BaseUrlNewSendero}`} component={MiSendero2} />
                <Route exact path={`/acepta-contrato`} component={() => <><Layout><Login  iscontrato={1}  path={`/acepta-contrato`} /></Layout></>} />
                <Route exact path={`/acepta-contrato/register`} component={() => <><Layout><Register  iscontrato={1} /></Layout></>} />
                <Route exact path={`/acepta-contrato/recover`} component={() => <><Layout><Recover  iscontrato={1} /></Layout></>} />
                <Route path="/pago-rapido" component={PagoRapido} />

                {/* <Route path="/contact-center" component={Forme} /> */}
                {/* <Route component={() => (<><Header /><Desktop /><Footer /></>)} /> */}
                <Route component={() => (<><Desktop /></>)} />
            </Switch>
        </Router>
    );
}


export default renderContent;