import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sendero_contigo from './../../../assets/images/icons/valores-sendero.svg';
import noticias_sendero from './../../../assets/images/icons/noticias.svg';
import donde_pagar from './../../../assets/images/icons/donde_pagar.svg';

class MoreServices extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        var items = [{
            'name_one': 'PRECIOS',
            'name_two': 'SENDERO',
            'link': 'https://masinformados.sendero.cl/precios-sendero/',
            'icon': sendero_contigo,
            'type_link': 'a'
        },
        {
            'name_one': 'NOTICIAS',
            'name_two': 'SENDERO',
            'link': '/posts/noticias',
            'icon': noticias_sendero
        },
        {
            'name_one': 'DONDE',
            'name_two': 'PAGAR',
            'link': '/donde-pagar',
            'icon': donde_pagar
        }]
        return (
            <>
                <div className="seccion text-center">
                    <div className="text-center">
                        <h2>MÁS <strong>SERVICIOS</strong></h2>
                    </div>
                    <div className="row">
                        {items.map((item, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="content-info-parque-contacto more-service">
                                    <div className="row">
                                        <div className="col-4 text-center">
                                            <img src={item.icon} alt="" />
                                        </div>
                                        <div className="col-8 text-center">
                                            <p>{item.name_one} <strong>{item.name_two}</strong></p>
                                            {item.type_link === 'a' ? (
                                                <a href={item.link} target="_blank" className="btn btn-verde">MÁS INFO</a>
                                            ) : (
                                                <Link to={item.link} className="btn btn-verde">MÁS INFO</Link>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default MoreServices;