import React, { Component } from 'react';
import { Col } from 'reactstrap';

class ServicioItem extends Component {
    render() {
        const { nombre, descripcion, portada, url, posicion } = this.props;
        var style = '';
        if (posicion === 0) {
            style = 'back-green';
        }
        if (posicion === 1) {
            style = 'back-blue';
        }
        if (posicion === 2) {
            style = 'back-gris';
        }
        return (
            <>
                <Col md={4} className="servicio-container">
                    <div className={"content-servicio " + style}>
                        <div className="image-container">
                            <img className="cripta" src={portada} alt="" />
                        </div>
                        <div className="text-title">{nombre}</div>
                        <p className="text-content" dangerouslySetInnerHTML={{ __html: descripcion }}></p>
                    </div>
                </Col>
            </>
        );
    }
}
export default ServicioItem;