import React from 'react';
import { Modal, ModalBody, Collapse } from 'reactstrap';
import logoSendero from './../../../assets/images/logo-sendero.svg';
import Preguntas from './Components/Preguntas';
import Formulario from './Components/Formulario';
import FormularioIngreso from './Components/FormularioIngreso';

class Registrar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            collapse: 1,
            rut: '',
            nd: '',
            iscontrato:  props.iscontrato
        }
        this.toggle = this.toggle.bind(this);
        this.siguientePaso = this.siguientePaso.bind(this);
        this.activarPop = this.activarPop.bind(this);
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
            collapse: 1
        });
    }
    handleInputChange(event) {
        this.setState({
            txtMsj: ""
        });
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if (this.state.Rut && this.state.Nd) {
            this.setState({
                disabledButton: true
            });
        } else {
            this.setState({
                disabledButton: false
            });
        }
    }
    siguientePaso(val, object) {
        const { rut, nd, usuario, correo } = object;
        this.setState({
            collapse: val,
            rut: rut,
            nd: nd,
            usuario: usuario,
            correo: correo
        });
    }
    activarPop(e) {
        this.setState({ modal: !this.state.modal });
    }
    render() {
        return (
            <>
                <p onClick={(e) => { this.activarPop(e) }} className="link-pop-up">Registrarse</p>
                <Modal isOpen={this.state.modal}>
                    <div id="content_login_rut" className="container-popup">
                        <div className="btn-close-modal" onClick={this.toggle}><i className="fas fa-times"></i></div>
                        <header>
                            <div className="content-logo">
                                <img src={logoSendero} alt="" />
                            </div>
                        </header>
                        <ModalBody>
                            {this.state.collapse === 1 && (
                                <Collapse isOpen={parseInt(this.state.collapse) === (1) ? true : false}>
                                    <FormularioIngreso
                                        siguientePaso={this.siguientePaso.bind(this)}
                                    />
                                </Collapse>
                            )}
                            {this.state.collapse === 2 && (
                                <Collapse isOpen={parseInt(this.state.collapse) === (2) ? true : false}>
                                    <Preguntas
                                        siguientePaso={this.siguientePaso.bind(this)}
                                        dataUser={this.state}
                                        type='get'
                                    />
                                </Collapse>
                            )}
                            {this.state.collapse === 3 && (
                                <Collapse isOpen={parseInt(this.state.collapse) === (3) ? true : false}>
                                    <Formulario
                                        dataUser={this.state} iscontrato = {this.state.iscontrato}
                                    />
                                </Collapse>
                            )}
                        </ModalBody>
                    </div>
                </Modal >
            </>

        );
    }
}

export default Registrar;