import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Ticket from './../../assets/images/correcto.PNG';
class PageAgradecimientoAgenteLibre extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Container className="page-gracias">
          <Row>
            <Col className="text-center">
              <div className="box-page">
                <img src={Ticket} alt="" />
                <h2 className="title">Gracias por contactarse con nosotros.</h2>
                <p className="content">
                  Hemos recibido sus datos correctamente y nos comunicaremos con
                  usted a la brevedad. Si tiene alguna duda o consulta
                  comuníquese con Agentes.Libres@sendero.cl
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default PageAgradecimientoAgenteLibre;
