import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

import Formulario from '../../components/Forms/TipoMantencion.jsx';

class Mantencion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            type: ''
        }
        this.handleCotizar = this.handleCotizar.bind(this);
    }
    handleCotizar(event) {
        this.setState({
            showModal: !this.state.showModal,
            type: event.target.dataset.type
        });
    }
    render() {
        const { nombre, descripcion, imagen, index } = this.props
        return (
            <div className="tipo-mantencion">
                <Row>
                    <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: index % 2 ? 2 : 1 }}>
                        <div className="content-data">
                            <h2>{ReactHtmlParser(nombre)}</h2>
                            <p>{ReactHtmlParser(descripcion)}</p>
                            <span className="btn button button-cotizar" data-type={nombre} onClick={this.handleCotizar}>Cotizar</span>
                        </div>
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: index % 2 ? 1 : 2 }} className="sinPadding">
                        <img src={imagen} style={{ width: 100 + '%' }} alt="" />
                    </Col>
                </Row>
                <Modal isOpen={this.state.showModal} toggle={this.handleCotizar}>
                    <ModalHeader toggle={this.handleCotizar}></ModalHeader>
                    <ModalBody>
                        <Formulario type={this.state.type} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
export default Mantencion;