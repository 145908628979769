import React, { Component } from 'react';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import SweetAlert from 'sweetalert2-react';
import axios, { CancelToken } from 'axios';
import { BaseURLVoyager } from '../../../config';
import ContenidoGrillaSepultura from './contenido.jsx';

class GrillaSepultura extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSepultura: [],
            txtBusqueda: '',
            txtParque: '',
            txtNombre: '',
            loading: false,
            texto: '',
            enabledButton: true,
            busquedaActiva: false,
            showAlert: false,
        }
        this.Busqueda = this.Busqueda.bind(this);
        this.handleFormInput1 = this.handleFormInput1.bind(this);
        this.handleFormInput = this.handleFormInput.bind(this);
    }

    handleFormInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
        if (this.state.txtNombre.length >= 2) {
            this.setState({
                enabledButton: false,
                busquedaActiva: false
            });
        } else {
            this.setState({
                enabledButton: true,
                busquedaActiva: false
            });
        }
    }

    handleFormInput1(event) {
        const name = event.target.name;
        const value = event.target.value;
        const texto = event.target.value === "T" ? "Titular" : "Fallecido";
        this.setState({
            [name]: value,
            texto: texto
        });
    }
    Busqueda() {
        this.setState({
            loading: true,
        });

        let source = CancelToken.source();
        let timer = setTimeout(() => {
            source.cancel();
            this.setState({
                loading: false,
                showAlert: true,
            });
        }, 30000);

        axios.get(BaseURLVoyager + 'api/web/BusquedaInhumados?busqueda=' + this.state.txtBusqueda + '&parque=' + this.state.txtParque + '&nombre=' + this.state.txtNombre,
            { cancelToken: source.token })
            .then(res => {
                clearTimeout(timer);
                this.setState({
                    dataSepultura: res.data,
                    loading: false,
                    busquedaActiva: true
                });
            })
    }

    render() {
        return (
            <div className="ubicacion-sepulturas">
                <Container>
                    <div className="text-center">
                        <p>Hacemos posible la localización del lugar donde se encuentra su ser querido. Sólo debe digitar el nombre o rut del fallecido que desea buscar y así obtendrá el sector donde está ubicado.</p>
                    </div>
                </Container>
                <div className="content-buscador">
                    <Container>
                        <Row className="ubicacion-sepulturas__inputs">
                            <Col md="4">
                                <Input defaultValue="0" type="select" name="txtBusqueda" id="txtBusqueda" onChange={this.handleFormInput1}>
                                    <option disabled value="0">Tipo de búsqueda</option>
                                    <option value="T">Titular</option>
                                    <option value="I">Fallecido</option>
                                </Input>
                            </Col>
                            <Col md="4">
                                <Input type="select" name="txtParque" id="txtParque" onChange={this.handleFormInput}>
                                    <option disabled value="">Parque</option>
                                    <option value="01">Arica</option>
                                    <option value="02">Iquique</option>
                                    <option value="18">Villa Alemana</option>
                                    <option value="19">Quilpué</option>
                                    <option value="21">Valparaíso</option>
                                    <option value="22">San Antonio</option>
                                    <option value="27">Maipú</option>
                                    <option value="24">San Bernardo</option>
                                    <option value="21">Rancagua</option>
                                    <option value="38">Concepción</option>
                                    <option value="40">Temuco</option>
                                </Input>
                            </Col>
                            <Col md="4">
                                <input name="txtNombre" id="txtNombre" className="form-control" placeholder={`Nombre o Rut ${this.state.texto}`} onChange={this.handleFormInput} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center" md={{ size: 4, offset: 4 }}><Button className="button button-busqueda-ubic" onClick={this.Busqueda} disabled={this.state.enabledButton}><i className="fas fa-search"></i> Buscar</Button></Col>
                        </Row>
                    </Container>
                </div>
                {this.state.loading ? (
                    <div className="grilla-loading">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={15}
                            color={'#454847'}
                            loading={this.state.loading}
                        />
                        <p>Cargando...</p>
                    </div>
                ) : null}
                <ContenidoGrillaSepultura dataSepultura={this.state.dataSepultura} />
                <SweetAlert
                    show={this.state.showAlert}
                    type="warning"
                    confirmButtonText="Aceptar"
                    confirmButtonColor="#00A5C8"
                    text="Por favor intente con un nombre más específico"
                    onConfirm={() => this.setState({ showAlert: false })}
                />
            </div>
        );
    }
}
export default GrillaSepultura;