import React, { Component } from 'react';
class DetallePie extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	render() {
		const { product, mnzn, nombre, contrato, rut, finance } = this.props;
		return (
			<>
				<h3>DETALLE DE ACEPTACIÓN DE CONTRATO</h3>
				<p>Estimado/a está contratando con nosotros el siguiente producto, por lo cual le solicitamos revisar el resumen del contrato y aceptar las condiciones, luego tiene que optar por pagar una parte del pie o el total y para terminar debe pagar mediante Webpay.</p>
				<br/>
				<div className="row row-bottom">
					<div className="col-6 col-md-3">N Contrato</div>
					<div className="col-6 col-md-9">: {contrato}</div>
				</div>
				<div className="row row-bottom">
					<div className="col-6 col-md-3">RUT</div>
					<div className="col-6 col-md-9">: {rut}</div>
				</div>
				<div className="row">
					<div className="col-6 col-md-3">Nombre</div>
					<div className="col-6 col-md-9">: {nombre}</div>
				</div>
				<h3>RESUMEN DEL PRODUCTO</h3>
				<div className="row row-bottom">
					<div className="col-12 col-md-4 text-center">
						<p className="title">Descripción Producto</p>
						<p>{product.DESCRIPCION_PRODUCTO}</p>
					</div>
					<div className="col-12 col-md-4 text-center">
						<p className="title">Parque</p>
						<p>{product.PARQUE_TEMATICO}</p>
					</div>
					<div className="col-12 col-md-4 text-center">
						<p className="title">Código Producto</p>
						<p>{product.CODIGO_PRODUCTO}</p>
					</div>
				</div>
				<div className="row row-bottom">
					<div className="col-12 col-md-4 text-center">
						<p className="title">Manzana</p>
						<p>{mnzn.manzana}</p>
					</div>
					<div className="col-12 col-md-4 text-center">
						<p className="title">Sector</p>
						<p>{mnzn.sector}</p>
					</div>
					<div className="col-12 col-md-4 text-center">
						<p className="title">Fracción</p>
						<p>{mnzn.fraccion}</p>
					</div>
				</div>
				{finance !== '' && (
					<div className="row">
						<div className="col-12 col-md-3 text-center">
							<p className="title">Pie</p>
							<p>{typeof finance.PIE === 'undefined' ? '--' : new Intl.NumberFormat().format(finance.PIE)}</p>
						</div>
						<div className="col-12 col-md-3 text-center">
							<p className="title">Saldo a pagar</p>
							<p>{typeof finance.MONTO_FINANCIAR === 'undefined' ? '--' : new Intl.NumberFormat().format(finance.MONTO_FINANCIAR)}</p>
						</div>
						<div className="col-12 col-md-3 text-center">
							<p className="title">Cuota</p>
							<p>{typeof finance.MONTO_CUOTA === 'undefined' ? '--' : new Intl.NumberFormat().format(finance.MONTO_CUOTA)}</p>
						</div>
						<div className="col-12 col-md-3 text-center">
							<p className="title">Cuotas</p>
							<p>{typeof finance.CUOTAS === 'undefined' ? '--' : finance.CUOTAS}</p>
						</div>
					</div>
				)}
			</>
		)
	}
}
export default DetallePie;