import React, { Component } from 'react';
import Background from './../Assets/Images/fondo-pg.png';
import iconPosterga from './../Assets/Images/icon-posterga.svg';

import Layout from '../Layout';
import { Link } from 'react-router-dom';

class Postergacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <>
                <Layout name="Postergación de Cuotas">
                    <div className="text-center content-postergacion">
                        <div>
                            <p>Estimados, con el ánimo de continuar apoyando a nuestros clientes, Sendero ofrece a usted la posibilidad de cambiar las cuotas que actualmente tiene en mora al final del financiamiento
“sin ningún costo adicional a la cuota que paga hoy” . Con este beneficio usted podrá quedar al día y no pagar recargos por mora y salir de Dicom.</p>
                            <p>Para optar por este beneficio lo puedes hacer acá en Mi Sendero o de forma presencial en Parque y Oficinas con tu cédula de identidad.</p>
                        </div>
                        <div className="back-postergacion" style={{ backgroundImage: `url(${Background})` }}>
                            <div className="row">
                                <div className="col-md-9 text-left">
                                    <img src={iconPosterga} alt=""/>
                                    <h3>Postergación de Cuotas</h3>
                                </div>
                                <div className="col-md-3 text-right">
                                    <Link to={'/mi-sendero/cartola-de-pago'} className="btn btn-verde button">Postergar</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}
export default Postergacion;