import React, { Component } from 'react';
import Api from '../../service/Api';
import Validate from '../../service/Validate';
import FormInputReferido from './FormInputReferido';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                nombreParticipante: '',
                apellidosParticipante: '',
                rutParticipante: '',
                correoParticipante: '',
                telefonoParticipante: '',
                rutReferido: '',
                nombreReferido: '',
                apellidosReferidos: '',
                correoReferidos: '',
                telefonoReferido: '',
                observacion: '',
                parque: '',
                destino_email: this.props.destino_email
            },
            txtMsj: '',
            AlertClass: '',
            disabledButton: false

        };
        this.form = React.createRef()
        this.setForm = this.setForm.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    async setForm() {
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let validation = true
        if(this.state.data.rutParticipante == '') {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Rut del Participante ingresado no es válido.', AlertClass: "alert-danger" });
            validation = false; 
        }

        if(this.state.data.rutParticipante != '') {
            if(!(Validate.validaRut(this.state.data.rutParticipante.replaceAll('.','')))) {
                this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Rut del Participante ingresado no es válido.', AlertClass: "alert-danger" });
                validation = false;
            }
          }
        
        if(this.state.data.nombreParticipante == '') {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Nombre del Participante no puede estar vacío.', AlertClass: "alert-danger" });
            validation = false;
        }

        if(this.state.data.apellidosParticipante == '') {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'Los Apellidos del Participante no pueden estar vacíos.', AlertClass: "alert-danger" });
            validation = false;
        }

        if(this.state.data.correoParticipante == '') {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Correo del Participante no puede estar vacío.', AlertClass: "alert-danger" });
            validation = false;
        }

        if(regexEmail.test(this.state.data.correoParticipante) == false) {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Correo del Participante no es válido.', AlertClass: "alert-danger" });
            validation = false;
        }

        if(this.state.data.telefonoParticipante == '') {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Teléfono del Participante no puede estar vacío.', AlertClass: "alert-danger" });
            validation = false;
        }

        if(this.state.data.telefonoParticipante.length > 10) {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Teléfono del Participante no es válido.', AlertClass: "alert-danger" });
            validation = false;
        }

        if(this.state.data.rutReferido != '') {
            if(!(Validate.validaRut(this.state.data.rutReferido.replaceAll('.','')))) {
                this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Rut del Referido ingresado no es válido.', AlertClass: "alert-danger" });
                validation = false;
            }
          }

        if(this.state.data.nombreReferido == '') {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Nombre del Referido no puede estar vacío.', AlertClass: "alert-danger" });
            validation = false;
        }

        if(this.state.data.apellidosReferidos == '') {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'Los Apellidos del Referido no pueden estar vacíos.', AlertClass: "alert-danger" });
            validation = false;
        }

        // if(this.state.data.correoReferidos == '') {
        //     this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Correo del Referido no puede estar vacío.', AlertClass: "alert-danger" });
        //     validation = false;
        // }

        // if(regexEmail.test(this.state.data.correoReferidos) == false) {
        //     this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Correo del Referido no es válido.', AlertClass: "alert-danger" });
        //     validation = false;
        // }
        
        // if(this.state.data.telefonoReferido == '') {
        //     this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Teléfono del Referido no puede estar vacío.', AlertClass: "alert-danger" });
        //     validation = false;
        // }

        // if(this.state.data.telefonoReferido.length > 10) {
        //     this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + 'El Teléfono del Referido no es válido.', AlertClass: "alert-danger" });
        //     validation = false;
        // }

        if (this.state.data.correoReferidos === '' && this.state.data.telefonoReferido === '') {
            this.setState({
                txtMsj: "Por favor revise los siguientes campos:\n\n" + 
                         'Debe proporcionar al menos un Correo o un Teléfono del Referido.',
                AlertClass: "alert-danger"
            });
            validation = false;
        } else {
            // Validate "Correo Referidos" if it is provided
            if (this.state.data.correoReferidos !== '') {
                if (!regexEmail.test(this.state.data.correoReferidos)) {
                    this.setState({
                        txtMsj: "Por favor revise los siguientes campos:\n\n" + 
                                 'El Correo del Referido no es válido.',
                        AlertClass: "alert-danger"
                    });
                    validation = false;
                }
            }
        
            // Validate "Teléfono Referido" if it is provided
            if (this.state.data.telefonoReferido !== '') {
                if (this.state.data.telefonoReferido.length > 10) {
                    this.setState({
                        txtMsj: "Por favor revise los siguientes campos:\n\n" + 
                                 'El Teléfono del Referido no es válido.',
                        AlertClass: "alert-danger"
                    });
                    validation = false;
                }
            }
        }
        
        if(validation == false) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return false;
        }


        this.setState({ disabledButton: true, nameButton: 'ENVIANDO..' });

        this.setState({ disabledButton: true, nameButton: 'ENVIANDO..' });
        const response = await Api.SendReferido(this.state.data);
        if (response.result) {
            this.setState({
                nombreParticipante: '',
                apellidosParticipante: '',
                rutParticipante: '',
                correoParticipante: '',
                telefonoParticipante: '',
                rutReferido: '',
                nombreReferido: '',
                apellidosReferidos: '',
                correoReferidos: '',
                telefonoReferido: '',
                observacion: '',
                parque: '',
                txtMsj: "Solicitud enviada correctamente",
                AlertClass: "alert-success",
                disabledButton: false
            });

            this.setState({ disabledButton: false, nameButton: "ENVIAR" });

            setTimeout(() => {

                window.location = "/referido/gracias";

            }, 1000);

        } else {
            this.setState({
                txtMsj: "Ocurrió un problema, favor intentar nuevamente",
                AlertClass: "alert-danger",
                disabledButton: false
            });
            this.setState({ disabledButton: false, nameButton: "ENVIAR" });
        }
    }
    onChange(event) {

        const { name, value } = event.target;

        this.setState({ txtMsj: "" , AlertClass: "" });

        this.setState((prevState) => {
            
            const updatedData = { ...prevState.data };
    
            if (name === 'nombreParticipante' || name === 'apellidosParticipante' || name === 'nombreReferido' || name === 'apellidosReferidos') {
                
                updatedData[name] = value.replace(/[^a-zA-Z ]/g, "");
            
            } else if (name === 'rutParticipante' || name === 'rutReferido') {
                
                updatedData[name] = value.replace(/[.-]/g, "").replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
            
            } else if (name === 'telefonoParticipante') {
                let numericValue = value.replace(/\D/g, ""); // Solo números

                if (numericValue === "") {
                    if(prevState.data.telefonoParticipante.length == 1) {
                        numericValue = ""
                    } else {
                        numericValue = prevState.data.telefonoParticipante;
                    }
                }
                updatedData[name] = numericValue;
            
            }  else if (name === 'telefonoReferido') {
                let numericValue = value.replace(/\D/g, ""); // Solo números

                if (numericValue === "") {
                    if(prevState.data.telefonoReferido.length == 1) {
                        numericValue = ""
                    } else {
                        numericValue = prevState.data.telefonoReferido; 
                    }
                }

                updatedData[name] = numericValue;
            
            } else {
                
                updatedData[name] = value;
            }
    
            return { data: updatedData };
        }, () => {
            console.log(this.state.data);
        })

    }
    render() {
        const { txtMsj, AlertClass, disabledButton, data } = this.state;
        const { RegionText } = data;
        const { nameButton } = this.props;
        return (
            <>
                
                <form ref={this.form} style={{'background-color': '#e9ecef','padding':'10px'}}>
                    <FormInputReferido
                        onChange={this.onChange}
                        setForm={this.setForm}
                        txtMsj={txtMsj}
                        AlertClass={AlertClass}
                        nameButton={nameButton}
                        disabledButton={disabledButton}
                        validateSelect={RegionText}
                        value={this.state.data}
                    />
                </form>
            </>
        );
    }
}
export default Form;