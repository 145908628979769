import React, { Component } from "react";

class BoxHome extends Component {
  render() {
    const { type, extra, name, icon, first, col } = this.props;
    return (
      <div className="content">
        <div className={type + " " + extra}>
          <div className="row">
            <div className={col === 12 ? "text-center col-2" : "col-12"}>
              <img src={icon} className="icon" />
            </div>
            <div className={col === 12 ? "text-left col-10" : "col-12"}>
              <p className="home-contactenos-mobile">{name}</p>
              <p className={"home-text-mobile " + (name === "" ? "top" : "")}>
                {first}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BoxHome;
