import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import axios from 'axios';
import { BaseURLVoyager } from '../../../config';

function formatDate(date) {
    return date.split(" ")[0];
}

function formatTime(date) {
    let t = date.split(" ")[1];
    let s = t.split(":");
    return `${s[0]}:${s[1]}`;
}

class ContenidoGrillaFuneral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSepultura: [],
            loading: false,
            orderAsc: true,
        }
        this.sortDataName = this.sortDataName.bind(this);
        this.sortDataHora = this.sortDataHora.bind(this);
        this.sortDataParque = this.sortDataParque.bind(this);
    }

    componentWillMount() {
        this.setState({
            loading: true,
            dataSepultura: this.props.dataSepultura
        });
        axios.get(BaseURLVoyager + 'api/web/Obituario')
            .then(res => {
                this.setState({
                    dataSepultura: res.data,
                    loading: false
                });
            })
    }

    sortDataName() {
        let data = this.state.dataSepultura;
        if (this.state.orderAsc) {
            data.sort(function (a, b) {
                if (a.NOMBRE_FALLECIDO.trim() < b.NOMBRE_FALLECIDO.trim()) { return -1; }
                if (a.NOMBRE_FALLECIDO.trim() > b.NOMBRE_FALLECIDO.trim()) { return 1; }
                return 0;
            });
        } else {
            data.sort(function (a, b) {
                if (a.NOMBRE_FALLECIDO.trim() > b.NOMBRE_FALLECIDO.trim()) { return -1; }
                if (a.NOMBRE_FALLECIDO.trim() < b.NOMBRE_FALLECIDO.trim()) { return 1; }
                return 0;
            });
        }

        this.setState({
            dataSepultura: data,
            orderAsc: !this.state.orderAsc,
        });
    }
    sortDataParque() {
        let data = this.state.dataSepultura;
        if (this.state.orderAsc) {
            data.sort(function (a, b) {
                if (a.PARQUE_FISICO.trim() < b.PARQUE_FISICO.trim()) { return -1; }
                if (a.PARQUE_FISICO.trim() > b.PARQUE_FISICO.trim()) { return 1; }
                return 0;
            });
        } else {
            data.sort(function (a, b) {
                if (a.PARQUE_FISICO.trim() > b.PARQUE_FISICO.trim()) { return -1; }
                if (a.PARQUE_FISICO.trim() < b.PARQUE_FISICO.trim()) { return 1; }
                return 0;
            });
        }

        this.setState({
            dataSepultura: data,
            orderAsc: !this.state.orderAsc,
        });
    }
    sortDataHora() {
        let data = this.state.dataSepultura;
        if (this.state.orderAsc) {
            data.sort(function (a, b) {
                if (a.FECHA_SERVICIO.trim() < b.FECHA_SERVICIO.trim()) { return -1; }
                if (a.FECHA_SERVICIO.trim() > b.FECHA_SERVICIO.trim()) { return 1; }
                return 0;
            });
        } else {
            data.sort(function (a, b) {
                if (a.FECHA_SERVICIO.trim() > b.FECHA_SERVICIO.trim()) { return -1; }
                if (a.FECHA_SERVICIO.trim() < b.FECHA_SERVICIO.trim()) { return 1; }
                return 0;
            });
        }

        this.setState({
            dataSepultura: data,
            orderAsc: !this.state.orderAsc,
        });
    }
    render() {
        const { dataSepultura } = this.state;
        return (
            <>
                {this.state.loading ? (
                    <div className="grilla-loading">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={15}
                            color={'#454847'}
                            loading={this.state.loading}
                        />
                        <p>Cargando...</p>
                    </div>
                ) : null}
                <Container>
                    <Row>
                        <Col>
                            {window.innerWidth >= 760 ? (
                                <table className="table funeral-del-dia">
                                    <tbody>
                                        {dataSepultura.length > 0 ? (
                                            <tr className="header-table">
                                                <th className="funeral-del-dia__name" onClick={this.sortDataName}>NOMBRE <i className="funeral-del-dia__icon-sort fas fa-sort"></i></th>
                                                <th>FECHA</th>
                                                <th className="funeral-del-dia__name" onClick={this.sortDataHora}>HORA <i className="funeral-del-dia__icon-sort fas fa-sort"></i></th>
                                                <th>MANZANA</th>
                                                <th className="funeral-del-dia__name" onClick={this.sortDataParque}>PARQUE <i className="funeral-del-dia__icon-sort fas fa-sort"></i></th>
                                                <th>ACCESO</th>
                                            </tr>
                                        ) : null}
                                        {
                                            dataSepultura.map(person =>
                                                <tr key={person.NOMBRE_FALLECIDO}>
                                                    <td className="text-left">{person.NOMBRE_FALLECIDO}</td>
                                                    <td align="center">{formatDate(person.FECHA_SERVICIO)}</td>
                                                    <td align="center">{formatTime(person.FECHA_SERVICIO)}</td>
                                                    <td align="center">{person.MANZANA_FRACCION_DESTINO} - {person.NUMERO_FRACCION_DESTINO}</td>
                                                    <td align="center">{person.PARQUE_FISICO.replace("ORGANIZACION SENDERO - ", "")}</td>
                                                    <td align="center">{person.ACCESO_PARQUE}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                    dataSepultura.map(
                                        person => (
                                            <Col xs="12">
                                                <div key={person.NOMBRE_FALLECIDO} className="content-grilla px-0 mx-0">
                                                    <p><strong>{person.NOMBRE_FALLECIDO}</strong></p>
                                                    <p>{formatDate(person.FECHA_SERVICIO)}</p>
                                                    <p>{formatTime(person.FECHA_SERVICIO)}</p>
                                                    <p>{person.MANZANA_FRACCION_DESTINO} - {person.NUMERO_FRACCION_DESTINO}</p>
                                                    <p>{person.PARQUE_FISICO.replace("ORGANIZACION SENDERO - ", "")}</p>
                                                    <p>{person.ACCESO_PARQUE}</p>
                                                </div>
                                            </Col>
                                        )
                                    )
                                )}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}
export default ContenidoGrillaFuneral;