import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import BannerTop from '../../components/BannerTop';
import Parques from '../../assets/images/TipoMantencion.jpg';
import Mantencion from './Mantencion';
import Tipo from './Tipo';
import Api from '../../service/Api';
import Box from '../../components/Boxes';

class TipoMantencion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            type: '',
            contenido: {},
            mantencion: {},
            tipos: {}

        }
        this.handleCotizar = this.handleCotizar.bind(this);
    }

    componentDidMount() {
        document.title = 'Sendero | Tipo de mantención'
        window.scrollTo(0, 0);
        this.getContent();
    }

    handleCotizar(event) {
        this.setState({
            showModal: !this.state.showModal,
            type: event.target.dataset.type
        });
    }
    async getContent() {
        const data = {}
        data.slug = this.state.slug;
        const response = await Api.getPageTipoMantencion(data);
        this.setState({
            contenido: response[0][0],
            mantencion: response[1],
            tipos: response[2]
        })
    }

    render() {
        const { contenido, mantencion, tipos } = this.state
        return (
            <div className="tipo-mantencion">
                <Container fluid>
                    <BannerTop title1="Tipos de" title2="Mantenciones" image={Parques} />
                    <Container>
                        <Row>
                            <Col>
                                <div className="sobre-sendero__info">
                                    <p>{ReactHtmlParser(contenido.texto_top)}</p>
                                </div>
                            </Col>
                        </Row>
                        {
                            mantencion.length > 0 && (
                                mantencion.map((item, index) => (
                                    <Mantencion key={index} index={index} nombre={item.nombre} descripcion={item.descripcion} imagen={item.portada} />
                                ))
                            )
                        }
                        <br />
                        <br />
                        <Row>
                            <Col className="text-center">
                                {ReactHtmlParser(contenido.texto_intermedio)}
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Box typeBox={'TypeMaintenanceItem'} type={3} />
                        <br />
                        <br />
                        <Row>
                            <Col>
                                {ReactHtmlParser(contenido.texto_footer)}
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    }
}
export default TipoMantencion;