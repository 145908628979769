import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Api from '../../../service/Api';
import Box from '../../../components/Boxes';

class TypeMantencion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contenido: {},
            mantencion: {},
        }
    }
    componentDidMount() {
        this.getContent();
    }

    async getContent() {
        const data = {}
        data.slug = 'tipos-de-mantenciones';
        const response = await Api.getPageTipoMantencion(data);
        this.setState({
            contenido: response[0][0],
            mantencion: response[1],
            tipos: response[2]
        })
    }
    render() {
        const { contenido } = this.state
        const { calendar, calendario } = this.props;
        return (
            <>
                <div className="row mb-2">
                    <div className="col-md-12 text-center">
                        <p dangerouslySetInnerHTML={{
                            __html: `${contenido.texto_intermedio}`,
                        }}></p>
                    </div>
                </div>
                <div className="my-5">
                    <Box typeBox={'TypeMaintenanceItem'} type={3} />
                </div>
                {calendario && calendario !== null && (
                    <div className="seccion text-center">
                        <div className="text-center">
                            <h2>CALENDARIO <strong>MANTENCIÓN</strong></h2>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <img src={calendar} alt='' className='img-fluid' />
                            </div>
                        </div>
                    </div>
                )}
                <div className='row'>
                    <div className='col-12 text-center mb-5 pb-2'>
                        <Link className="btn button" to={'/mas-parque-para-todos'}>Descubre Más Parque Para Todos</Link>
                    </div>
                </div>
            </>
        );
    }
}

export default TypeMantencion;