/* Icono Parque */
import icon from './../../../../assets/images/Parques/IconRancagua.png';
/* Logos Iconos Jardines Temáticos */
import sendero from './../../../../assets/images/Parques/icon/sendero.png'; // Parque del Sendero
import familiar from './../../../../assets/images/Parques/icon/familiar.png'; //Tradición Familiar
import mausoleo from './../../../../assets/images/Parques/icon/mausoleo.png'; // Jardin Mausoleo
import privado from './../../../../assets/images/Parques/icon/privado.png'; // Jardin Privado
import noble from './../../../../assets/images/Parques/icon/noble.png'; // Jardín Noble
import sanlorenzo from './../../../../assets/images/Parques/icon/sanlorenzo.png';  // San Lorenzo
/* Fotos Jardines Temáticos */
import img_sendero from './../../../../assets/images/Parques/Rancagua/img_sendero.jpg'; // Img Parque del Sendero
// import img_familiar from './../../../../assets/images/Parques/Rancagua/img_familiar.jpg'; // Img Tradición Familiar
import img_mausoleo from './../../../../assets/images/Parques/Rancagua/img_mausoleo.jpg'; // Jardin Mausoleo
// import img_privado from './../../../../assets/images/Parques/Rancagua/img_privado.jpg'; // Img Jardin Privado
import img_noble from './../../../../assets/images/Parques/Rancagua/img_noble.jpg'; // Img Jardín Noble
import img_sanlorenzo from './../../../../assets/images/Parques/Rancagua/img_sanlorenzo.jpg';  // Img San Lorenzo

import img_familiar_new from './../../../../assets/images/Parques/new/img_jardin_familiar.png';  // Img Tradición Familiar
import img_privado_new from './../../../../assets/images/Parques/new/img_jardin_privado.png'; // Img Jardin Privado

export default {
    icon: icon,
    carouselItems: [
        {
            image: img_sendero,
            logo: sendero,
            text: 'Es un espacio que cuenta con grandes áreas verdes enmarcadas por hermosas arboleda.',
            nombre: 'Parque del Sendero',
            link: ''
        },
        {
            image: img_familiar_new,
            logo: familiar,
            text: 'Un área diseñado para estar en contacto con la naturaleza. La hermosa fuente de agua que rodea el espacio permite una tranquilidad y conexión con sus seres queridos.',
            nombre: 'Tradición Familiar',
            link: ''
        },

        {
            image: img_mausoleo,
            logo: mausoleo,
            text: 'Lo mejor de lo tradicional, acompañado por la belleza natural que ofrece un parque cementerio.',
            nombre: 'Jardín Mausoleo',
            link: ''
        },
        {
            image: img_privado_new,
            logo: privado,
            text: 'Un espacio privilegiado donde se resguarda la memoria y el descanso de sus seres queridos.',
            nombre: 'Jardín Privado',
            link: ''
        },
        {
            image: img_noble,
            logo: noble,
            text: 'Conmemore con elegancia y distinción el recuerdo de sus seres queridos. El espacio es resguardado por un cierre perimetral de fierro, con puerta y escaño para uso exclusivo de su familia.',
            nombre: 'Jardín Noble',
            link: ''
        },
        {
            image: img_sanlorenzo,
            logo: sanlorenzo,
            text: 'Para las familias mineras que prefieren honrar a sus seres queridos en un entorno de espiritualidad. Un área ubicada a los pies del altar de San Lorenzo, con oratorio y entorno natural. Un espacio exclusivo para honrar a sus seres queridos al amparo del Santo Patrono de los mineros.',
            nombre: 'Jardines San Lorenzo',
            link: ''
        },
    ],

    jardinesTematicos: [
        sendero,
        familiar,
        sanlorenzo,
        mausoleo,
        privado,
        noble
    ],
}





