import React, { Component } from 'react';
import { Container, Row, Col, Input } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import PopUpCotizar from './../../../components/PopUpCotizar/';
import Validate from '../../../service/Validate';
import data from './dataPlanes.json';

class Sepultura extends Component {
    constructor(props) {
        super(props);
        this.state = {
            txtCantidad: null,
            txtCiudad: this.props.slug,
            NombrePlan: '',
            Precio: 0,
            result: null,
            modal: false,
        }
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFormInput = this.handleFormInput.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    handleFormInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value,
            result: null,
        });
    }
    handleSearch() {
        let cantidad = this.state.txtCantidad;
        let ciudad = this.state.txtCiudad;
        let mensaje = "";
        let rules = {
            selector1: 'txtCantidad',
            selector2: 'txtCiudad'
        };
        let validate = {};
        for (var [type, input] of Object.entries(rules)) {
            let resul = Validate.ValidateForm(this.state[input], type.replace(/[0-9]/g, ''), input)
            if (resul !== '') {
                mensaje += resul
                validate[input] = [resul];
            }
        }
        Validate.validate(document.getElementsByClassName("form-select-plain-01")[0], validate)
        if (cantidad && ciudad) {
            this.setState({
                result: data[ciudad][0][cantidad],
            });
        }
    }

    onClickCotizar(NombrePlan, Precio) {
        this.setState({
            NombrePlan: NombrePlan,
            Precio: Precio,
            modal: true
        });
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        return (
            <>
                <Container>
                    <div className="seccion text-center">
                        <Col className="text-center">
                            <h2>Seleccione su <strong>Plan</strong></h2>
                            <p>En Sendero los planes están pensados para cada tipo de familia. Es por ello, que hemos creado una función para que pueda cotizar fácilmente con nosotros sin importar dónde se encuentre.</p>
                        </Col>
                    </div>
                </Container>
                <div className="fondo-gris">
                    <Container>
                        <Row>
                            <Col>
                                <form className="form-select-plain-01 row">
                                    <Col md="5">
                                        <p id="escoja-su-cobertura"><strong>CANTIDAD DE PERSONAS</strong></p>
                                        <Input defaultValue="0" type="select" name="txtCantidad" onChange={this.handleFormInput}>
                                            <option disabled value="0">Seleccione</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="8">8</option>
                                        </Input>
                                    </Col>
                                    <Col md="5">
                                        <p><strong>ESCOJA SU PARQUE</strong></p>
                                        <Input type="select" name="txtCiudad" onChange={this.handleFormInput} defaultValue={this.props.slug}>
                                            <option disabled value="0">Seleccione</option>
                                            <option value="arica">Arica</option>
                                            <option value="iquique">Iquique</option>
                                            <option value="villa-alemana">Villa Alemana</option>
                                            <option value="quilpue">Quilpué</option>
                                            <option value="valparaiso">Valparaíso</option>
                                            <option value="san-antonio">San Antonio</option>
                                            <option value="maipu">Maipú</option>
                                            <option value="san-bernardo">San Bernardo</option>
                                            <option value="rancagua">Rancagua</option>
                                            <option value="concepcion">Concepción</option>
                                            <option value="temuco">Temuco</option>
                                        </Input>
                                    </Col>
                                    <Col md="2">
                                        <div className="btn-busqueda-sepultura btn-buscar-text" onClick={this.handleSearch}>
                                            <i className="fas fa-search"></i> Buscar
                                        </div>
                                    </Col>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <br />
                <Container>
                    {this.state.result ? (
                        <>
                            <h2 className="title-resultado-busqueda">Resultado de <strong>búsqueda</strong></h2>
                            <hr />
                            <Row>
                                {this.state.result.map(item => (
                                    <Col key={item.Nombre} md="4">
                                        <div className="content-planes">
                                            <p className="plan-title-top">{item.Nombre}</p>
                                            <hr></hr>
                                            <p>{ReactHtmlParser(item.Jardin)}</p>
                                            <p className="content-title-plan">{this.state.txtCantidad} {this.state.txtCantidad === "1" ? "Persona" : "Personas"}</p>
                                            <p className="secciones">{ReactHtmlParser(item.Descripcion)}</p>
                                            <p className="secciones">{ReactHtmlParser(item.Incluye)}</p>
                                            <p className="content-title-plan2">Desde <span className="value">UF {item.Precio}</span></p>
                                            <span className="btn button button-cotizar" onClick={(e) => { this.onClickCotizar(item.Nombre, 'Desde UF ' + item.Precio) }}>
                                                Cotizar
                                            </span>
                                            <span className="cotizar-extra">
                                                Todos los precios están sujeto a modificaciones
                                            </span>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </>
                    ) : null}
                </Container>
                <PopUpCotizar
                    ActivaPopUp={this.state.modal}
                    producto={this.state.NombrePlan}
                    cantidad={this.state.txtCantidad}
                    parque={this.state.txtCiudad}
                    precio={this.state.Precio}
                    tipo={'Planes'}
                    destino_email={'f760llpx@robot.zapier.com'}
                    type_form='Formulario Planes Sepultura'
                />
            </>
        );
    }
}
export default Sepultura;