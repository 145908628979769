import React, { Component } from "react";
import Api from "../../service/Api";
import Validate from "../../service/Validate";
import { Button, Alert } from "reactstrap";

class SSFF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Nombre: "",
        ApellidoP: "",
        ApellidoM: "",
        Rut: "",
        Email: "",
        Ext: "",
        Telefono: "",
        Mensaje: "",
        TipoNecesidadText: "",
        destino_email: 'p2jzl6ps@robot.zapier.com',
        type_form: 'Formulario Servicio Funeraria',
      },
      txtMsj: "",
      AlertClass: "",
      disabledButton: false,
      nameButton: 'COTIZAR'
    };
    this.form = React.createRef();
    this.setForm = this.setForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  async setForm() {
    let mensaje = "";
    let rules = {
      texto: "Nombre",
      texto1: "ApellidoP",
      texto2: "ApellidoM",
      rut: "Rut",
      email: "Email",
      numerico: "Telefono",
      extension: "Ext",
      selector2: "TipoNecesidadText",
    };
    let validate = {};
    for (var [type, input] of Object.entries(rules)) {
      let resul = Validate.ValidateForm(
        this.state.data[input],
        type.replace(/[0-9]/g, ""),
        input
      );
      if (resul !== "") {
        mensaje += resul;
        validate[input] = [resul];
      }
    }
    Validate.validate(this.form.current, validate);
    if (mensaje !== "") {
      //this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + mensaje, AlertClass: "alert-danger" });
      this.setState({
        txtMsj: "Se encuentran campos con errores, favor revisar",
        AlertClass: "alert-danger",
      });
      return false;
    }
    this.setState({ disabledButton: true, nameButton: "ENVIANDO.." });
    const response = await Api.setForm(this.state.data);
    if (response.result === "true") {
      this.setState({
        Nombre: "",
        ApellidoP: "",
        ApellidoM: "",
        Rut: "",
        Email: "",
        Ext: "",
        Telefono: "",
        Mensaje: "",
        txtMsj: "Correo enviado correctamente",
        AlertClass: "alert-success",
        disabledButton: false,
      });
      window.location = "/gracias/";
    } else {
      this.setState({
        txtMsj: "Ocurrió un problema, favor intentar nuevamente",
        AlertClass: "alert-danger",
        disabledButton: false,
      });
    }
  }
  onChange(event) {
    const { name, value } = event.target;
    const { data } = this.state;
    if (name === "Rut") {
      data[name] = value
        .replace(/[.-]/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    } else {
      data[name] = value;
    }
    this.setState({
      data,
    });
  }
  render() {
      const {nameButton,disabledButton} = this.state;
    return (
      <>
        <form ref={this.form}>
          <div className="row-form row">
            <div className="col-12 col-sm-12">
              <input
                className="form-control"
                type="text"
                name="Nombre"
                id="Nombre"
                placeholder="Nombre *"
                onChange={this.onChange}
                value={this.state.Nombre}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                className="form-control"
                type="text"
                name="ApellidoP"
                id="ApellidoP"
                placeholder="Apellido Paterno *"
                onChange={this.onChange}
                value={this.state.Apellido}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                className="form-control"
                type="text"
                name="ApellidoM"
                id="ApellidoM"
                placeholder="Apellido Materno*"
                onChange={this.onChange}
                value={this.state.Apellido}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                className="form-control"
                type="text"
                name="Rut"
                id="Rut"
                placeholder="Rut *"
                onChange={this.onChange}
                value={this.state.Rut}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                className="form-control"
                type="email"
                name="Email"
                id="Email"
                placeholder="Email *"
                onChange={this.onChange}
                value={this.state.Email}
              />
            </div>
            <div className="col-3 col-sm-3">
              <input
                className="form-control"
                type="number"
                name="Ext"
                id="Ext"
                placeholder="+56 *"
                onChange={this.onChange}
                value={this.state.Ext}
              />
            </div>
            <div className="col-9 col-sm-9">
              <input
                className="form-control"
                type="email"
                name="Telefono"
                id="Telefono"
                placeholder="Teléfono *"
                onChange={this.onChange}
                value={this.state.Telefono}
              />
            </div>
            <div className="col-12 col-sm-12">
              <select
                name="TipoNecesidadText"
                id="TipoNecesidadText"
                className="form-control"
                onChange={this.onChange}
              >
                <option value="0">Seleccionar Tipo Necesidad</option>
                <option value="Inmediata">Inmediata</option>
                <option value="Futura">Futura</option>
              </select>
            </div>
            <div className="col-12 col-sm-12">
              <textarea
                className="form-control"
                type="textarea"
                name="Mensaje"
                id="Mensaje"
                placeholder="Mensaje..."
                onChange={this.onChange}
                value={this.state.Mensaje}
              ></textarea>
            </div>
            <div className="form-group row">
              <div className="col-sm-12 text-center">
                {this.state.txtMsj !== "" ? (
                  <Alert className={"alert " + this.state.AlertClass}>
                    {this.state.txtMsj}
                  </Alert>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="text-center col-12 col-sm-12">
              <Button className="btn btn-verde button" onClick={this.setForm} disabled={disabledButton}>
                {nameButton}
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  }
}
export default SSFF;
