import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Input, Alert } from 'reactstrap';
import Api from '../../service/Api';
import Validate from '../../service/Validate';

class Formulario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Parque: 0,
            Nombre: '',
            Rut: '',
            Email: '',
            Telefono: '',
            Mensaje: '',
            txtMsj: '',
            AlertClass: '',
            nameButton: 'COTIZAR',
            producto: '',
            type_form: 'Formulario Tipo Mantencion',
            destino_email: '42c7fe3m@robot.zapier.com'
        }
        this.setForm = this.setForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentWillMount() {
        this.setState({ producto: this.props.type });
    }
    async setForm() {
        let mensaje = "";
        let rules = {
            texto0: 'Nombre',
            texto1: 'Rut',
            texto2: 'Telefono',
            texto3: 'Mensaje',
            email: 'Email',
            selector1: 'Parque'
        };
        let validate = {};
        for (var [type, input] of Object.entries(rules)) {
            let resul = Validate.ValidateForm(this.state[input], type.replace(/[0-9]/g, ''), input)
            if (resul !== '') {
                mensaje += resul
                validate[input] = [resul];
            }
        }
        Validate.validate(document.getElementsByClassName("form-tipos-de-mantenciones-01")[0], validate)
        if (mensaje !== "") {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + mensaje, AlertClass: "alert-danger" });
            return false;
        } else {
            this.setState({ disabledButton: true, nameButton: 'ENVIANDO..' });
            const response = await Api.setForm(this.state);
            if (response.result === 'true') {
                this.setState({
                    Nombre: '',
                    Rut: '',
                    Email: '',
                    Telefono: '',
                    Mensaje: '',
                    disabledButton: false,
                    nameButton: 'COTIZAR',
                    txtMsj: "Correo enviado correctamente",
                    AlertClass: "alert-success"
                });
                window.location = "/gracias/";
            } else {
                this.setState({
                    txtMsj: "Ocurrió un problema, favor intentar nuevamente",
                    AlertClass: "alert-danger",
                    nameButton: 'COTIZAR',
                    disabledButton: false
                });
            }
        }
    }
    handleInputChange(event) {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        if (name === 'Rut') {
            value = value.replace(/[.-]/g, '').replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
        }
        this.setState({
            [name]: value
        });
    }
    render() {
        return (
            <div className="popup-cotizar">
                <Form className="form-tipos-de-mantenciones-01">
                    <FormGroup>
                        <Row>
                            <Col md={12} className="text-left">
                                <p className="bold">Usted está cotizando:</p>
                            </Col>
                        </Row>
                        <Row className="content-detalle-pop-up">
                            <Col md={12}>
                                <p className="text-popup"><strong>Producto:</strong> {this.props.type}</p>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Input type="select" name="Parque" id="Parque" onChange={this.handleInputChange}>
                                <option value="0">Parque</option>
                                <option value="Arica">Arica</option>
                                <option value="Iquique">Iquique</option>
                                <option value="Villa Alemana">Villa Alemana</option>
                                <option value="Quilpue">Quilpué</option>
                                <option value="Valparaiso">Valparaíso</option>
                                <option value="San Antonio">San Antonio</option>
                                <option value="Maipu">Maipú</option>
                                <option value="San Bernardo">San Bernardo</option>
                                <option value="Rancagua">Rancagua</option>
                                <option value="Concepcion">Concepción</option>
                                <option value="Temuco">Temuco</option>
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Input type="text" name="Nombre" id="Nombre" placeholder="Nombre*" onChange={this.handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Input type="text" name="Rut" id="Rut" placeholder="RUT*" onChange={this.handleInputChange} value={this.state.Rut} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Input type="email" name="Email" id="Email" placeholder="Email*" onChange={this.handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Input type="text" name="Telefono" id="Telefono" placeholder="Teléfono" onChange={this.handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Input type="textarea" name="Mensaje" id="Mensaje" placeholder="Mensaje..." onChange={this.handleInputChange} />
                        </Col>
                    </FormGroup>
                    <div className="form-group row">
                        <div className="col-sm-12 text-center">
                            {this.state.txtMsj !== "" ? <Alert className={'alert ' + this.state.AlertClass}>{this.state.txtMsj}</Alert> : ""}
                        </div>
                    </div>
                    <FormGroup row>
                        <Col sm={12} className="text-center">
                            <Button className="btn btn-verde popup-cotizar__button" onClick={this.setForm} disabled={this.state.disabledButton}>{this.state.nameButton}</Button>
                        </Col>
                    </FormGroup>
                </Form>

            </div>
        );
    }
}
export default Formulario;