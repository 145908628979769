import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class BoxPunto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name_action: "Ver más",
      status_action: false,
    };
    this.onClick = this.onClick.bind(this);
  }
  onClick(e) {
    this.setState({
      name_action: this.state.status_action ? "Ver más" : "Ver menos",
      status_action: !this.state.status_action,
    });
  }
  render() {
    const { nombre, portada, descripcion, color, col } = this.props;
    const { name_action, status_action } = this.state;
    return (
      <div className={"col-6 col-md-" + col + " text-center"}>
        <div className="box-punto">
          <div className="content-img">
            <div className={"circle " + color}>
              <img src={portada} alt="" />
            </div>
          </div>
          <p className="title-box">{nombre}</p>
          {status_action && <p>{ReactHtmlParser(descripcion)}</p>}
          <p onClick={this.onClick} className={"action " + color}>
            {name_action}
          </p>
        </div>
      </div>
    );
  }
}

export default BoxPunto;
