import IconArica from '../../../../assets/images/Parques/Mapa/MapIconArica.png';
import IconIquique from '../../../../assets/images/Parques/Mapa/MapIconIquique.png';
import IconVillaAlemana from '../../../../assets/images/Parques/Mapa/MapIconVillaAlemana.png';
import IconQuilpue from '../../../../assets/images/Parques/Mapa/MapIconQuilpue.png';
import IconValparaiso from '../../../../assets/images/Parques/Mapa/MapIconValparaiso.png';
import IconSanAntonio from '../../../../assets/images/Parques/Mapa/MapIconSanAntonio.png';
import IconMaipu from '../../../../assets/images/Parques/Mapa/MapIconMaipu.png';
import IconSanBernardo from '../../../../assets/images/Parques/Mapa/MapIconSanBernardo.png';
import IconRancagua from '../../../../assets/images/Parques/Mapa/MapIconRancagua.png';
import IconConcepcion from '../../../../assets/images/Parques/Mapa/MapIconConcepcion.png';
import IconTemuco from '../../../../assets/images/Parques/Mapa/MapIconTemuco.png';

import mapa1 from './assets/mapa-a1.png';
import mapa2 from './assets/mapa-a2.png';
import mapa3 from './assets/mapa-a3.png';
import mapa4 from './assets/mapa-a4.png';
import mapa5 from './assets/mapa-a5.png';
import mapa6 from './assets/mapa-a6.png';
import mapa7 from './assets/mapa-a7.png';
import mapa8 from './assets/mapa-a8.png';
import mapa9 from './assets/mapa-a9.png';
import mapa10 from './assets/mapa-a10.png';
import mapa11 from './assets/mapa-a11.png';

export const maps = [
	mapa2, mapa1, mapa11, mapa5, mapa7, mapa10, mapa4, mapa6, mapa8, mapa9, mapa3
];

export const data = [
	{
		name: "Arica",
		link: "/parques/arica",
		icon: IconArica,
		style: "iconArica",
	},
	{
		name: "Villa Alemana",
		link: "/parques/villa-alemana",
		icon: IconVillaAlemana,
		style: "iconVillaAlemana",
	},
	{
		name: "Valparaiso",
		link: "/parques/valparaiso",
		icon: IconValparaiso,
		style: "iconValparaiso",
	},
	{
		name: "Maipu",
		link: "/parques/maipu",
		icon: IconMaipu,
		style: "iconMaipu",
	},
	{
		name: "Rancagua",
		link: "/parques/rancagua",
		icon: IconRancagua,
		style: "iconRancagua",
	},
	{
		name: "Temuco",
		link: "/parques/temuco",
		icon: IconTemuco,
		style: "iconTemuco",
	},
	{
		name: "Iquique",
		link: "/parques/iquique",
		icon: IconIquique,
		style: "iconIquique",
	},
	{
		name: "Quilpue",
		link: "/parques/quilpue",
		icon: IconQuilpue,
		style: "iconQuilpue",
	},
	{
		name: "San Antonio",
		link: "/parques/san-antonio",
		icon: IconSanAntonio,
		style: "iconSanAntonio",
	},
	{
		name: "San Bernardo",
		link: "/parques/san-bernardo",
		icon: IconSanBernardo,
		style: "iconSanBernardo",
	},
	{
		name: "Concepcion",
		link: "/parques/concepcion",
		icon: IconConcepcion,
		style: "iconConcepcion",
	}
];