/* Icono Parque */
import icon from './../../../../assets/images/Parques/IconVillaAlemana.png';
/* Logos Iconos Jardines Temáticos */
import sendero from './../../../../assets/images/Parques/icon/sendero.png'; // Sendero Tradicional
import familiar from './../../../../assets/images/Parques/icon/familiar.png'; //Tradición Familiar
import mausoleo from './../../../../assets/images/Parques/icon/mausoleo.png'; //Jardín Mausoleo
import privado from './../../../../assets/images/Parques/icon/privado.png'; // Jardin Privado
import noble from './../../../../assets/images/Parques/icon/noble.png'; // Jardín Noble
import sanexpedito from './../../../../assets/images/Parques/icon/sanexpedito.png'; // Jardin San Expedito
/* Fotos Jardines Temáticos */
import img_sendero from './../../../../assets/images/Parques/VillaAlemana/img_sendero.jpg'; // Img Sendero Tradicional
// import img_familiar from './../../../../assets/images/Parques/VillaAlemana/img_familiar.jpg'; // Img Tradición Familiar
import img_mausoleo from './../../../../assets/images/Parques/VillaAlemana/img_mausoleo.jpg'; // Img Jardín Mausoleo
//import img_privado from './../../../../assets/images/Parques/VillaAlemana/img_privado.jpg'; // Img Jardin Privado
// import img_noble from './../../../../assets/images/Parques/VillaAlemana/img_noble.jpg'; // Img Jardín Noble
import img_sanexpedito from './../../../../assets/images/Parques/VillaAlemana/img_sanexpedito.jpg'; // Img Jardín San Expedito

import img_familiar_new from './../../../../assets/images/Parques/new/img_jardin_familiar.png';  // Img Tradición Familiar
 // Img Jardin Privado

import img_privado_new from './../../../../assets/images/Parques/new/new_jardin_privado_17182023.png';
import img_noble from './../../../../assets/images/Parques/new/new_jardin_noble_17082023.png';

export default {
    icon: icon,
    carouselItems: [
        {
            image: img_sendero,
            logo: sendero,
            text: 'Es un espacio que cuenta con grandes áreas verdes enmarcadas por hermosas arboleda.',
            nombre: 'Parque del Sendero',
            link: ''
        },
        {
            image: img_familiar_new,
            logo: familiar,
            text: 'Un área diseñado para estar en contacto con la naturaleza. La hermosa fuente de agua que rodea el espacio permite una tranquilidad y conexión con sus seres queridos.',
            nombre: 'Tradición Familiar',
            link: ''
        },

        {
            image: img_mausoleo,
            logo: mausoleo,
            text: 'Lo mejor de lo tradicional, acompañado por la belleza natural que ofrece un parque cementerio.',
            nombre: 'Jardín Mausoleo',
            link: ''
        },
        {
            image: img_privado_new,
            logo: privado,
            text: 'Un espacio privilegiado donde se resguarda la memoria y el descanso de sus seres queridos.',
            nombre: 'Jardín Privado',
            link: ''
        },
        {
            image: img_noble,
            logo: noble,
            text: 'Conmemore con elegancia y distinción el recuerdo de sus seres queridos. El espacio es resguardado por un cierre perimetral de fierro, con puerta y escaño para uso exclusivo de su familia.',
            nombre: 'Jardín Noble',
            link: ''
        },
        {
            image: img_sanexpedito,
            logo: sanexpedito,
            text: 'Ubicada a los pies del altar de San Expedito, con oratorio y un entorno natural. Un espacio diseñado para la devoción y honrar a sus seres queridos al amparo del Santo Patrono de las causas urgentes e imposibles.',
            nombre: 'Jardines San Expedito',
            link: ''
        },
    ],

    jardinesTematicos: [
        sendero,
        familiar,
        mausoleo,
        privado,
        noble,
        sanexpedito,
    ],
}





