import React, { Component } from "react";
import { Col } from "reactstrap";
import ReactHtmlParser from "react-html-parser";

class Tipo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { nombre, descripcion, portada } = this.props;
    return (
      <Col md={4}>
        <div className="content-servicio-mantencion mb-2 mb-xs-0">
          <img src={portada} className="img-servicio" alt="" />
          <p className="text-title">
            <strong>{ReactHtmlParser(nombre)}</strong>
          </p>
          <p className="text-info-mantencion">{ReactHtmlParser(descripcion)}</p>
        </div>
      </Col>
    );
  }
}
export default Tipo;
