import React, { Component } from 'react';

export default class TyC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: false
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
    }
    render() {
        const { isToggleOn } = this.state;
        return (
            <>
                <div className="text-center ver-byc">
                    <a onClick={this.handleClick}>*Ver bases y condiciones</a>
                </div>
                {isToggleOn && (
                    <div className="tyc">
                        <h4>BASES Y CONDICIONES</h4>
                        <p>- Precio normal equivale a 66 veces el valor cuota de mantención anual </p>
                        <p>- Sólo para clientes con su sepultura 100% pagada </p>
                        <p>- Válido hasta: xx | xx | xx </p>
                        <p>- Forma de pago: tarjeta de crédito máximos 12 cuotas </p>
                        <p>- El pago de su mantención perpetua se verá reflejado en nuestro sistema en un máximo de xx horas</p>
                        <span onClick={this.handleClick} className="ver-byc">Volver</span>
                    </div>
                )}
            </>
        );
    }
}