import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Ticket from './../../assets/images/correcto.PNG';
class PageAgradecimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <>
      <Container className="page-gracias">
        <Row>
          <Col className="text-center">
            <div className="box-page">
              <img src={Ticket} alt=""/>
              <h2 className="title">Gracias por contactarse con nosotros.</h2>
              <p className="content">Un ejecutivo se contactará con usted lo antes posible. Si tiene alguna duda recuerde que puede encontrarnos también en el teléfono 600 736 3376 y nuestras redes sociales.</p>
            </div>
          </Col>
        </Row>
      </Container>
      </>
    );
  }
}
export default PageAgradecimiento;