import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from './../../service/Api';
import BannerMain from './Components/BannerMain';
import Precio from './Components/Precio';
import ServiceCremacion from './Components/ServiceCremacion';
import { Button, Spinner } from 'reactstrap';

const CompraLinea2 = () => {
  const [items, setItems] = useState({});
  const [records, setRecords] = useState([]);

  const [loading, setLoading] = useState(true);
  const buscarPlanes = async () => {
    try {
      const response = await Api.getAllProducts({});
      // console.info(response);
      if (response.response === 200) {
        setLoading(false);

        setItems(() => {
          return response.result.filter((c) => {
            if (c.parent_id === 0) {
              c.loading = false;
              c.quantity = 1;
              return c;
            }
          });
        });

        setRecords(response.result);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    buscarPlanes();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log('IMPrimiendo;');
  // console.log(process.env.REACT_APP_IVAS);

  const renderDisclaimerIVA = () => {
    const date = new Date();

    if (date.getFullYear() >= process.env.REACT_APP_IVAS) {
      return (
        <>
          <p className="disclaimer">
            <span
              style={{
                color: '#000',
                fontWeight: 'bold',
                textAlign: 'justify',
                fontSize: '11px',
              }}
            >
              El precio indicado se encuentra afecto a
            </span>{' '}
            <span
              style={{
                color: '#000',
                fontWeight: 'bold',
                textAlign: 'justify',
                fontSize: '11px',
              }}
            >
              IVA A BENEFICIO FISCAL
            </span>{' '}
            <span
              style={{
                color: '#000',
                fontWeight: 'bold',
                textAlign: 'justify',
                fontSize: '11px',
              }}
            >
              que Sendero se encuentra obligado a recaudar y enterar al Fisco.
            </span>
          </p>
        </>
      );
    }

    return <></>;
  };

  // const showHideReload = (item, bvalue) => {
  //   const aux_items = items.map((ii) => {
  //     if (ii.id === item.id) {
  //       ii.loading = bvalue;
  //     }
  //     return ii;
  //   });

  //   setItems(aux_items);
  // };

  const showChangeItem = (item, item_new, quantity) => {
    item_new['quantity'] = quantity;
    item_new['loading'] = false;
    const aux_items = items.map((ii) => {
      if (ii.id === item.id) {
        ii = item_new;
      }
      return ii;
    });

    setItems(aux_items);
  };

  const getNewItem = (item, quantity) => {
    if (parseInt(item.parent_id) !== 0 && parseInt(quantity) !== 1) {
      return records.find(
        (c) =>
          parseInt(c.parent_id) === parseInt(item.parent_id) &&
          parseInt(c.correlativo) === parseInt(quantity)
      );
    } else {
      if (parseInt(item.parent_id) === 0) {
        return records.find(
          (c) =>
            parseInt(c.parent_id) === parseInt(item.id) &&
            parseInt(c.correlativo) === parseInt(quantity)
        );
      } else {
        if (parseInt(quantity) === 1) {
          return records.find(
            (c) =>
              parseInt(c.parent_id) === 0 &&
              parseInt(c.correlativo) === parseInt(item.parent_id)
          );
        } else {
          return records.find(
            (c) =>
              parseInt(c.parent_id) === parseInt(item.parent_id) &&
              parseInt(c.correlativo) === parseInt(quantity)
          );
        }
      }
    }
  };
  const QuantityItem = (item) => {
    const [quantity, setQuantity] = useState(item.quantity);

    const handleQuantity = async (item, quantity) => {
      console.log(quantity);
      setQuantity(quantity);

      //showHideReload(item, true);

      // const response = await await Api.getProductByID({
      //   item,
      //   quantity,
      // });

      // if (Array.isArray(response.result)) {
      //   showChangeItem(item, response.result[0]);
      // }
      const aux = getNewItem(item, quantity);
      // console.log(aux);
      showChangeItem(item, aux, quantity);
    };

    return (
      <div className="d-flex justify-content-around mt-3">
        <Button
          onClick={(e) => {
            if (quantity - 1 < 1) {
              return;
            }
            handleQuantity(item, quantity - 1);
          }}
        >
          -
        </Button>
        <label htmlFor="" className="mt-1">
          {quantity}
        </label>
        <Button
          onClick={(e) => {
            if (quantity + 1 > 3) {
              return;
            }
            handleQuantity(item, quantity + 1);
          }}
        >
          +
        </Button>
      </div>
    );
  };

  return (
    <>
      {/* <Banner /> */}
      <BannerMain />
      <div className="container">
        <div className="row text-bajada-plan mt-5">
          <div className="col-12">
            <div className="seleccione-plan mb-4">
              <h2>Conoce nuestros planes de cremación</h2>
            </div>
          </div>
        </div>
        {loading === true && (
          <>
            <div className="d-flex justify-content-around mt-3">
              <Spinner />
            </div>
          </>
        )}
        <div className="row">
          {loading === false &&
            items &&
            items.length > 0 &&
            items.map((item, index) => (
              <>
                {item.loading === true && (
                  <>
                    <div
                      className="col-12 col-md-4 align-self-stretch"
                      key={index}
                    >
                      <div className="box-product ">
                        <img
                          src={item.portada}
                          alt={item.title}
                          className="item-image"
                        />
                        <div className="info-producto text-center">
                          <Spinner />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {item.loading === false && (
                  <>
                    <div className="col-12 col-md-4" key={index}>
                      <div className="box-product">
                        <img
                          src={item.portada}
                          alt={item.title}
                          className="item-image"
                        />
                        <div className="info-producto">
                          <h2>{item.title}</h2>
                          <div className="box-detalle">
                            {typeof item.detalle !== 'undefined' ? (
                              item.detalle.length > 0 &&
                              item.detalle.map((item2, index2) => <></>)
                            ) : (
                              <div className="box-detalle__icon_detalle">
                                <p>No tiene descripción disponible</p>
                              </div>
                            )}
                          </div>
                          <Precio {...item} />
                          <QuantityItem {...item} />
                          <div className="content-button text-center">
                            <Link
                              to={{
                                pathname: `/compra/product/${item.codigo}/${index}`,
                              }}
                              className="btn-compra"
                            >
                              Comprar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
        </div>
        <div className="row justify-content-center">
          {renderDisclaimerIVA()}
        </div>
      </div>
      <ServiceCremacion />
    </>
  );
};
export default CompraLinea2;
