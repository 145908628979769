import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { HashLink as Link } from 'react-router-hash-link';
import ReactHtmlParser from 'react-html-parser';
import Api from '../../../service/Api';
import PopMasInfo from './MasInfoLanding';
import Slider from './Slider.js';
import ServicioFunerario from './../../Forms/ServicioFunerario.jsx';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class ServicioFunerarioVoyager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planes: {},
            servicios: {},
            photoIndex: 0,
            isOpen: false,
            isModal:false,
            isOpenGaleria: false,
            isCollapse: false
        }
        this.onClickActivaOption = this.onClickActivaOption.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    componentWillMount() {
        this.getPlanes();
        this.getServicios();
    }
    toggle() {
        this.setState({
            isModal: !this.state.isModal
        });
    }

    async getPlanes() {
        let response = await Api.getPlanes();
        this.setState({
            planes: response
        })
    }
    async getServicios() {
        let response = await Api.getServicios();
        let response1 = response[0];
        this.setState({
            servicios: response1,
            serviciosGaleria: JSON.parse(response1[1].galeria),
            serviciosFichas: response[1]
        })
    }
    onClickActivaOption(e, index) {
        if (index === 0) {
            window.scrollTo(0, 0);
        } else if (index === 1) {
            this.setState({
                isOpen: true,
                galeria:this.state.serviciosGaleria
            });
        } else if (index === 2) {
            this.setState({
                isModal: true,
                galeria:this.state.serviciosFichas
            });
        }
    }
    render() {
        const { planes, servicios, isOpen, isModal, galeria, photoIndex } = this.state;

        return (
            <>
                {
                    planes.length > 0 ? (
                        planes.map((item, index) => (
                            <Row key={index} className="row-servicios">
                                <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: index % 2 ? 2 : 1 }}>
                                    <div className="col-servicios">
                                        <div>
                                            <Row className="border-bottom">
                                                <Col xs={12} md={12}>
                                                    <p className="title-incluye"><strong>Plan {item.nombre}</strong><br /> Incluye:</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="list">
                                            {ReactHtmlParser(item.incluye)}
                                        </div>
                                        <div className="bottom-detalle row">
                                            <Col xs={6} md={6}>
                                                <Link smooth="true" to={"/servicios-funerarios/#cotizar"} className="btn button cotizar-landing">COTIZAR</Link>
                                            </Col>
                                            <Col xs={6} md={6} className="box-ver-mas">
                                                <PopMasInfo name={item.nombre} data={item.info} nota={item.nota} />
                                            </Col>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: index % 2 ? 1 : 2 }}>
                                    <Slider items={JSON.parse(item.gallery)} />
                                </Col>
                            </Row>
                        ))
                    ) : null}
                <Row className="row-products sinPadding">
                    {
                        servicios.length > 0 ? (
                            servicios.map((item, index) => (
                                <Col md={4} className="text-left" key={index} onClick={e => this.onClickActivaOption(e, index)}>
                                    <div className="content-servicio-funeraria">
                                        <img src={item.portada} className="img-landing" alt="" />
                                        <h2 className="title-products">{item.nombre}</h2>
                                        {ReactHtmlParser(item.descripcion)}
                                    </div>
                                </Col>
                            ))
                        ) : null
                    }
                </Row>
                <ServicioFunerario />
                {isOpen && (
                    <Lightbox
                        mainSrc={galeria[photoIndex]}
                        nextSrc={galeria[(photoIndex + 1) % galeria.length]}
                        prevSrc={galeria[(photoIndex + galeria.length - 1) % galeria.length]}
                        imageCaption={''}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + galeria.length - 1) % galeria.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % galeria.length,
                            })
                        }
                    />
                )}
                {isModal && (
                    <Modal isOpen={isModal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}></ModalHeader>
                        <ModalBody>
                            <Slider items={galeria} iframe={true}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="btn button" onClick={this.toggle}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>
                )}
            </>
        );
    }
}
export default ServicioFunerarioVoyager;