import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class BoxImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { descripcion, portada, orden, visible } = this.props;
    return (
      visible === orden && (
        <>
          <div className="col-md-6">
            <div className="box-image">{ReactHtmlParser(descripcion)}</div>
          </div>
          <div className={`col-md-6 ${visible === 5 ? 'order-first' : ''}`}>
            <img src={portada} style={{ width: 100 + "%" }} alt="" />
          </div>
        </>
      )
    );
  }
}

export default BoxImage;
