/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import LogoSendero from '../../../assets/images/logo/LogoSendero.png';
import Icon247 from '../../../assets/images/icons/24-7.png';
import iso from '../../../assets/images/SGS_ISO_9001_TBL.jpg';
import funeraria from '../../../assets/images/Enmascarar_grupo_4.jpg';
import Chat from '../../Chat';
//import baseContratoPDF from '../../../assets/pdf/base_sorteo_2022.pdf';
import baseContratoPDF from '../../../assets/pdf/base_sorteo_pdf_octubre_2022.pdf';

class Footer extends Component {
  year() {
    return new Date().getFullYear();
  }
  async handleMenuClick(event) {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className="container">
          <div className="footer-container">
            <div className="footer-container__row">
              <div className="row">
                <Col md={3} className="text-center text-md-left">
                  <img
                    src={LogoSendero}
                    className="img img-logo-footer"
                    alt=""
                  />
                  <div className="footer-container__socials text-center text-md-left">
                    <strong>Síganos</strong>
                    <div className="rrss">
                      <a
                        href="https://www.facebook.com/Senderoparque/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon-bottom-rrss fab fa-facebook-f"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/senderoparque/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon-bottom-rrss fab fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCv8fufJ35tJDP2E92NdHgww"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon-bottom-rrss fab fa-youtube"></i>{' '}
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={12} className="text-center text-md-left">
                  <ul className="list-footer">
                    <li className="item-footer-title">Servicios al cliente</li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/oficinas'}
                        className="hover-link"
                      >
                        <p>Nuestras Oficinas</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/preguntas-frecuentes'}
                        className="hover-link"
                      >
                        <p>Preguntas Frecuentes</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        smooth="true"
                        to={'/donde-pagar/'}
                        className="hover-link"
                      >
                        <p>Paga online</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/ubicacion-de-sepulturas'}
                        className="hover-link"
                      >
                        <p>Ubicación de Sepultura</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/funerales-del-dia'}
                        className="hover-link"
                      >
                        <p>Funerales Del Día</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <a
                        href="https://masapoyo.sendero.cl"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover-link"
                      >
                        <p>Apoyo al duelo</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/canal-de-denuncia'}
                        className="hover-link"
                      >
                        <p>Canal Denuncias Cumplimiento</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/canal-de-denuncia-etico'}
                        className="hover-link"
                      >
                        <p>Canal de Denuncias Éticas</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <a
                        href="https://compliance.controldoc.legal/public/ley_karin/denuncia/mbsJW/Da9SB/new"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover-link"
                      >
                        <p>Canal de Denuncias Ley Karin</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      {/* <Link onClick={this.handleMenuClick} to={"/contratos-y-reglamentos"} className="hover-link">
                        <p>Contratos y Reglamentos</p>
                      </Link> */}
                      <a
                        class="hover-link"
                        target={'_blank'}
                        href={
                          'https://masinformados.sendero.cl/contratos-y-reglamentos/'
                        }
                      >
                        <p>Contratos y Reglamentos</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/acceso-firmante'}
                        className="hover-link"
                      >
                        <p>Acceso Firmante</p>
                      </Link>
                    </li>
                    <li class="item-footer">
                      <a
                        class="hover-link"
                        href={baseContratoPDF}
                        target="_blank"
                      >
                        <p>Base Sorteo + Amigos</p>
                      </a>
                    </li>
                    <li class="item-footer">
                      <a
                        class="hover-link"
                        href={
                          'https://sendero-api.gux.cl//storage/legal/carta_retiro.pdf'
                        }
                        target="_blank"
                      >
                        <p>Bases legales Contratación de mantención perpetua</p>
                      </a>
                    </li>
                    <li class="item-footer">
                      <a
                        class="hover-link"
                        href={
                          'https://sendero-api.gux.cl//storage/pdfs/refiere_vecino.pdf'
                        }
                        target="_blank"
                      >
                        <p>Bases legales “Refiere a un vecino”</p>
                      </a>
                    </li>
                    <li class="item-footer">
                      <a
                        class="hover-link"
                        href={
                          'https://sendero-api.gux.cl//storage/imenus/blegal_actualizacion_datos.pdf'
                        }
                        target="_blank"
                      >
                        <p>Bases legales Actualización de Datos</p>
                      </a>
                    </li>
                    <li class="item-footer">
                      <a
                        class="hover-link"
                        href={
                          'https://sendero-api.gux.cl//storage/imenus/20241028161803PMS.pdf'
                        }
                        target="_blank"
                      >
                        <p>Bases legales Referidos 01 de noviembre 2024</p>
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md={3} sm={12} className="text-center text-md-left">
                  <ul className="list-footer">
                    <li className="item-footer-title">Conócenos</li>
                    <li className="item-footer">
                      <a
                        href="https://sendero.trabajando.cl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover-link"
                      >
                        <p>Trabaje con nosotros</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/transparencia'}
                        className="hover-link"
                      >
                        <p>Transparencia</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/politicas-de-privacidad'}
                        className="hover-link"
                      >
                        <p>Politica de Privacidad</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/politica-de-calidad'}
                        className="hover-link"
                      >
                        <p>Política de calidad</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/sobre-sendero'}
                        className="hover-link"
                      >
                        <p>Sobre Sendero</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/mas-parque-para-todos'}
                        className="hover-link"
                      >
                        <p>Más parque para todos</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      {/* <a href={"https://sendero-api.gux.cl/storage/Precios_Sendero_web.pdf"} className="hover-link" target="_blank">
                        <p>Valores Sendero 2022</p>
                      </a> */}
                      <a
                        href={
                          'https://masinformados.sendero.cl/precios-sendero/'
                        }
                        className="hover-link"
                        target={'_blank'}
                      >
                        <p>Precios Sendero 2024</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      <a
                        href={
                          'https://sendero-api.gux.cl//storage/imenus/codigo_etica_23092024.pdf'
                        }
                        className="hover-link"
                        target={'_blank'}
                      >
                        <p>Código de ética Sendero</p>
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md={3}  sm={12} className="text-center text-md-left">
                  <div className="list-footer">
                    <li className="item-footer-title">Estamos aquí</li>
                  </div>
                  <p className="text-necesite">Cuando nos necesite</p>
                  <p>
                    <strong>CONTÁCTENOS LAS 24 HORAS</strong>
                  </p>
                  <div className="footer-contact">
                    <div>
                      <img src={Icon247} className="img img-247" alt="" />
                    </div>
                    <div className="">
                      <a className="footer-phone" href="tel:6007363376">
                        <strong>600 736 3376</strong>
                      </a>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 5 + 'px' }}>
                    <div className="col-md-6 col-sm-12 text-center text-md-left" style={{ padding: 0 }}>
                      <img
                        src={funeraria}
                        alt="Funeraria Sendero"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-12 text-center text-md-left" style={{ padding: 0, display: 'none' }}>
                      <img src={iso} alt="iso SGS" className="img-fluid" />
                    </div>
                  </div>
                </Col>
              </div>
              <hr />
              <div className="row post-footer">
                <Col
                  md={6}
                  className="post-footer__info post-footer__info-left"
                >
                  © {this.year} Sendero
                </Col>
                <Col
                  md={6}
                  className="post-footer__info post-footer__info-right"
                >
                  Todas las imágenes de este sitio son referenciales
                </Col>
              </div>
            </div>
            <Chat type="desktop" />
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
