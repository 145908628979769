import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Api from '../../service/Api';

class PostsItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoria: props.type,
            ruta: parseInt(props.type) === 1 ? "noticias/" : "sendero-contigo/",
            cant: typeof props.cant === 'undefined' ? '' : props.cant,
            items: []
        }
    }
    async componentWillMount() {
        let response = await Api.getPosts(this.state);
        this.setState({
            items: response.data
        });
    }

    render() {
        return (
            <>
                <div className="grid-posts list">
                    <h3 className="text-recientes">{this.state.categoria === 1 ? ('NOTICIAS') : ('ARTÍCULOS')} RECIENTES</h3>
                    {this.state.items.map((item, index) => (
                        <Row key={index}>
                            <Col md={12}>
                                <a href={"/posts/" + this.state.ruta + item.id + "/" + item.title.toLowerCase().split(" ").join("-")}>
                                    <div className="content-blog list-blog">
                                        <Row>
                                            <Col md={6}>
                                                <img src={item.portada} className="img-posts-list" alt={item.title} />
                                            </Col>
                                            <Col md={6}>
                                                <div>
                                                    <p className="text-left content-blog__excerpt">{item.title}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </a>
                            </Col>
                        </Row>
                    ))}
                </div>
            </>
        );
    }
}
export default PostsItems;