import React, { Component } from 'react';
import { Row, Col, Button, Alert } from 'reactstrap';
import AppSelect from './Select.jsx';
import AppInput from './InputText.jsx';
import Ciudad from './../Data/Ciudad.json';
import CiudadSacramental from './../Data/CiudadSacramental.json';
import logoSendero from '../../assets/images/logo-sendero.svg';
import logoSacramental from '../../assets/images/logosacramental.png';
class FormInputReferido extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            companies: [
                { value: 'Sendero', name: 'Sendero' },
                { value: 'Sacramental', name: 'Sacramental' }
            ],
            data: Ciudad,
            parques: {
                Sendero: Ciudad,
                Sacramental: CiudadSacramental
            }
        }

        this.onChangeCompany = this.onChangeCompany.bind(this);
    
    }
    
    onChangeCompany(e) {
        
        const { value } = e.target;

        if (value === 'Sendero') {
            this.setState({ data: this.state.parques.Sendero });
        }

        if (value === 'Sacramental') {
            this.setState({ data: this.state.parques.Sacramental });
        }

        this.props.onChange(e);

    }
    render() {
        const { onChange, txtMsj, AlertClass, nameButton, disabledButton, setForm, validateSelect, value } = this.props;
        

        return (
            <>
                <Row>
                    <Col xs={12} sm={12} md={3} className='text-center' ><img src={logoSendero} alt="Logo Sendero" style={{maxWidth:'100%', marginTop: '15px'}}  /></Col>
                    <Col xs={12} sm={12} md={6} ><h2 className='text-center' style={{marginTop: '30px'}}><strong>REGISTRO DE REFERIDOS</strong></h2></Col>
                    <Col xs={12} sm={12} md={3} className='text-center'><img src={logoSacramental} alt="Logo Sacramental"  style={{maxHeight: '100px'}} /></Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12} sm={12} md={12} className="text-center" style={{'margin-bottom': '10px'}}>
                        <span style={{'font-weight': 'bold','font-size':'22px'}}>Datos Participante</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} className="text-center">
                        {txtMsj !== "" ? <Alert className={'alert ' + AlertClass}>{txtMsj}</Alert> : ""}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={4} className="text-center">
                        <label ><strong>Rut</strong></label>
                        <AppInput type="text" name="rutParticipante" placeholder="123.456.78-5" classname="form-control text-center" onChange={onChange} value ={value.rutParticipante}/>    
                    </Col>
                    <Col xs={12} sm={12} md={4} className="text-center">
                        <label><strong>Nombre Participante</strong></label>
                        <AppInput type="text" name="nombreParticipante" classname="form-control text-center"  placeholder="Nombre Cliente" onChange={onChange} value={value.nombreParticipante} />
                    </Col>
                    <Col xs={12} sm={12} md={4} className="text-center">
                        <label><strong>Apellidos Participante</strong></label>
                        <AppInput type="text" name="apellidosParticipante" classname="form-control text-center"  placeholder="Apellidos" onChange={onChange} value={value.apellidosParticipante} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12} sm={12} md={6} className="text-center">
                        
                        <label><strong>Correo Participante</strong></label>
                        <AppInput type="email" name="correoParticipante" classname="form-control text-center"  placeholder="hola@gmail.com" onChange={onChange} value ={value.correoParticipante}/>
                    
                    </Col>
                    <Col xs={12} sm={12} md={6} className="text-center">
                        <label><strong>Teléfono Participante</strong></label>
                        <AppInput type="text" classname="form-control text-center" name="telefonoParticipante" placeholder="987654321" maxLength="10" onChange={onChange} value ={value.telefonoParticipante}/>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12} sm={12} md={12} className="text-center">
                        <label><strong>Seleccione Companía</strong></label>
                        <AppSelect name="Compania" data={this.state.companies} onChange={this.onChangeCompany} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className="text-center">
                        <label><strong>Seleccione Parque</strong></label>
                        <AppSelect name="parque" data={this.state.data} onChange={onChange} />
                    </Col>
                </Row>
                <br />
                <br />
                <hr />
                <br />
                <Row>
                    <Col xs={12} sm={12} md={12} className="text-center" style={{'margin-bottom': '10px'}}>
                        <span style={{'font-weight': 'bold','font-size':'22px'}}>Datos Referido</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={4} className="text-center">
                        <label ><strong>Rut Referido (opcional)</strong></label>
                        <AppInput type="text" name="rutReferido" placeholder="123.456.78-5" classname="form-control text-center" onChange={onChange} value ={value.rutReferido}/>    
                    </Col>
                    <Col xs={12} sm={12} md={4} className="text-center">
                        <label><strong>Nombre Referido</strong></label>
                        <AppInput type="text" name="nombreReferido" classname="form-control text-center"  placeholder="Nombre Cliente" onChange={onChange} value={value.nombreReferido} />
                    </Col>
                    <Col xs={12} sm={12} md={4} className="text-center">
                        <label><strong>Apellidos Referido</strong></label>
                        <AppInput type="text" name="apellidosReferidos" classname="form-control text-center"  placeholder="Apellidos" onChange={onChange} value={value.apellidosReferidos} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12} sm={12} md={6} className="text-center">
                        
                        <label><strong>Mail Referido</strong></label>
                        <AppInput type="email" name="correoReferidos" classname="form-control text-center"  placeholder="aaaaa@correo.cl" onChange={onChange} value ={value.correoReferidos}/>
                    
                    </Col>
                    <Col xs={12} sm={12} md={6} className="text-center">
                        <label><strong>Teléfono Referido</strong></label>
                        <AppInput type="text" classname="form-control text-center" name="telefonoReferido" placeholder="987654321" maxLength="9" onChange={onChange}  value ={value.telefonoReferido}/>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <label><strong>Observación</strong></label>
                        <AppInput type="textarea" name="observacion" classname="form-control"  placeholder="Ingrese un comentario" onChange={onChange} value={value.observacion} />
                    </Col>
                </Row>
                <br />
                <Col xs={12} sm={12} className="text-center">
                    <Button className="btn btn-verde button" style={{'margin-bottom': '10px'}} onClick={setForm} disabled={disabledButton}>{nameButton}</Button>
                </Col>
            </>
        );
    }
}
export default FormInputReferido;