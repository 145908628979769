import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

class FeaturedColorItem extends Component {
    render() {
        const { nombre, descripcion, portada, url, posicion } = this.props;
        var style = '';
        if (posicion === 0) {
            style = 'back-green';
        }
        if (posicion === 1) {
            style = 'back-blue';
        }
        if (posicion === 2) {
            style = 'back-gris';
        }
        return (
            <Col md={4} className="sinPadding home-featured3-item">
                <div className={"home-featured3-item-container " + style}>
                    <Link smooth="true" to={url} id={nombre}><img src={portada} className="img-servicio" alt={nombre} /></Link>
                    <p className="text-title"><span className="lato-light">{nombre}</span> <strong></strong></p>
                    <Link className="more-info btn button" to={url} smooth="true" id={nombre}>Más Info</Link>
                </div>
            </Col>
        );
    }
}

export default FeaturedColorItem;