import React, { Component } from 'react';
import youtube from './../../../assets/images/icons/youtube.svg';
import view360 from './../../../assets/images/icons/360-degree.svg';
import loader from './../../../assets/images/logo/logo_sendero.gif';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
class Anexo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            status: true
        };
        this.toggle = this.toggle.bind(this);
        this.TimeOut = this.TimeOut.bind(this); 
    }
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            status: true
        }));
    }
    TimeOut() {
        setTimeout(() => {
            this.setState({ status: !this.state.status });
        }, 4000);

    }
    render() {
        const { url_youtube, url_view360 } = this.props;
        const { url } = this.state;
        var value = this.state.status ? 'block' : 'none';
        return (
            <>
                <div className="seccion content-anexos text-center">
                    <div className="row">
                        <div className="col-12">
                            <span onClick={(e) => { this.setState({ url: url_youtube, modal: true }, () => this.TimeOut()) }} >
                                <div className="circle">
                                    <img src={youtube} />
                                </div>
                                <p>VIDEO</p>
                            </span>
                        </div>
                        {/* <div className="col-12">
                            <span onClick={(e) => { this.setState({ url: url_view360, modal: true }, () => this.TimeOut()) }} >
                                <div className="circle">
                                    <img src={view360} />
                                </div>
                                <p>VISITA 360°</p>
                            </span>
                        </div> */}
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop={'static'} fade={false} size="lg">
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <ModalBody style={{ padding: 0 }}>
                        <div>
                            <div style={{ display: value }} className="spinner-parque">
                                <img src={loader} />
                            </div>
                            <iframe src={url} style={{ width: 100 + '%', height: 500 + 'px' }} />
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default Anexo;