import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Api from '../../../service/Api';
import Information from './Information';
import JardinesSection from './JardinesSection';
import Description from './Description';
import NotFound from '../../404/NotFound';
import Search from './Search';
import Gallery from './Gallery';
import Location from './Location';
import MoreServices from './MoreServices';
import Compartir from './Compartir';
import TypeMantencion from './TypeMantencion';

class ParquePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: {},
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentWillMount() {
    this.getParque();
  }
  async getParque() {
    const data = {};
    data.slug = this.props.slug;
    const response = await Api.getParque(data);
    this.setState({
      items: response[0],
    });
  }
  render() {
    const { info, slug } = this.props;
    const { items } = this.state;
    return (
      <>
        {items !== '' ? (
          <>
            <div className="content-page-parque">
              <Information
                portada={items.portada}
                portada_mobile={items.portada_mobile}
                icon={info.icon}
                name={items.parque}
                url_youtube={items.url_youtube}
                url_view360={items.url_view360}
              />
              <Container>
                <Compartir
                  description={items.descripcion}
                  href={window.location.href}
                />
                <Description info={items} />
                <JardinesSection items={info.carouselItems} slug={slug} />
              </Container>
              {/* <Search slug={slug} /> */}
              <Container>
                <Gallery items={items} />
                <Location {...items} />
                <TypeMantencion
                  calendario={items.calendario}
                  calendar={items.calendar}
                />
                <MoreServices />
              </Container>
            </div>
          </>
        ) : (
          <NotFound />
        )}
      </>
    );
  }
}
export default ParquePage;
