import React from 'react';
import CEREMONIA_DE_DESPEDIDA from './../../../assets/images/Cremacion/CEREMONIA_DE_DESPEDIDA.png';
import ENTREGA_DE_ANFORA from './../../../assets/images/Cremacion/ENTREGA_DE_ANFORA.png';
import SERVICIO_DE_CREMACION from './../../../assets/images/Cremacion/SERVICIO_DE_CREMACION.png';
import TRAMITACION_SEREMI from './../../../assets/images/Cremacion/TRAMITACION_SEREMI.png';
import './cremacion.scss';

const service = [
  {
    image: CEREMONIA_DE_DESPEDIDA,
    title: 'Ceremonia de despedida',
    description:
      'Te entregamos un momento especial y único para que puedas compartir junto a tu familia un cálido homenaje de despedida para tu ser querido.',
    url: '',
  },
  {
    image: SERVICIO_DE_CREMACION,
    title: 'Servicio de cremación',
    description:
      'Queremos entregarte un servicio respetuoso en todo momento, por esto nuestros procesos están completamente regulados y son realizados con altos estándares de calidad.',
    url: '',
  },
  {
    image: TRAMITACION_SEREMI,
    title: 'Tramitación Seremi',
    description:
      'Queremos que tú y tu familia puedan despedir con la tranquilidad necesaria a su ser querido, por esto contamos con personal experto que realizará en tu nombre la respectiva tramitación ante el Seremi de Salud.',
    url: '',
  },
  {
    image: ENTREGA_DE_ANFORA,
    title: 'Entrega de Ánfora',
    description:
      'Te entregamos un momento especial y único para que puedas compartir junto a tu familia un cálido homenaje de despedida para tu ser querido.',
    url: '',
  },
];
const ServiceCremacion = () => {
  const ButtonIso = {
    height: '30px',
    width: '100px',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: '#00A5C8',
    color: '#fff',
    padding: '15px',
    marginTop: '5px',
  };
  return (
    <>
      <section className="bg-green my-5 py-4">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5">
              <h2>¿En qué consiste el servicio de cremación?</h2>
              {/* <div className="text-center container-button-iso">
                <a href="https://masinformados.sendero.cl/certificacion-de-calidad-iso-9001-2015/" className="button-iso" target="_blank" rel="noopener noreferrer">Más información aquí</a>
              </div>   */}
            </div>
            <div className="col-12 col-md-7 text-justify">
              <p>
                La cremación es un proceso que permite la reducción de un cuerpo
                fallecido a cenizas por medio de un horno crematorio que trabaja
                a altas temperaturas. El proceso completo en Sendero, ha sido
                certificado por la norma ISO 9001:2015, la cuál nos entrega un
                reconocimiento de calidad desde la recepción de notificar al
                cliente, hasta la entrega del ánfora. Todo esto, acompañado de
                una atención cercana, sensible y adecuandonos a cada familia de
                la mejor manera posible.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="paso-a-paso">
        <div className="container">
          <div className="row my-4 px-2">
            {service.map((value, key) => (
              <div className="col-12 col-md-3 mb-3" key={key}>
                <div className="box-service">
                  <img src={value.image} alt="" className="img-fluid" />
                  <p className="tx-paso">Paso {key + 1}</p>
                  <p className="my-2 title">
                    <strong>{value.title}</strong>
                  </p>
                  <p className="box-service__description">
                    {value.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
export default ServiceCremacion;
