import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Input, Alert } from 'reactstrap';
import Session from './../../service/Session';
import Layout from './../Layout/'
import Api from '../../service/Api';

class CambiarContasena extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ClaveA: '',
            ClaveN: '',
            ClaveR: '',
            txtMsj: '',
            AlertClass: '',
            rut: Session.getInfoUser().rut,
            disabledButton: false
        }
        this.setForm = this.setForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    async setForm() {
        let params = {};
        const { ClaveA, ClaveN, ClaveR, rut } = this.state;
        if (ClaveA === '') {
            this.setState({ txtMsj: "Debe ingresar clave actual", AlertClass: "alert-danger" });
            return false;
        }
        if (ClaveN === '' || ClaveR === '') {
            this.setState({ txtMsj: "Debe ingresar contraseñas", AlertClass: "alert-danger" });
            return false;
        }
        if (ClaveN !== ClaveR) {
            this.setState({ txtMsj: "Contraseñas no coinciden", AlertClass: "alert-danger" });
            return false;
        }
        this.setState({
            disabledButton: false
        });
        params.rut = rut;
        params.password = ClaveA;
        params.passwordNueva = ClaveN;

        let data = await Api.setContrasena(params);
        if (data.response === 200) {
            this.setState({ txtMsj: "Contraseña cambiada correctamente", AlertClass: "alert-success" });
        } else {
            if (data.response === 404) {
                this.setState({ txtMsj: "Contraseña actual no corresponde", AlertClass: "alert-danger" });
            } else {
                this.setState({ txtMsj: "Ocurrió un problema al cambiar las contraseñas", AlertClass: "alert-danger" });
            }
        }
        this.setState({
            disabledButton: true
        });
    }

    handleInputChange(event) {
        this.setState({
            txtMsj: ""
        });
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if (this.state.ClaveA && this.state.ClaveN && this.state.ClaveR) {
            this.setState({
                disabledButton: true
            });
        } else {
            this.setState({
                disabledButton: false
            });
        }
    }

    render() {
        return (
            <>
                <Layout name="Cambiar Contraseña">
                    <Row>
                        <Col xs={12} md={6}>
                            <Form>
                                <FormGroup row>
                                    <Col>
                                        <p>Actualiza Tu Contraseña</p>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="password" name="ClaveA" id="ClaveA" placeholder="Clave Actual" onChange={this.handleInputChange} value={this.state.ClaveA || ''} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="password" name="ClaveN" id="ClaveN" placeholder="Nueva Clave" onChange={this.handleInputChange} value={this.state.ClaveN || ''} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="password" name="ClaveR" id="ClaveR" placeholder="Repetir Clave" onChange={this.handleInputChange} value={this.state.ClaveR || ''} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12} className="text-center">
                                        {this.state.txtMsj !== "" ? <Alert className={'alert ' + this.state.AlertClass}>{this.state.txtMsj}</Alert> : ""}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12} className="text-left">
                                        <Button className="btn btn-verde button" onClick={this.setForm} disabled={!this.state.disabledButton}>Actualizar Contraseña</Button>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Layout>
            </>
        );
    }
}
export default CambiarContasena;