import React, { Component } from 'react';
import { FacebookShareButton, WhatsappShareButton, FacebookIcon, WhatsappIcon } from "react-share";
import { Tooltip } from 'reactstrap';
import './Compartir.scss';
class Compartir extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false
        };
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.TimeOut = this.TimeOut.bind(this);
    }
    copyToClipboard() {
        var textField = document.createElement('textarea')
        textField.innerText = this.props.href
        console.info(textField);
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        this.setState({
            tooltipOpen: true
        })
        this.TimeOut();
    }
    TimeOut() {
        setTimeout(() => {
            this.setState({ tooltipOpen: false });
        }, 1000);
    }
    render() {
        const { tooltipOpen } = this.state;
        return (
            <>
                <div className="row">
                    <div className="col-md-12 text-center shared">
                        <p>Comparte:</p>
                        <FacebookShareButton quote={this.props.description} hashtag="#sendero" url="https://sendero.cl/parques/arica"><FacebookIcon className="fb" /></FacebookShareButton>
                        <WhatsappShareButton title={this.props.description} hashtag="#sendero" url="https://sendero.cl/parques/arica"><WhatsappIcon className="wsp" /></WhatsappShareButton>
                        <button className="copy" onClick={(e) => { this.copyToClipboard()}} href="#" id="TooltipExample"><i class="fas fa-link"></i></button>
                        <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample"> Copiado! </Tooltip>
                    </div>
                </div>
            </>
        );
    }
}

export default Compartir;