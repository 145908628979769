import React from "react";
import { Button, Row, Col, Modal, ModalHeader } from "reactstrap";
import Session from "../service/Session";
import IconPagoLinea from '../assets/images/icons/ico_pago_linea_top_header.png';
import IconTarjet from '../assets/images/icons/credit-card.png';
import FormAuth from "./Components/form";

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    render() {
        const { type, popup } = this.props;
        Session.close();
        return (
            <>
                {
                    type === 'botton' ?
                        <>
                            <Button onClick={this.toggle} className="btn button">
                                Pago Rápido
                            </Button>
                        </>
                        : ''
                }
                {
                    type === 'top-header' ?
                        <p>
                            <a onClick={this.toggle} className="hover-pago"><img src={IconPagoLinea} className="icon-top" alt="" />
                                &nbsp;Pago Rápido
                            </a>
                        </p>
                        : ''
                }
                {
                    type === 'top-nav' ?
                        <a onClick={this.toggle} className="menu nav-link nav-link hover-pago">
                            <img src={IconTarjet} className="icon-rrss" alt="" /> Pago Rápido
                            </a>
                        : ''
                }
                {
                    type === 'top-footer' ?
                        <a onClick={this.toggle} className="hover-link hover-pago">
                            <p>Pago Rápido</p>
                        </a>
                        : ''
                }
                {
                    type === 'top-home' ?
                        <a onClick={this.toggle} className="feature-item__button hover-pago">
                            Más info
                        </a>
                        : ''
                }
                {
                    type === 'top-movil-home' ?

                        <div className="content-servicio-mobile back-green" onClick={this.toggle}>
                            <Row>
                                <Col xs={2} className="text-center py-1">
                                    <img src={IconPagoLinea} className="img-lateral mt-0" alt="" style={{ filter: 'brightness(0) invert(1)' }} />
                                </Col>
                                <Col xs={10} className="text-left">
                                    <p className="title-mobile"> Pago <strong>Rápido</strong></p>
                                </Col>
                            </Row>
                        </div>
                        : ''
                }
                {
                    type === 'left-menu-movil-home' ?
                        <div className="back-green text-center" onClick={this.toggle}>
                            <p className="title-mobile-menu"> Pago <strong>Rápido</strong></p>
                        </div>
                        : ''
                }
                {
                    typeof popup === 'undefined' ? (
                        <div className="container">
                            <FormAuth col={5} />
                        </div>
                    ) : (
                        <Modal isOpen={this.state.modal}>
                            <ModalHeader toggle={this.toggle}></ModalHeader>
                            <FormAuth col={12} />
                        </Modal>
                    )
                }
            </>
        );
    }
}

export default Auth;