import React, { Component } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';

import logoSendero from './../../assets/images/logo/LogoSendero.png';
import iconCantidad from './../../assets/images/icons/icono-formulario3.png';
import iconProducto from './../../assets/images/icons/icono-formulario1.png';
import iconParque from './../../assets/images/icons/icono-formulario2.png';
import iconPrecio from './../../assets/images/icons/icono-formulario4.png';

class InfoCotizar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { data } = this.props;
        const visible = data.tipo === "Jardines" ? true : false;

        const cantidad = typeof data.cantidad !== "undefined" ?
            <Col xs={12} md={6}>
                <img src={iconCantidad} className="icon-modal" alt='' />
                <p className="text-popup"><strong>Cantidad de Personas:</strong> {data.cantidad}</p>
            </Col>
            : null;
        const producto = data.producto !== "" ?
            <Col xs={12} md={6}><img src={iconProducto} className="icon-modal" alt='' />
                <p className="text-popup"><strong>Producto:</strong> {data.producto}</p>
            </Col>
            : null;
        const precio = typeof data.precio !== "undefined" ?
            <Col xs={6}><img src={iconPrecio} className="icon-modal" alt='' />
                <p className="text-popup"><strong>Precio:</strong> {data.precio}</p>
            </Col>
            : null;
        const parque = typeof data.parque !== "undefined" && data.parque !== "" && !visible ?
            <Col xs={12} md={6}><img src={iconParque} className="icon-modal" alt='' />
                <p className="parque-capitalize text-popup">
                    <strong>Parque:</strong> {data.parque}
                </p>
            </Col>
            : null;
        const text = typeof data.text !== "undefined" ? <p>{data.text}</p> : '';
        const logo = data.logo !== "" ? <div><img src={data.logo} alt="" /><br /></div> : '';
        return (
            <>
                <div className="text-center content-logo-pop-up" hidden={visible}>
                    <img src={logoSendero} className="img img-logo" alt='' />
                </div>
                <FormGroup className="content-cotizar" hidden={!visible}>
                    <Row>
                        <Col md={12} className="text-center">
                            <div className="cotizar-logo">{logo}</div>
                            <div className="cotizar-texto">{text}</div>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    {data.tipo !== "Oficinas" ?
                        <>
                            <Row>
                                <Col md={12} className="text-left">
                                    <p className="bold">Usted está cotizando:</p>
                                </Col>
                            </Row>
                            <Row className="content-detalle-pop-up">
                                {cantidad}
                                {parque}
                                {producto}
                                {precio}
                            </Row>
                        </>
                        : null}
                </FormGroup>
            </>
        );
    }
}
export default InfoCotizar;