import React, { Component } from 'react';
import waze from './../../../assets/images/waze.png';
import googlemaps from './../../../assets/images/googlemaps.png';
import plano from './../../../assets/images/icons/plano.svg';
import pin from './../../../assets/images/icons/pin.svg';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class Location extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
            isOpen: true
        });
    }

    render() {
        const { address, region, lat, lon, iframeMapa, mapa, plane } = this.props;
        const {isOpen} = this.state;
        var ruta = "https://www.waze.com/ul?ll=" + lat + "," + lon + "&navigate=yes&zoom=17"
        return (
            <>
                <div className="seccion text-center">
                    <div className="text-center">
                        <h2>¿CÓMO <strong>LLEGAR?</strong></h2>
                    </div>
                    <div className="row">
                        <div className="col-6 col-md-3">
                            <div className="content-info-parque-contacto location" onClick={this.handleClick}>
                                <div className="row">
                                    <div className="col-2">
                                        <img src={plano} />
                                    </div>
                                    <div className="col-10 text-left">
                                        <div>
                                            <p><b>Plano ubicación</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="content-info-parque-contacto location">
                                <div className="row">
                                    <div className="col-2">
                                        <img src={pin} />
                                    </div>
                                    <div className="col-10 text-left">
                                        <p><b>Dirección</b></p>
                                        <p>{address}</p>
                                        <p>{region}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="content-info-parque-contacto location">
                                <a href={mapa} target="_blank">
                                    <div className="row">
                                        <div className="col-2">
                                            <img src={googlemaps} />
                                        </div>
                                        <div className="col-10 text-left">
                                            <p><b>Ir a Google Maps</b></p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="content-info-parque-contacto location">
                                <a href={ruta} target="_blank">
                                    <div className="row">
                                        <div className="col-2">
                                            <img src={waze} />
                                        </div>
                                        <div className="col-10 text-left">
                                            <p><b>Ir a Waze</b></p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="iframe" dangerouslySetInnerHTML={{ __html: iframeMapa }}></div>
                </div>
                {isOpen && (
                        <Lightbox
                            mainSrc={plane}
                            imageCaption={'PLANO DE UBICACIÓN'}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                        />
                    )}
            </>
        );
    }
}

export default Location;