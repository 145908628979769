import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col } from 'reactstrap';
import Auth from '../../../../pago-rapido/auth';

class FeaturedItem extends Component {
    render() {
        const { nombre, descripcion, portada, url, position } = this.props;
        return (
            <Col xs={12} md={4} className={"feature-item " + (position === 0 && "pink")}>
                <div className="feature-item__wrapper">
                    <Link to={url} id={nombre}>
                        <img src={portada} className="feature-item__icon" alt={nombre} />
                    </Link>
                    <div className="feature-item__title">
                        <span className="lato-light">{nombre}</span>
                    </div>
                    {
                        nombre !== "PAGO EN LÍNEA" ?
                            <Link className="feature-item__button" to={url} id={nombre}>Más info</Link>
                            : <Auth type="top-home" popup={true} />
                    }
                </div>
            </Col>
        );
    }
}

export default FeaturedItem;