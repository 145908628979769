import React, { Component } from "react";
import phone from './../Assets/Images/phone-24.png';

export class Footer extends Component {
    render() {
        return (
            <div className="contact">
                <div className="row">
                    <div className="col-12 offset-md-3 col-md-6 text-center">
                        <div className="content_info">
                            <h5>Estamos aquí</h5>
                            <p className="tl">Cuando nos necesite</p>
                            <h5>CONTÁCTENOS LAS 24 HORAS</h5>
                            <img src={phone} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}