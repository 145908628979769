import React from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

class ModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            disabledButton: true
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    render() {
        const { name, data, nota } = this.props;
        return (
            <>
                <div>
                    {typeof data !== 'undefined' ?
                        (
                            <>
                                <p onClick={this.toggle} className="mas-info">Más info</p>
                                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                                    <ModalHeader toggle={this.toggle}></ModalHeader>
                                    <ModalBody>
                                        <Row className="row-servicios sin-bottom">
                                            <Col>
                                                <div className="col-servicios">
                                                    <div>
                                                        <p className="title-incluye"><strong>Plan {name}</strong> Incluye:</p>
                                                    </div>
                                                    <div className="list">
                                                        {ReactHtmlParser(data)}
                                                        <hr />
                                                        <p className="nota"><strong>{ReactHtmlParser(nota)}</strong></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="btn button" onClick={this.toggle}>Cerrar</Button>
                                    </ModalFooter>
                                </Modal>
                            </>
                        ) : null}
                </div>
            </>
        );
    }
}

export default ModalComponent;