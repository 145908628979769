import React from 'react';
import { Row, Col} from 'reactstrap';

import Volver from './Assets/volver_a_sendero.png';

export default class NavBarTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iscontrato : localStorage.getItem("iscontrato")
    };
  }
  render() {
    return (
      <div className="NavBarTop">
        <Row>
          <Col xs={6} md={6} className="text-left">
            <p className="navBarTextTitle">{this.props.nameNavBarTop}</p>
          </Col>
          {
            this.state.iscontrato == 1 ? (<></>) 
              : (
                  <>
                    <Col xs={6} md={6} className="text-right">
                      <img src={Volver} className="icon-volver" alt=""/>
                      <a href="/" className="volver">Volver a Sendero</a>
                    </Col>
                  </>
                )
          }
        </Row>
      </div>
    );
  }
}