import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class PostsItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { title } = this.props;
        return (
            <>
                {title !== '' && (
                    <Row>
                        <Col className="text-left">
                            <h2 className="products-home__title posts-title">{title}</h2>
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}
export default PostsItems;