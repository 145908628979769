import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import './popUp.scss';

import Formulario from './../Forms/Cotizar.jsx';

class ModalCotizar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      nombrePlan: '',
      ActivarCotizarPopUp: false
    };
    this.toggle = this.toggle.bind(this);
  }
  componentWillReceiveProps(props) {
    this.setState({
      modal: props.ActivaPopUp
    });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={'static'} fade={false}>
        <ModalHeader toggle={this.toggle}></ModalHeader>
        <ModalBody>
          <Formulario arrayElement={this.props} />
        </ModalBody>
      </Modal>
    );
  }
}

export default ModalCotizar;