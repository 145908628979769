import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Cripta from '../../../assets/images/icons/Cripta.svg';
import Niveles from '../../../assets/images/icons/Niveles.svg';
import Capacidades from '../../../assets/images/icons/Capacidades.svg';
import Box from '../../../components/Boxes';

class Servicios extends Component {
    render() {
        return (
            <>
                <br />
                <Row>
                    <Col className="text-center">
                        <h2>Conozca nuestro <strong>Servicio de Sepultura</strong></h2>
                        <p>En Sendero, los planes se estructuran a partir del tipo de sepultura que escoja. Éstos pueden incluir una serie de beneficios como asistencia ante el deceso, servicio funerario y asesoría legal con nuestros profesionales.</p>
                        <p>Además, denominamos niveles y capacidades a la disposición que damos a la sepultura donde se encontrará posteriormente el ser inhumado.</p>
                    </Col>
                </Row>
                <Box typeBox={'ServicioItem'} type={5} />
                <br /><br />
            </>
        );
    }
}
export default Servicios;