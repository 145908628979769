import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class PaginationLaravel extends Component {
	constructor(props) {
		super(props);
		let pages=[];
		for ( var i = 1; i <=props.last_page; i++) {
		  	pages[i]={page:i};
		}
		this.state = {
			last_page:props.last_page,
			current_page:props.current_page,
			per_page:props.per_page,
			pages
		};
		this.getResuls = this.getResuls.bind(this);
	}
	componentWillReceiveProps(props) {
		let pages=[];
	    for ( var i = 1; i <=props.last_page; i++) {
		  	pages[i]={page:i};
		}
		this.setState({
			last_page:props.last_page,
			current_page:props.current_page,
			per_page:props.per_page,
			pages
		});
	}
	getResuls(e,page){
		e.preventDefault();
		var data=this.props.loadList(page)
		var pages = [];
		for ( var i = 1; i <=data.last_page; i++) {
		  	pages[i]={page:i};
		}
	}
	render() {
		var nex={};
	    return (
			<Pagination aria-label="Page navigation example" className={this.props.className}>
				<PaginationItem>
					<PaginationLink previous onClick={ e => this.getResuls(e,
						(this.state.current_page-1<1 ? {page:null} : {page:this.state.current_page-1})
					) } />
				</PaginationItem>
				{ typeof this.state.pages === 'object' ? 
					this.state.pages.map((p, i) => {
						if(i===this.state.current_page){
							nex=(1+i>this.state.last_page ? {page:i} :{page:1+i});
							return(
								<PaginationItem key={i} active>
									<PaginationLink onClick={ e => this.getResuls(e,p) } >
										{i}
									</PaginationLink>
								</PaginationItem>
							)
						}else{
							return(
								<PaginationItem key={i}>
									<PaginationLink onClick={ e => this.getResuls(e,p) } >
										{i}
									</PaginationLink>
								</PaginationItem>
							)
						}
					}):null
				}
				<PaginationItem>
					<PaginationLink next onClick={ e => this.getResuls(e,nex) }/>
				</PaginationItem>
			</Pagination>
		);
	}
}
export default PaginationLaravel;