import React, { Component } from 'react';
import Form from './Form';
import InfoCotizar from './InfoCotizar';

class Cotizar extends Component {
    render() {
        const data = this.props.arrayElement;
        const { destino_email, type_form, producto, parque, cantidad, precio } = data;
        return (
            <>
                <InfoCotizar data={data} />
                <Form
                    nameButton='COTIZAR'
                    destino_email={destino_email}
                    type_form={type_form}
                    producto={producto}
                    parque={parque}
                    cantidad={cantidad}
                    precio={precio}
                />
            </>
        );
    }
}
export default Cotizar;