import React, { Component } from 'react';
import { Link } from "react-router-dom";
// Assets
import mapa from './assets/mapa.png';
import './Mapa.scss';
import { data, maps } from './data';

class Mapa extends Component {

	constructor(props){
		super(props);
		this.state = {
			map: mapa,
		}
		this.change = this.change.bind(this);
		this.changeOut = this.changeOut.bind(this);
	}

	change(event){
		this.setState({
			map: maps[event.target.dataset.id],
		});
	}

	changeOut(){
		this.setState({
			map: mapa,
		});
	}

	render() {
		return (
			<div className="home-map">
			<h2 className="home-map__title">CEMENTERIOS <b>PARQUE Sendero</b></h2>
			<div className="Mapa" style={{backgroundImage:`url(${this.state.map})`}}>
				{data.map((item, index) => (
					<div key={index} onMouseOut={this.changeOut} onMouseMove={this.change}>
						<Link to={item.link}>
							<img src={item.icon} className={"iconMap " + item.style} data-id={index} alt=""/>
						</Link>
					</div>
					))}
				<div className="MapaBg" style={{backgroundImage: `url(${mapa})`}}/>
			</div>
			</div>
		);
	}
}
export default Mapa;