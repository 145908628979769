import  React, { Component } from 'react';
import ReactTable from "react-table";
import Api from './../../../../service/Api';
import Session from './../../../../service/Session'; 

class List extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items:[],
			rut:Session.getInfoUser().rut,
			campana:Session.getInfoUser().campana,
			parque:Session.getInfoUser().parque,
			codigo:Session.getInfoUser().codigo,
			numero:Session.getInfoUser().numero,
			type:'financiamiento'
		}
	}

	async componentDidMount(){
		let response = await Api.getGrid(this.state);
		this.setState({ items: response });
	}

	render() {
		const columns = [
			{
				Header: 'N° Contrato',
				id: 'nContrato',
				filterAll: true,
				filterable: false,
				Cell: row => (row.original.nContrato),		
			}, 
			{
				Header: 'Nombre',
				id: 'nProducto',
				filterAll: true,
				filterable: false,
				Cell: row => (row.original.nProducto)
			}, 
			{
				Header: 'Saldo',
				id: 'saldo',
				filterAll: true,
				filterable: false,
				Cell: row => ('$'+row.original.montoTotal)
			}, 
			{
				Header: 'Cuota',
				id: 'cuotaVigente',
				filterAll: true,
				filterable: false,
				Cell: row => ('$'+row.original.cuotaVigente)
			}, 
			/*{
				Header: 'Fecha Vencimiento',
				id: 'fechaVencimiento',
				filterAll: true,
				filterable: false,
				Cell: row => (row.original.fechaVencimiento)
			},*/ 
			{
				Header: 'Cuotas Morosas',
				id: 'cantidadCuotaMorosa',
				filterAll: true,
				filterable: false,
				Cell: row => (row.original.cantidadCuotaMorosa)
			}, 
			{
				Header: 'N° de Cuotas',
				id: 'nCuotas',
				filterAll: true,
				filterable: false,
				Cell: row => (row.original.nCuotas)
			}, 
			{
				Header: 'Más Info',
				expander: true,
				id: 'expander',
				Expander: ({ isExpanded, ...rest }) =>
					<div>
						<a href={"/mi-sendero/pago-detalle/"+rest.original.position+"/"+rest.original.nContrato}><span>&#x2295;</span></a>
					</div>,
				style: {
					cursor: "pointer",
					fontSize: 25,
					padding: "0",
					textAlign: "center",
					userSelect: "none"
				},
				
			},		
		]
		return (
			<>
				<div className="admin-transactions-list">
					<div className="grid-green">
						<h3 className="title-lists">Resumen financiero sendero</h3>
						<ReactTable
							manual
							data={this.state.items}
							columns={columns}

							filterable
							defaultPageSize={1}

							pages={this.state.pages}
							loading={this.state.loading}
							onFetchData={this.fetchData}
							previousText='Anterior'
							nextText='Siguiente'
							loadingText='Cargando...'
							noDataText='Sin resultados'
							pageText='Página'
							ofText='de'
							rowsText='registros'
							showPagination={false}
						/>
					</div>
				</div>
			</>
		)
	}
}
export default List