import React from 'react';
import './precio.scss';

const Precio = (props) => {
  const renderDisclaimer = () => {
    const date = new Date();

    if (date.getFullYear() >= process.env.REACT_APP_IVAS) {
      return (
        <>
          <p className="disclaimer">
            <span style={{ color: '#627171', fontWeight: 'bold' }}>
              (Monto variable a la uf comercial del mes en transcurso)
            </span>
          </p>
        </>
      );
    }

    return (
      <>
        <p className="disclaimer">
          (*Valor variable, a la UF comercial del mes en transcurso.)
        </p>
      </>
    );
  };

  const renderIncludedIVA = () => {
    const date = new Date();

    if (date.getFullYear() >= process.env.REACT_APP_IVAS) {
      return <></>;
    }

    return <></>;
  };

  return (
    <>
      <div className="box-precios">
        {props.act_descto === 1 ? (
          <>
            {/* <div className="precio-normal">
              <p className="valores">
                $
                <span className="precio">
                  {new Intl.NumberFormat("de-DE").format(
                    props.precio_descuento
                  )}
                </span>
                * ({props.uf_descuento} UF.)
              </p>
            </div>
            <div className="precio-web">
              <p className="valores">
                $
                <span className="precio">
                  {new Intl.NumberFormat("de-DE").format(props.precio)}
                </span>
                * ({props.uf} UF.)
              </p>
            </div> */}
            <div className="precio-normal-sug">
              <label htmlFor="">Precio Normal:</label>
              <p>
                ${' '}
                {new Intl.NumberFormat('de-DE').format(
                  Math.round(props.precio * 0.19 + props.precio)
                )}{' '}
                ({' '}
                {new Intl.NumberFormat('de-DE')
                  .format((props.uf * 1.19).toFixed(2))
                  .replaceAll('.', ',')}{' '}
                UF ) {renderIncludedIVA()}
              </p>
            </div>
            <div className="precio-web-sug" style={{ color: '#627171' }}>
              <label htmlFor="">Precio Web:</label>
              <p>
                ${' '}
                {new Intl.NumberFormat('de-DE').format(
                  Math.round(
                    props.precio_descuento * 0.19 + props.precio_descuento
                  )
                )}{' '}
                ({' '}
                {new Intl.NumberFormat('de-DE')
                  .format((props.uf_descuento * 1.19).toFixed(2))
                  .replaceAll('.', ',')}{' '}
                UF ) {renderIncludedIVA()}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="precio-web">
              <p className="valores">
                $
                <span className="precio">
                  {new Intl.NumberFormat('de-DE').format(props.precio)}
                </span>
                * ({props.uf} UF)
              </p>
            </div>
          </>
        )}
        {
          /* <p className="disclaimer">
          (*Valor variable, a la UF comercial del mes en transcurso.)
        </p> */
          renderDisclaimer()
        }
      </div>
    </>
  );
};
export default Precio;
