import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Anexo from './Anexo';

function replace(name) {
    if (typeof name !== 'undefined') {
        return name.replace('-', ' ');
    }
}
class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estadoModal: false,
        }
    }
    render() {
        const { icon, name, portada, portada_mobile, url_youtube, url_view360 } = this.props;
        return (
            <div className="content-banner-parque">
                <div className="d-block d-md-none">
                    <img src={portada_mobile} className="portada" alt='' />
                </div>
                <div className="d-none d-md-block">
                    <img src={portada} className="portada" alt='' />
                </div>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="content-sendero">
                                <div className="content-icon-interno">
                                    <img src={icon} style={{ width: 120 + "px" }} alt='' />
                                    <h2 className="lato-light">Sendero <strong>{replace(name)}</strong></h2>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Anexo
                    url_youtube={url_youtube}
                    url_view360={url_view360}
                />
            </div>
        );
    }
}

export default Information;