import React, { Component } from 'react';

class Adicional extends Component {
    render() {
        const { pie, parque, producto, contrato } = this.props;
        const { codigo_producto, manzana, sector, fraccion, monto_producto } = pie;
        return (
            <>
                <div>
                    <div className="row">
                        <div className="col-4 text-center text-content">{parque}</div>
                        <div className="col-4 text-center text-content">{producto}</div>
                        <div className="col-4 text-center text-content">Contrato N° : {contrato}</div>
                    </div>
                    {pie !== '' && (
                        <>
                            <div className="row">
                                <div className="col-4 text-center text-content">CODIGO PRODUCTO<p>{codigo_producto}</p></div>
                                <div className="col-4 text-center text-content">MANZANA<p>{manzana}</p></div>
                                <div className="col-4 text-center text-content">SECTOR<p>{sector}</p></div>
                            </div>
                            <div className="row">
                                <div className="col-4 text-center text-content">FRACCIÓN<p>{fraccion}</p></div>
                                <div className="col-4 text-center text-content">MONTO PRODUCTO<p>${new Intl.NumberFormat("de-DE").format(monto_producto)}</p></div>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
}
export default Adicional;