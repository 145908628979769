import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import SliderSlick from "react-slick";
import iconArrow from './../../../assets/images/next.png';
/* Import Components */
import PopUpCotizar from './../../../components/PopUpCotizar/';
import Api from '../../../service/Api';

class CrematoriosVoyager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planes: {},
            stock: {},
            servicios: {},
            NombrePlan: '',
            Precio: 0,
            currentIndex: 0,
            ActivarCotizarPopUp: false
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    onClick(e, NombrePlan, Precio) {
        e.preventDefault();
        this.setState({
            NombrePlan: NombrePlan,
            Precio: Precio,
            ActivarCotizarPopUp: true
        });
    }
    componentWillMount() {
        this.getPlanCrematorio();
        this.getStockPlanes();
    }
    async getPlanCrematorio() {
        let response = await Api.getPlanCrematorio();
        this.setState({
            planes: response
        })
        this.getServiciosCrematorio();
    }
    async getStockPlanes() {
        let response = await Api.getStockPlanes();
        if (response.response === 200) {
            this.setState({
                stock: response.result
            })
        }
    }
    async getServiciosCrematorio() {
        let response = await Api.getServiciosCrematorio();
        this.setState({
            servicios: response
        })
    }
    next() {
        this.crematoriosvoyager.slickNext();
    }
    previous() {
        this.crematoriosvoyager.slickPrev();
    }

    render() {
        const { planes, servicios, stock } = this.state;
        const cantidad = planes.length;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <>
                <div className="parque-crematorio">
                    <br /><br />
                    <Row>
                        <Col className="text-center">
                            <h2>Seleccione su <strong>Plan</strong></h2>
                            <p>En Sendero, los planes están pensados para cada tipo de familia sin importar su creencia religiosa.</p>
                        </Col>
                    </Row>
                    <br /><br />
                    <div className="px-3">
                        <Row className="paque-crematorio-planes"></Row>
                    </div>
                    <div className="jardines-container jardines-bt">
                        {cantidad >= 4 ? (
                            <img
                                src={iconArrow}
                                onClick={this.previous}
                                className="arrow-left arrow"
                                alt=""
                            />
                        ) : null}
                        <div>
                            {planes.length > 0 ? (
                                <SliderSlick ref={c => (this.crematoriosvoyager = c)} {...settings}>
                                    {
                                        planes.map((item, index) => (
                                            <div key={index}>
                                                <Col md="12" className={"px-1 h-100 --ss" + index}>
                                                    <div className="content-planes crematorio">
                                                        <h4>{item.nombre}</h4>
                                                        <hr></hr>
                                                        <div className="planes-detalle planes-detalle-crematorio">
                                                            {ReactHtmlParser(item.detalle)}
                                                        </div>
                                                        <hr></hr>
                                                        <p className="content-title-plan">{item.precio}</p>
                                                        <hr></hr>
                                                        <span className="btn button" onClick={(e) => { this.onClick(e, item.nombre, item.precio) }}>Cotizar</span>
                                                        <span className="cotizar-extra">
                                                            Todos los precios están sujeto a modificaciones
                                                        </span>
                                                    </div>
                                                </Col>
                                            </div>
                                        ))
                                    }
                                </SliderSlick>
                            ) : null}
                        </div>
                        {cantidad >= 4 ? (
                            <img
                                src={iconArrow}
                                onClick={this.next}
                                className="arrow-right arrow"
                                alt=""
                            />
                        ) : null}
                    </div>
                    {stock.length > 0 && (
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Stock de <strong>Ánforas</strong></h2>
                                <br />
                            </div>
                            <div className="col-12">
                                <div className="d-none d-md-block">
                                    <div className="row stock_anfora">
                                        {stock.map((item, index) => (
                                            <>
                                                {item.visible && (
                                                    <div className="col-md-6 bt">
                                                        <p className="title">{item.SBPR_DSC}</p>
                                                        <div className="row">
                                                            {typeof stock[index] !== 'undefined' && stock[index].PRODUCTOS.map((item_1, index_1) => (
                                                                <>
                                                                    <div className="col-md-6 text-center">
                                                                        <img src={item_1.IMAGE} />
                                                                    </div>
                                                                    <div className="col-md-6 name_product">
                                                                        <p>{item_1.PCMP_DSC}</p>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </div>
                                <div className="d-block d-md-none">
                                    <table className="table table-anfora-mb" id="table-blue">
                                        <thead>
                                            {stock.map((item, index) => (
                                                <div key={index}>
                                                    {item.visible && (
                                                        <>
                                                            <tr className="head">
                                                                <th align="center">{item.SBPR_DSC}</th>
                                                            </tr>
                                                            {typeof stock[index] !== 'undefined' && stock[index].PRODUCTOS.map((item_1, index) => (
                                                                <tr className="body">
                                                                    <td align="center"><img src={item_1.IMAGE} style={{ width: 120 + 'px' }} /></td>
                                                                    <td align="center">{item_1.PCMP_DSC}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    {
                        servicios.length > 0 ? (
                            window.innerWidth <= 760 ?
                                (
                                    <>
                                        {

                                            servicios.map((item, index) => (
                                                <Row key={index} className="row-servicios text-center">
                                                    <Col xs={12}>
                                                        <div className="content-data">
                                                            <h2>{item.nombre}</h2>
                                                            <img src={item.portada} alt="" style={{ width: 100 + '%' }} />
                                                        </div>
                                                    </Col>
                                                    <Col xs={12}>
                                                        {ReactHtmlParser(item.descripcion)}
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                    </>
                                ) : (
                                    servicios.map((item, index) => (
                                        <Row key={index}>
                                            <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: index % 2 ? 2 : 1 }}>
                                                <div className="content-data">
                                                    <h2>{item.nombre}</h2>
                                                    {ReactHtmlParser(item.descripcion)}
                                                </div>
                                            </Col>
                                            <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: index % 2 ? 1 : 2 }}>
                                                <img src={item.portada} alt="" />
                                            </Col>
                                        </Row>
                                    ))

                                )
                        ) : null}
                </div>
                <PopUpCotizar
                    ActivaPopUp={this.state.ActivarCotizarPopUp}
                    producto={this.state.NombrePlan}
                    precio={this.state.Precio}
                    tipo={'Crematorio'}
                    destino_email={'k968wsyl@robot.zapier.com'}
                    type_form='Formulario Planes Parque crematorio'
                />
            </>
        );
    }
}
export default CrematoriosVoyager;