import React, { Component } from 'react';
import { Container } from 'reactstrap';
import BannerTop from '../../components/BannerTop';

import Banner from './../../assets/images/banner-parque-para-todos.jpg';
import BoxMapa from './BoxMapa';
import Box from '../../components/Boxes';

class MantencionOfrenda extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        document.title = 'Sendero | Parque para todos'
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div>
                <Container fluid>
                    <BannerTop title1={''} image={Banner} />
                    <Container className="content-parque-para-todos">
                        <p className="parrafo text-center">En Sendero nos preocupamos de cuidar y mantener la belleza natural del Parque. Para ello, y queriendo siempre que tu visita sea un momento especial es que te explicamos cómo cuidamos nuestros espacios</p>
                        <Box typeBox={'ParqueTodosItem'} type={6} />
                        <div id="comunidad-y-parque">
                            <Box typeBox={'MO Text Image'} type={10} visible={1} />
                            <Box typeBox={'MO Item Comunidad Parque'} type={7} children={<Box typeBox={'MO Text Image'} type={10} visible={4} />} />
                        </div>
                        <div id="retiro-de-ofrendas">
                            <Box typeBox={'MO Text Image'} type={10} visible={2} />
                            <Box typeBox={'MO Item Retiro de Ofrendas'} type={8} />
                        </div>
                        <div id="cuidado-del-parque">
                            <Box typeBox={'MO Text Image'} type={10} visible={3} />
                            <Box typeBox={'MO Item Cuidado del Parque'} type={9} />
                        </div>
                        <BoxMapa />
                        <div className="mt-5 text-center cuidado-agua">
                            <h2 className="title-mantencion">EL AGUA LA <strong>CUIDAMOS JUNTOS</strong></h2>
                            <p>En Sendero somos conscientes de la escasez hídrica que afecta al país y al mundo, por lo que actuamos con responsabilidad protegiendo este recurso natural que es fundamental para la vida de todos nuestros Cementerios Parques.</p>
                            <p>Hace 2 años iniciamos un plan para OPTIMIZAR EL AGUA y aportar al cuidado de ésta con las siguientes acciones:</p>
                            <div id="cuidado-del-parque" className="mt-4">
                                <Box typeBox={'MO Item Cuidamos Juntos'} type={11} />
                                <Box typeBox={'MO Text Image'} type={10} visible={5} />
                                <Box typeBox={'MO Lists'} type={12} />
                            </div>
                        </div>
                    </Container>
                </Container>
            </div>
        );
    }
}

export default MantencionOfrenda;
