import React, { Component } from 'react';
import { Row } from 'reactstrap';
import FeaturedItem from './FeaturedItem';

import IconFunerales from './../../../../assets/images/icons/Funerales.png';
import IconMantencion from './../../../../assets/images/icons/mantencionhome.png';
import IconCieloVirtual from './../../../../assets/images/icons/CieloVirtual.png';

import './Featured.scss';
import Auth from '../../../../pago-rapido/auth';

class Featured extends Component {
    render() {
        const content= [
            {
                text1: "Funerales",
                text2: "del día",
                icon: IconFunerales,
                link:"/funerales-del-dia",
                page:""
            },
            {
                text1: "Mantención",
                text2: "y Ofrenda",
                icon: IconMantencion,
                link: "/mantencion-y-ofrenda",
                page:""
            },
            {
                text1: "Pago en",
                text2: "Línea",
                icon: IconCieloVirtual,
                link: "",
                page:"https://sendero-pago-publico.gux.cl/",
                component:<Auth type="top-home" popup={true}/>
            }
        ]
        return (
            <Row className="home-featured">
                {content.map((item, index) => (
                    <FeaturedItem {...item} key={index}/>
                ))}
            </Row>
        );
    }
}

export default Featured;