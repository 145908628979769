import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import ModalImage from 'react-modal-image';
import SliderSlick from 'react-slick';
import Api from '../../service/Api';
import Banner from './../../assets/images/bannernoticias.jpg';
import List from './list';
import TitleSeccion from './titleSeccion';
import BannerTop from './../BannerTop';
import AgenteLibres from '../Forms/AgenteLibres';
import ReproductorSpotify from '../Forms/ReproductorSpotify';
class PostsItems extends Component {
  constructor(props) {
    super(props);
    const path = this.props.match.path.split('/');
    this.state = {
      id: this.props.match.params.id,
      type: path[2] === 'sendero-contigo' ? 2 : 1,
      categoria: path[2] === 'sendero-contigo' ? 2 : 1,
      ruta: parseInt(props.type) === 1 ? 'noticias/' : 'sendero-contigo/',
      title: path[2] === 'sendero-contigo' ? 'Sendero Contigo' : 'Noticias',
      item: [],
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  async componentWillMount() {
    let response = await Api.getPosts(this.state);
    this.setState({
      item: response[0],
    });
  }
  next() {
    this.PostsItems.slickNext();
  }
  previous() {
    this.PostsItems.slickPrev();
  }
  render() {
    let { title, item } = this.state;
    let Titulo = title.split(' ');
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let dataGallery = '';
    if (
      typeof item?.galeria !== 'undefined' &&
      typeof item?.galeria !== 'string'
    ) {
      dataGallery = (
        <SliderSlick ref={(c) => (this.PostsItems = c)} {...settings}>
          {item?.galeria.map((val) => (
            <div className="content-gallery" key={val}>
              <ModalImage small={val} large={val} alt="" />
            </div>
          ))}
        </SliderSlick>
      );
    }
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            name="og:url"
            property="og:url"
            content={window.location.href}
          />
          <meta name="og:type" property="og:type" content="website" />
          <meta name="og:title" property="og:title" content={title} />
          <meta
            name="og:description"
            property="og:description"
            content={item?.excerpt}
          />
          <meta name="og:image" property="og:image" content={item?.portada} />
        </Helmet>
        <Container fluid>
          <BannerTop title1={Titulo[0]} title2={Titulo[1]} image={Banner} />
        </Container>
        <Container>
          <div className="grid-posts single">
            <Row>
              <Col md={7} key={item?.index}>
                <div className="content-blog">
                  <TitleSeccion title={item?.title} />
                  <div>
                    <p className="text-left">{item?.excerpt}</p>
                    <p className="text-left">{ReactHtmlParser(item?.body)}</p>
                  </div>
                  <div>{dataGallery}</div>
                  <AgenteLibres props={this.props} />
                  <ReproductorSpotify props={this.props} />
                </div>
              </Col>
              <Col md={5}>
                <List type={this.state.type} />
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}
export default PostsItems;
