import React, { Component } from 'react';
import JardinItem from './JardinItem';
import SliderSlick from "react-slick";
import iconArrow from './../../assets/images/next.png';

class Jardines extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.jardines.slickNext();
    }
    previous() {
        this.jardines.slickPrev();
    }
    render() {
        const items = this.props.items.filter((item) => {
            if (this.props.slug != 'san-antonio') {
                return item;
            }
            if (item.nombre != 'Jardín Mausoleo') {

                return item;
            }
        });

        const cantidad = items.length;
        var cant = cantidad < 4 ? cantidad : 4;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: cant,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="jardines-container jardines-bt">
                {cantidad > 4 ? (
                    <img
                        src={iconArrow}
                        onClick={this.previous}
                        className="arrow-left arrow"
                        alt=""
                    />
                ) : null}
                <div>
                    <SliderSlick ref={c => (this.jardines = c)} {...settings}>
                        {items.map((item, index) => (
                            <div key={index}>
                                {
                                    <><JardinItem key={index} image={item.image} logo={item.logo} text={item.text} link={item.link} nombre={item.nombre} /></>
                                }
                            </div>
                        ))}
                    </SliderSlick>
                </div>
                {cantidad > 4 ? (
                    <img
                        src={iconArrow}
                        onClick={this.next}
                        className="arrow-right arrow"
                        alt=""
                    />
                ) : null}
            </div>
        );
    }
}
export default Jardines;