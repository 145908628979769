import React, { Component } from 'react';
import { Col } from 'reactstrap';
import edificio from './../../../assets/images/icons/edificio.svg';
import pin_map from './../../../assets/images/icons/pin_map.svg';
import hour from './../../../assets/images/icons/hour.svg';
import pin from './../../../assets/images/icons/pin.svg';
import telefono_parques from './../../../assets/images/icons/telefono_parques.svg';
import PopUp from '../../../components/ModelPage/Oficina/PopUp';


class Description extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estadoModal: false,
        }
    }
    onClick(e) {
        e.preventDefault();
        this.setState({
            estadoModal: true
        });
    }
    render() {
        const { info } = this.props;
        return (
            <>
                <div className="row">
                    <div className="col-md-4">
                        <div className="content-info-parque-contacto">
                            <div className="row">
                                <div className="col-2">
                                    <img src={pin} />
                                </div>
                                <div className="col-10">
                                    <p><b>Dirección</b></p>
                                    <p>{info.address}</p>
                                    <p>{info.region}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="content-info-parque-contacto">
                            <div className="row">
                                <div className="col-2">
                                    <img src={telefono_parques} />
                                </div>
                                <div className="col-10">
                                    <p><strong>Teléfonos</strong></p>
                                    <p>
                                        {
                                            typeof info.telefono !== 'undefined' ? (
                                                JSON.parse(info.telefono).map(function (tel, i) {
                                                    return (<span key={i}><a href={"tel:" + tel} className="tel-link-color">{tel}</a><br /></span>)
                                                })
                                            ) : null
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="content-info-parque-contacto">
                            <div className="row">
                                <div className="col-2">
                                    <img src={hour} />
                                </div>
                                <div className="col-10">
                                    <p><strong>Horario de atención</strong></p>
                                    <p>{info.horario}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-title">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>CONOZCA PARQUE <strong>{info.parque}</strong></h2>
                            <p>{info.descripcion}</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Description;