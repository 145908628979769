import React, { useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import Api from "../../../service/Api";

const Step2 = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [sendForm, setSendForm] = useState(false);
  const [viewErrorEmail, setViewErrorEmail] = useState(true);
  const [dstep2, setDStep2] = useState({
    Nombre: "",
    ApellidoP: "",
    // ApellidoM:  '',
    Ext: "+56",
    Telefono: "",
    numeroLead: "",
    type: props?.stepProps?.type,
    completed: false,
    Rut: "",
    Formulario: props?.stepProps?.Formulario,
  });

  const numbers = [
    { Ext: "+56", name: "+56" },
    { Ext: "+54", name: "+54" },
    { Ext: "+591", name: "+591" },
    { Ext: "+55", name: "+55" },
    { Ext: "+57", name: "+57" },
    { Ext: "+593", name: "+593" },
    { Ext: "+595", name: "+595" },
    { Ext: "+51", name: "+51" },
    { Ext: "+598", name: "+598" },
  ];

  const isValidated = async () => {
    setSendForm(true);
    
    if (viewErrorEmail === true) {
      return false;
    }

    if (dstep2.ApellidoP === "" || dstep2.Nombre === "") {
      return false;
    }

    let bretorno = false;
    setLoading(true);

    window.scrollTo({
      top: 20,
      behavior: "smooth",
    });

    setDStep2({ ...dstep2, completed: true });

    try {
      let send = {};
      if (props.type === "cremacion") {
        send = {
          Nombre: dstep2.Nombre,
          ApellidoP: dstep2.ApellidoP,
          // ApellidoM:  dstep2.ApellidoM,
          Ext: localStorage.getItem("Ext"),
          Telefono: localStorage.getItem("Telefono"),
          numeroLead: localStorage.getItem("numeroLead"),
          Email: dstep2.Email,
          Terms: true,
          type: localStorage.getItem("type"),
          Rut: dstep2.Rut,
          Formulario: localStorage.getItem("Formulario"),
        };
      } else {
        send = {
          Nombre: dstep2.Nombre,
          ApellidoP: dstep2.ApellidoP,
          // ApellidoM:  dstep2.ApellidoM,
          Ext: localStorage.getItem("Ext"),
          Telefono: localStorage.getItem("Telefono"),
          numeroLead: localStorage.getItem("numeroLead"),
          Email: dstep2.Email,
          Parque: localStorage.getItem("Parque"),
          Terms: true,
          type: localStorage.getItem("type"),
          Rut: dstep2.Rut,
          Formulario: localStorage.getItem("Formulario"),
        };
      }
      const response = await Api.CotizarLeads(send);
      // console.log(response);
      bretorno = true;
    } catch (error) {
      // console.log(error);
      console.log(dstep2);
      bretorno = false;
    } finally {
      setLoading(false);
    }

    bretorno = true;
    return bretorno;
  };

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));

  return (
    <>
      {loading === true && (
        <div className="row">
          <div className="col-md-12 mb-3 text-center">
            <Spinner />
          </div>
        </div>
      )}
      {loading === false && (
        <>
          <Row className="row-form">
            <Col xs={12} sm={12}>
              <label>Nombre</label>
              <input
                className="form-control"
                type={"text"}
                name={"Nombre"}
                id={"Nombre"}
                placeholder={"Ej: Juan Andres"}
                onChange={(e) => {
                  setDStep2({ ...dstep2, Nombre: e.currentTarget.value });
                  //setDStep2({ ...dstep2, numeroLead: localStorage.getItem('numerolead') });
                  console.log(localStorage.getItem("numerolead"));
                }}
                value={dstep2.Nombre}
              />
              {dstep2.Nombre === "" && sendForm && (
                <>
                  <p class="text-danger">Debe Ingresar un Nombre.</p>
                </>
              )}
              <label>Apellidos</label>
              <input
                className="form-control"
                type={"text"}
                name={"ApellidoP"}
                id={"ApellidoP"}
                placeholder={"Ej: Lopez Perez"}
                onChange={(e) => {
                  setDStep2({ ...dstep2, ApellidoP: e.currentTarget.value });
                  //setDStep2({ ...dstep2, numeroLead: localStorage.getItem('numerolead') });
                }}
                value={dstep2.ApellidoP}
              />
              {dstep2.ApellidoP === "" && sendForm && (
                <>
                  <p class="text-danger">Debe Ingresar Apellidos.</p>
                </>
              )}
              {/* <input className="form-control" type={"text"} name={"ApellidoM"} id={"ApellidoM"} placeholder={"Apellido Materno *"} 
            onChange={ (e) =>  { 
              setDStep2({ ...dstep2, ApellidoM: e.currentTarget.value })
              //setDStep2({ ...dstep2, numeroLead: localStorage.getItem('numerolead') });
            }
            
            } value={dstep2.ApellidoM}/>
            {dstep2.ApellidoM === '' && (<>
              <p class="text-danger">Debe Ingresar un Apellido Materno.</p>
            </>)} */}
            </Col>
          </Row>
          <Row className="row-form">
            <Col xs={12} sm={12} md={9}>
            <label htmlFor="">Email</label>
            <input
              className="form-control"
              type="email"
              name="Email"
              id="Email"
              placeholder="info@sendero.cl"
              style={{ borderRadius: "0.25rem" }}
              onChange={(e) => {
                const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (regexPattern.test(e.currentTarget.value)) {
                  setViewErrorEmail(false);
                } else {
                  setViewErrorEmail(true);
                }

                setDStep2({ ...dstep2, Email: e.currentTarget.value });
              }}
              value={dstep2.Email}
            />
            {viewErrorEmail === true && sendForm && (
              <>
                <p class="text-danger">Debe Ingresar un Email válido.</p>
              </>
            )}
            </Col>
          </Row>
          <Row className="row-form">
            <Col xs={12} sm={12} md={6}>
              <label>Rut</label>
              <input
                className="form-control"
                type={"text"}
                name={""}
                id={"Rut"}
                placeholder={"Ej: 12.345.678-9"}
                onChange={(e) => {
                  let valor = e.target.value;
                  valor = valor
                    .replace(/[.-]/g, "")
                    .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");

                  setDStep2({ ...dstep2, Rut: valor });
                }}
                value={dstep2.Rut}
              />
              <p class="text-info">Dato no obligatorio.</p>
            </Col>
          </Row>
        </>
      )}
    </>
  );
});

export default Step2;
