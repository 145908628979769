import React, { Component } from 'react';
import BannerTop from '../components/BannerTop';
import FormularioContratar from '../components/Forms/FormularioContratar';
import Api from './../service/Api';
import Banner from './../assets/images/banner_ecommerce_Mesa_de_trabajo_1.jpg';
import fondo from './../assets/images/fondo_cotizador.png';
import fondocoti from './../assets/images/fondo_coti.png';
import fondocontactanos from './../assets/images/banner_sendero.png';

var sectionStyle = {
  backgroundImage: `url(${fondo})`,
  backgroundSize: 'cover',
  backgroundPosition: 'bottom center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#fff',
};
var sectionStyleCoti = {
  backgroundImage: `url(${fondocoti})`,
  backgroundSize: 'cover',
  backgroundPosition: 'bottom center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#fff',
};
var sectionStyleContactanos = {
  backgroundImage: `url(${fondocontactanos})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#fff',
};
const data_options = [
  {
    title:
      '<strong>Elige una opción</strong><br/>Estás pensando esta compra para',
    option: [
      { id: 0, name: 'Ahora, por una necesidad inmediata.' },
      { id: 1, name: 'Después, estoy comprando para el futuro.' },
    ],
  },
];
var object = {};
class CompraLinea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option_active: 0,
      items: {},
      plan_selected: {},
      show: false,
    };
    this.onClick = this.onClick.bind(this);
    this.clickVolver = this.clickVolver.bind(this);
    this.clickVerPlan = this.clickVerPlan.bind(this);
    this.buscarPlanes = this.buscarPlanes.bind(this);
    this.cerrarPlanSelected = this.cerrarPlanSelected.bind(this);
  }
  componentDidMount() {
    document.title = 'Sendero | Ecommerce';
    window.scrollTo(0, 0);
  }

  onClick(e, father, children, name, id) {
    object[father] = { id: id, name: name, position: children };
    this.setState({
      option_active: this.state.option_active + 1,
    });
    var necesidad = parseInt(object[0].id) === 0 ? 'NI' : 'NF';
    const { plan_selected } = this.state;
    plan_selected.necesidad = necesidad;
    this.buscarPlanes(object);
  }
  async buscarPlanes(data) {
    const params = {};
    params.data = JSON.stringify(data);
    const response = await Api.getAllProducts(params);
    if (response.response === 200) {
      this.setState({
        items: response.result,
      });
    }
  }

  clickVolver() {
    this.setState({
      option_active: this.state.option_active - 1,
    });
  }
  cerrarPlanSelected() {
    this.setState({
      show: false,
    });
  }
  clickVerPlan(e, item) {
    window.scrollTo(0, 0);
    const { plan_selected } = this.state;
    plan_selected.data = item;
    this.setState({
      plan_selected,
      show: true,
    });
  }

  render() {
    const { option_active, items, show, plan_selected } = this.state;
    function BtnWhatsapp(props) {
      return (
        <>
          <div className="btn_whatsapp">
            <a
              href="https://wa.me/56939435637?text=Hola"
              target="_blank"
              without
              rel="noopener noreferrer"
            >
              <i className="fas fa-phone"></i>
              {props.text}
            </a>
          </div>
        </>
      );
    }
    function PrecioNormal(props) {
      return (
        <>
          <div className="box-precios">
            {props.act_descto === 1 ? (
              <>
                <div className="precio-normal">
                  <p className="total">Precio Normal:</p>
                  <p className="valores">
                    $
                    <span className="precio">
                      {new Intl.NumberFormat('de-DE').format(
                        Math.round(
                          props.precio_descuento * 0.19 + props.precio_descuento
                        )
                      )}
                    </span>
                    * ({props.uf_descuento} UF IVA INCLUIDO)
                  </p>
                </div>
                <div className="precio-web">
                  <p className="total">Precio Web:</p>
                  <p className="valores">
                    $
                    <span className="precio">
                      {new Intl.NumberFormat('de-DE').format(
                        Math.round(props.precio * 0.19 + props.precio)
                      )}
                    </span>
                    * ({props.uf} UF IVA INCLUIDO)
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="precio-normal">
                  <p className="total">Precio Normal:</p>
                  <p className="valores">
                    $
                    <span className="precio">
                      {new Intl.NumberFormat('de-DE').format(
                        Math.round(
                          props.precio_descuento * 0.19 + props.precio_descuento
                        )
                      )}
                    </span>
                    * ({props.uf_descuento} UF IVA INCLUIDO)
                  </p>
                </div>
                <div className="precio-web">
                  <p className="total">Precio Web:</p>
                  <p className="valores">
                    $
                    <span className="precio">
                      {new Intl.NumberFormat('de-DE').format(
                        Math.round(props.precio * 0.19 + props.precio)
                      )}
                    </span>
                    * ({props.uf} UF IVA INCLUIDO)
                  </p>
                </div>
              </>
            )}
            <p className="disclaimer">
              (*Valor variable, a la UF comercial del mes en transcurso.)
            </p>
          </div>
        </>
      );
    }
    return (
      <>
        {option_active >= 1 ? (
          <>
            {/**BANNER**/}
            <BannerTop title1={''} title2={''} image={Banner} />
            <div className="container">
              {show ? (
                <>
                  <div className="row text-bajada-plan">
                    <div className="col-12">
                      <div className="seleccione-plan">
                        <h2>Este es el Plan que has seleccionado.</h2>
                        <p>Revisa en detalle todo lo que trae este servicio.</p>
                      </div>
                    </div>
                  </div>
                  <br />
                  {[plan_selected.data].map((item, index) => (
                    <>
                      <div className="d-block d-md-none">
                        <div key={index}>
                          <div className="row box-product-contratar">
                            <span
                              className="cerrar-plan"
                              onClick={this.cerrarPlanSelected}
                            >
                              {'x'}
                            </span>
                            <div className="col-3 text-center">
                              <img
                                src={item.portada}
                                alt={item.title}
                                className="item-image"
                              />
                            </div>
                            <div className="col-9">
                              <div className="info-producto">
                                <h2>{item.title}</h2>
                                <PrecioNormal {...item} />
                              </div>
                            </div>
                          </div>
                          <div className="box-product-contratar">
                            <div className="servicio">
                              <p className="title-servicio">
                                Servicio incluye:
                              </p>
                              {typeof item.servicio !== 'undefined' ? (
                                item.servicio.length > 0 &&
                                item.servicio.map((item, index) => (
                                  <div
                                    key={index}
                                    className="box-detalle__icon_detalle"
                                    dangerouslySetInnerHTML={{
                                      __html: `${item.description}`,
                                    }}
                                  ></div>
                                ))
                              ) : (
                                <div className="box-detalle__icon_detalle">
                                  <p>No tiene servicio disponible</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-none d-md-block">
                        <div key={index}>
                          <div className="row box-product-contratar">
                            <span
                              className="cerrar-plan"
                              onClick={this.cerrarPlanSelected}
                            >
                              {'x'}
                            </span>
                            <div className="col-6 text-center">
                              <img
                                src={item.portada}
                                alt={item.title}
                                className="item-image"
                              />
                            </div>
                            <div className="col-6 product-contratar-desktop">
                              <div className="info-producto">
                                <h2>{item.title}</h2>
                                <PrecioNormal {...item} />
                              </div>
                              <div>
                                <div className="servicio">
                                  <p className="title-servicio">
                                    Servicio incluye:
                                  </p>
                                  {typeof item.servicio !== 'undefined' ? (
                                    item.servicio.length > 0 &&
                                    item.servicio.map((item, index) => (
                                      <div
                                        key={index}
                                        className="box-detalle__icon_detalle"
                                        dangerouslySetInnerHTML={{
                                          __html: `${item.description}`,
                                        }}
                                      ></div>
                                    ))
                                  ) : (
                                    <div className="box-detalle__icon_detalle">
                                      <p>No tiene servicio disponible</p>
                                    </div>
                                  )}
                                  <ul>
                                    <BtnWhatsapp
                                      text={'Asistencia compra en línea'}
                                    />
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="box-form-contratar">
                    <div>
                      <h3>INGRESA TUS DATOS</h3>
                    </div>
                    <div className="row">
                      <div className="offset-md-3 col-xs-12 col-md-6">
                        <FormularioContratar {...plan_selected} />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row text-bajada-plan">
                    <div className="col-12">
                      <div className="seleccione-plan">
                        <h2>Conoce nuestros productos</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {items &&
                      items.length > 0 &&
                      items.map((item, index) => (
                        <>
                          {index === 3 && (
                            <div className="col-12">
                              <div className="seleccione-plan">
                                <h2>Conoce nuestros planes</h2>
                              </div>
                            </div>
                          )}
                          <div className="col-12 col-md-4" key={index}>
                            <div className="box-product">
                              <img
                                src={item.portada}
                                alt={item.title}
                                className="item-image"
                              />
                              <div className="info-producto">
                                <h2>{item.title}</h2>
                                <PrecioNormal {...item} />
                              </div>
                              <div className="box-detalle">
                                {typeof item.detalle !== 'undefined' ? (
                                  item.detalle.length > 0 &&
                                  item.detalle.map((item2, index2) => (
                                    <div
                                      key={index2}
                                      className="box-detalle__icon_detalle"
                                      dangerouslySetInnerHTML={{
                                        __html: `${item2.description}`,
                                      }}
                                    ></div>
                                  ))
                                ) : (
                                  <div className="box-detalle__icon_detalle">
                                    <p>No tiene descripción disponible</p>
                                  </div>
                                )}
                              </div>
                              <div className="content-button text-center">
                                <button
                                  className="btn-verde button btn btn-secondary"
                                  onClick={(e) => {
                                    this.clickVerPlan(e, item);
                                  }}
                                >
                                  Contratar
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <p>Servicio disponible solo en Parque Sendero Maipú </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div style={sectionStyleContactanos} className="fond_whatsapp">
              <div className="container">
                <div className="row">
                  <div className="col-md-5">
                    <h2>Asistencia compra en línea</h2>
                    <BtnWhatsapp text={'Contáctanos'} />
                  </div>
                  <div className="col-md-7"></div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div style={sectionStyleCoti}>
            <div className="container compra-linea">
              <div className="row">
                <div className="offset-md-2 col-md-8 col-xs-12 text-center">
                  <h3 className="title-green">Te ayudamos a encontrar la</h3>
                  <br />
                  <h3 className="title-green">
                    solución que tu familia necesita
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="offset-md-3 col-md-6 col-xs-12">
                  <div className="content box" style={sectionStyle}>
                    <div>
                      {option_active !== 0 && (
                        <span className="back" onClick={this.clickVolver}>
                          {'<'}
                        </span>
                      )}
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: `${data_options[option_active].title}`,
                        }}
                      ></h3>
                      {data_options[option_active].option.map((item, index) => (
                        <button
                          className="btn-verde button btn btn-secondary"
                          key={index}
                          onClick={(e) => {
                            this.onClick(
                              e,
                              option_active,
                              index,
                              item.name,
                              item.id
                            );
                          }}
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default CompraLinea;
