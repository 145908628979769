import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import BannerTop from '../components/BannerTop';
/* Import Imagenes */
import Parques from './../assets/images/Contacto.jpg';
import Icon247 from './../assets/images/icons/24-7.png';

/* Import Component */
 import FormReferidos from '../components/Forms/FormReferidos';
//import FormContacto from '../components/Forms/Contacto';
class Contacto extends Component {
    componentDidMount() {
        document.title = 'Sendero | Referidos'
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Container>
                        <Row>
                            <Col md="12">
                                <FormReferidos
                                    nameButton='ENVIAR'
                                    destino_email='uobkukzx@robot.zapier.com'
                                    type_form='Interesado en :'
                                />
                            </Col>

                        </Row>
                        <br></br>
                    </Container>
                </Container>
            </div>
        );
    }
}
export default Contacto;