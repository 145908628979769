import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import SliderSlick from "react-slick";
import './Gallery.scss';

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        const id = event.currentTarget.dataset.id;
        this.setState({
            isOpen: true,
            photoIndex: id
        });
    }

    render() {
        const { gallery } = this.props.items;
        var images = typeof gallery !== 'undefined' ? JSON.parse(gallery) : 0;
        const { photoIndex, isOpen } = this.state;
        var cantidad = images.length >= 4 ? 4 : images.length;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: cantidad,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <>
                <div className="seccion text-center gallery-parque">
                    <div className="content-title">
                        <Col className="text-center">
                            <h2>GALERÍA DEL <strong>PARQUE</strong></h2>
                        </Col>
                    </div>
                    <SliderSlick ref={c => (this.gallery = c)} {...settings}>
                        {
                            images !== 0 && images.map((item, key) =>
                                <>
                                    <div>
                                        <img data-id={key}
                                            id={key}
                                            src={item}
                                            style={{ width: 100 + "%" }}
                                            onClick={this.handleClick}
                                            alt=""
                                        />
                                    </div>
                                </>
                            )
                        }
                    </SliderSlick>
                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            imageCaption={''}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % images.length,
                                })
                            }
                        />
                    )}
                </div>
            </>
        );
    }
}

export default Gallery;