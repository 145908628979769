import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Api from '../../../service/Api';

class ReglamentoVoyager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: {}
        }
    }
    componentWillMount() {
        this.getReglamentos();
    }
    async getReglamentos() {
        const response = await Api.getReglamentos();
        this.setState({
            items: response
        })
    }

    render() {
        const { items } = this.state;
        return (
            <>
                {items.length > 0 ? (
                    <div className="reglamentos-container">
                        <Row className="botones-reglamentos">
                            {items.map(item => (
                                <Col md={3} className="botones-reglamentos__item">
                                    <a href={item.archivo} target="blank">
                                        <div className="btn-descargar">
                                            {item.parque}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 29.955 29.955"><path className="a" d="M13.074,20.742c1.075,1.075,1.688,1.117,2.806,0l8.22-8.426c.772-1.3.14-2.363-1.4-2.363H19V1.81A1.81,1.81,0,0,0,17.192,0H11.763a1.81,1.81,0,0,0-1.81,1.81V9.953h-3.7c-1.543,0-2.174,1.064-1.4,2.363Zm14.071,3.688H1.81A1.81,1.81,0,0,0,0,26.24v.9a1.81,1.81,0,0,0,1.81,1.81H27.145a1.81,1.81,0,0,0,1.81-1.81v-.9A1.81,1.81,0,0,0,27.145,24.431Z" /></svg>
                                        </div>
                                    </a>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ) : null}
            </>
        );
    }
}
export default ReglamentoVoyager;