import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CEREMONIA_DE_DESPEDIDA from './../../assets/images/Cremacion/CEREMONIA_DE_DESPEDIDA.png';
import ENTREGA_DE_ANFORA from './../../assets/images/Cremacion/ENTREGA_DE_ANFORA.png';
import SERVICIO_DE_CREMACION from './../../assets/images/Cremacion/SERVICIO_DE_CREMACION.png';
import TRAMITACION_SEREMI from './../../assets/images/Cremacion/TRAMITACION_SEREMI.png';
import banner_cremacion from './../../assets/images/banner_ecomerce_cremacion_1920_.jpg';
const service = [
  {
    image: CEREMONIA_DE_DESPEDIDA,
    title: 'CEREMONIA DE DESPEDIDA',
    description:
      'Te entregamos un momento especial y único para que puedas compartir junto a tu familia un cálido homenaje de despedida para tu ser querido.',
    url: '',
  },
  {
    image: SERVICIO_DE_CREMACION,
    title: 'SERVICIO DE CREMACIÓN',
    description:
      'Queremos entregarte un servicio respetuoso en todo momento, por esto nuestros procesos están completamente regulados y son realizados con altos estándares de calidad.',
    url: '',
  },
  {
    image: TRAMITACION_SEREMI,
    title: 'TRAMITACIÓN SEREMI',
    description:
      'Queremos que tú y tu familia puedan despedir con la tranquilidad necesaria a su ser querido, por esto contamos con personal experto que realizará en tu nombre la respectiva tramitación ante el Seremi de Salud.',
    url: '',
  },
  {
    image: ENTREGA_DE_ANFORA,
    title: 'ENTREGA DE ÁNFORA',
    description:
      'Te entregamos un momento especial y único para que puedas compartir junto a tu familia un cálido homenaje de despedida para tu ser querido.',
    url: '',
  },
];
class ServiceCremacion extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center my-3">
              ¿EN QUÉ CONSISTE EL <strong>SERVICIO DE CREMACIÓN?</strong>
            </h2>
            <p>
              La cremación es un proceso que permite la reducción de un cuerpo
              fallecido a cenizas por medio de un horno crematorio que trabaja a
              altas temperaturas. El proceso completo en Sendero, ha sido
              certificado por la norma ISO 9001:2015, la cuál nos entrega un
              reconocimiento de calidad desde la recepción de notificar al
              cliente, hasya la entrega del ánfora. Todo esto, acompañado de una
              atención cercana, sensible y adecuándonos a cada familia de la
              mejor manera posible.
            </p>
          </div>
        </div>
        <div className="row my-4 px-2">
          {service.map((value, key) => (
            <div className="col-12 col-md-3 px-1 mb-3" key={key}>
              <div className="box-service">
                <img src={value.image} alt="" className="img-fluid" />
                <p className="my-2">
                  <strong>{value.title}</strong>
                </p>
                <p className="box-service__description">{value.description}</p>
                {/*<a href={value.url}>Leer más</a>*/}
              </div>
            </div>
          ))}
        </div>
        <div className="row pb-4">
          <div className="col-12">
            <a href="/compra-en-linea">
              <img
                src={banner_cremacion}
                alt="banner-cremacion"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="mt-2">
              En Parque Crematorio Sendero ofrecemos una alternativa moderna y
              con altos estándares de calidad. Contamos con el apoyo de un
              equipo especializado, quienes los asesorarán en todo momento para
              hacer de la ceremonia de despedida un momento único para honrar la
              memoria de sus seres queridos.
            </p>
            <a
              href="https://masinformados.sendero.cl/certificacion-de-calidad-de-cremacion/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="mt-2">
                Conozca nuestro Proceso Certificado por ISO 9001.2015.
              </p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default ServiceCremacion;
