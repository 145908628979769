import React, { Component } from 'react';
import SliderSlick from "react-slick";
import Api from '../service/Api';

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
    this.getSliderHome = this.getSliderHome.bind(this);
  }

  componentDidMount() {
    this.getSliderHome();
  }

  async getSliderHome() {
    const response = await Api.getSlider();
    this.setState({ items: response.result });
  }

  render() {
    const { items } = this.state;
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <SliderSlick ref={c => (this.slider = c)} {...settings}>
        {items.map((item, index) => (
          <a href={item.link} target={item.target} key={index}>
            <img
              src={item.portadamobile}
              alt={item.name}
              style={{ width: 100 + "%" }} />
          </a>
        ))}
      </SliderSlick>
    );
  }
}
export default Slider;