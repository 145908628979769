import React from 'react'
import SliderMobile from './SliderMobile'
import SliderDesktop from './SliderDesktop'

const Slider = () => {
  return (
    <>
    <div className="d-block d-md-none">
        <SliderMobile />
    </div>
    <div className="d-none d-md-block">
        <SliderDesktop />
    </div></>
  )
}

export default Slider