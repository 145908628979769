import React, { Component } from 'react';
import BannerTop from '../../components/BannerTop';
import Banner from './../../assets/images/Cremacion/banner_cremacion_2.jpg';
import Relicarios from './Relicarios';
import ServiceCremacion from './ServiceCremacion';
import './Cremacion.scss';
import Plan from './Plan';
import Anforas from './Anforas';

class Cremacion extends Component {
  render() {
    return (
      <div>
        <BannerTop title1="" title2="" image={Banner} />
        <div className="container">
          {/*<div className="row">
                        <div className="col-12 text-center">
                            <h2>NUESTRO <strong>PLANES</strong></h2>
                            <p>En Sendero, los planes están pensados para cada tipo de familia sin importar su creencia religiosa.</p>
                        </div>
                    </div>
        <Plan />*/}
          <Anforas />
          {/*<Relicarios />*/}
          <ServiceCremacion />
        </div>
      </div>
    );
  }
}
export default Cremacion;
