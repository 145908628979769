import React, { Component } from 'react';

const relicarios = [
    {
        image: '',
        name: 'Relicarios Familiar',
        description: 'Pequeñas ánforas que le permitirá compartir las cenizas de esa persona especial entre varios integrantes de la familia y así conmemorar de una manera única.'
    },
    {
        image: '',
        name: 'Relicarios Familiar',
        description: 'Pequeñas ánforas que le permitirá compartir las cenizas de esa persona especial entre varios integrantes de la familia y así conmemorar de una manera única.'
    },
    {
        image: '',
        name: 'Relicarios Familiar',
        description: 'Pequeñas ánforas que le permitirá compartir las cenizas de esa persona especial entre varios integrantes de la familia y así conmemorar de una manera única.'
    },
    {
        image: '',
        name: 'Relicarios Compañía',
        description: 'Ánfora en forma de joya que le permitirá atesorar en todo momento los recuerdos más preciados junto a su ser querido.'
    }
]
class Relicarios extends Component {

    render() {
        return (
            <>
                <div className="row my-4">
                    <div className="col-12 mb-4">
                        <h2 className="text-center"><strong>RELICARIOS</strong></h2>
                        <p>Una manera especial de homenajear a su ser querido es llevarlo con usted en todo momento. En Crematorio Sendero contamos con hermosos relicarios con diseños exclusivos para recordar y mantener vivo el legado familiar</p>
                    </div>
                    {relicarios.map((value, key) => (
                        <div className="col-12 col-md-3 px-2 text-center" key={key}>
                            <div className="box mb-2">
                                <div className="box__title">
                                    <p>{value.name}</p>
                                </div>
                                <div className="box__content p-2">
                                    <img src={value.image} alt="" />
                                    <p className="mb-2"><strong>{value.name}</strong></p>
                                    <p className="description">{value.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }
}
export default Relicarios;