import React, { Component } from 'react';

import { Row } from 'reactstrap';

import IconComplementario from './../../../../assets/images/icons/Complementario.png';
import IconContratacion from './../../../../assets/images/icons/Contratacion.png';
import IconMantencion from './../../../../assets/images/icons/Mantencion.png';

import FeaturedColorItem from './FeaturedColorItem';

import './FeaturedColor.scss';

class FeaturedColor extends Component {
    render() {
        const content= [
            {
                text1: "Tipos de",
                text2: "Mantenciones",
                icon: IconMantencion,
                link:"/tipos-de-mantenciones",
                style: "back-green"
            },
            {
                text1: "Cotización",
                text2: "Online",
                icon: IconContratacion,
                link: "/sepultura/#escoja-su-cobertura",
                style: "back-blue"
            },
            {
                text1: "Servicios",
                text2: "",
                icon: IconComplementario,
                link: "/servicios",
                style: "back-gris"
            }
        ]
        return (
                <div className="home-featured3">
                    <Row className="home-featured3-container">
                        {content.map((item, index) => (
                            <FeaturedColorItem {...item} key={index}/>
                        ))}
                    </Row>
                </div>
        );
    }
}

export default FeaturedColor;

