import React, { Component } from 'react';

class NotFound extends Component {

    render() {
        return (
            <div className="not-found-container">
                <h2><b>Lo sentimos...</b></h2>
                <h3>Contenido no encontrado</h3>
            </div>
        );
    }
}
export default NotFound;