import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Api from '../../../service/Api';
import Session from '../../../service/Session';
import Layout from '../../Layout'

import ListFinanciamiento from './Components/ListFinanciamiento';
import ListMantencion from './Components/ListMantencion';
import { ViewIvas } from '../../../config';

class ServicioContratado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rut: Session.getInfoUser().rut,
            amount: 0,
            cuotasSeleccionadas: {},
            token_ws: 0,
            url: ''
        }
        this.setForm = this.setForm.bind(this);
        this.calcularTotal = this.calcularTotal.bind(this);
    }

    calcularTotal(e, val, todo) {
        var objeto = {};
        if (e.target.checked) {
            objeto = {
                'NUMERO_CONTRATO': todo.CNTR_NMR,
                'NUMERO_CUOTA': todo.CTAS_NMR,
                'CONCEPTO_RECAUDACION': todo.CONCEPTO,
                'MONTO_CUOTA': todo.CTAS_MNT_SALDO,
                'NUMERO_DOCUMENTO': todo.DCMN_NMR,
                'ESTADO_PAGO': todo.CTAS_IND_ESTADO_PAGO
            };

            this.state.cuotasSeleccionadas[todo.CTAS_NMR + "-" + todo.CONCEPTO + "-" + todo.CNTR_NMR] = objeto;
        } else {
            delete this.state.cuotasSeleccionadas[todo.CTAS_NMR];
        }
        this.setState({
            amount: e.target.checked ? parseInt(this.state.amount) + parseInt(val) : parseInt(this.state.amount) - parseInt(val)
        });
    }

    async setForm() {
        let cuotas = this.state.cuotasSeleccionadas;
        let response = await Api.Pagar(
            {
                rut: this.state.rut,
                amount: this.state.amount,
                cuotasSeleccionadas: JSON.stringify(cuotas)
            }
        );
        this.setState({
            token_ws: response.token_ws,
            url: response.next_page
        });
        document.getElementById("form_webpay").submit();
    }

    renderMensajeIVA() {
        const date = new Date();

        if(date.getFullYear() >= ViewIvas ) {
            return (<>
                    <Row>
                        <Col md={8} className="text-justify">
                            (*) Monto del <strong style={{letterSpacing: '1.5px'}}>IVA A BENEFICIO FISCAL</strong> calculado sobre los precios de los servicios afectos a IVA. Para más información sobre estos servicios visite <a href="https://sendero.cl" target="_blank">www.sendero.cl</a> o comuníquese con nosotros al <span style={{color: '#000',fontWeight: 'bold'}}>600 736 33 76</span> o en cualquiera de nuestras oficinas y cementerios parque.
                        </Col>
                    </Row>
                </>)
        }

        
        return (<></>)
       
    }


    render() {
        let idContrato = this.props.match.params.id;
        let position = this.props.match.params.position;
        console.info(this.state.cuotasSeleccionadas);
        return (
            <>
                <form name="form_webpay" action={this.state.url} method="POST" id="form_webpay">
                    <input type="hidden" name="token_ws" value={this.state.token_ws} />
                </form>
                <Layout name="Resumen financiero">
                    <Row>
                        <Col>
                            <ListFinanciamiento calcularTotal={this.calcularTotal.bind(this)} id={idContrato} position={position} />
                            <ListMantencion calcularTotal={this.calcularTotal.bind(this)} id={idContrato} position={position} />
                        </Col>
                    </Row>
                    {
                        this.renderMensajeIVA()
                    }
                    <Row>
                        <Col md={4} className="text-right content-pagar">
                            <div className="content-text-pagar">
                                <h3>Total a pagar</h3>
                            </div>
                            <div className="content-pagar-valor">
                                <div className="content-precio">
                                    <p>${new Intl.NumberFormat().format(this.state.amount)}.-</p>
                                </div>
                                <div className="content-button">
                                    <Button className="btn btn-verde button btn-secondary" onClick={this.setForm}>PAGAR</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Layout>
            </>
        );
    }
}
export default ServicioContratado;