import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import BannerTop from '../../../components/BannerTop';
import ParqueItem from './ParqueItem';
import data from './data';
import './Parques.scss';

import Parques from './../../../assets/images/Parques/Parques.png';

class Parque extends Component {
    componentDidMount() {
        document.title = 'Sendero | Parques'
        window.scrollTo(0, 0);

    }

    render() {
        return (
            <Container fluid>
                <BannerTop title1="Cementerios parque" title2="sendero" image={Parques} />
                <Container className="parques-container" >
                    <Row>
                        {data.map((item, index) => (
                            <ParqueItem {...item} key={index} />
                        ))}
                    </Row>
                </Container>
            </Container>
        );
    }
}

export default Parque;