import React, { Component } from "react";
import { Row, Col } from "reactstrap";
// import CO from "./../../assets/images/MantecionOfrenda/PLANO_MANTENCION_CO.jpg";
// import SB from "./../../assets/images/MantecionOfrenda/PLANO_MANTENCION_SB.jpg";
// import TE from "./../../assets/images/MantecionOfrenda/PLANO_MANTENCION_TE.jpg";
// import VA from "./../../assets/images/MantecionOfrenda/PLANO_MANTENCION_VA.jpg";
// import MA from "./../../assets/images/MantecionOfrenda/PLANO_MANTENCION_MA.jpg";
// import SA from "./../../assets/images/MantecionOfrenda/PLANO_MANTENCION_SAN_ANTONIO.jpg";


import plano_arica from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_arica.png";
import plano_concepcion from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_concepcion.png";
import plano_iquique from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_iquique.png";
import plano_maipu from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_maipu.png";
import plano_quilpue from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_quilpue.png";
import plano_rancagua from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_rancagua.png";
import plano_san_antonio from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_san_antonio.png";
import plano_temuco from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_temuco.png";
import plano_valparaiso from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_valparaiso.png";
import plano_villa_alemana from "./../../assets/images/MantecionOfrenda/mapas_parques/plano_villa_alemana.png";
import plan_san_bernando from "./../../assets/images/MantecionOfrenda/mapas_parques/plan_san_bernando.png";


const content = [
  {
    id: 0,
    parque: "Seleccionar Parque",
  },
  {
    id: 1,
    parque: 'Parque Arica'
  },
  {
    id: 2,
    parque: 'Parque Concepción'
  },
  {
    id: 3,
    parque: 'Parque Iquique'
  },
  {
    id: 4,
    parque: 'Parque Maipú'
  },
  {
    id: 5,
    parque: 'Parque Quilpue'
  },
  {
    id: 6,
    parque: 'Parque Rancagua'
  },
  {
    id: 7,
    parque: 'Parque San Antonio'
  },
  {
    id: 8,
    parque: 'Parque Temuco'
  },
  {
    id: 9,
    parque: 'Parque Valparaíso'
  },
  {
    id: 10,
    parque: 'Parque Villa Alemana'
  },
  {
    id: 11,
    parque: 'Parque San Bernando'
  },
];

class BoxMapa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parqueSelected: "",
    };
    this.handleFormInput = this.handleFormInput.bind(this);
  }
  handleFormInput(event) {
    const value = event.target.value;
    if (parseInt(value) === 0) {
      this.setState({
        parqueSelected: "",
      });
    }
    if (parseInt(value) === 1) {
      this.setState({
        parqueSelected: plano_arica,
      });
    }
    if (parseInt(value) === 2) {
      this.setState({
        parqueSelected: plano_concepcion,
      });
    }
    if (parseInt(value) === 3) {
      this.setState({
        parqueSelected: plano_iquique,
      });
    }
    if (parseInt(value) === 4) {
      this.setState({
        parqueSelected: plano_maipu,
      });
    }
    if (parseInt(value) === 5) {
      this.setState({
        parqueSelected: plano_quilpue,
      });
    }
    if (parseInt(value) === 6) {
      this.setState({
        parqueSelected: plano_rancagua,
      });
    }


    if (parseInt(value) === 7) {
      this.setState({
        parqueSelected: plano_san_antonio,
      });
    }

    if (parseInt(value) === 8) {
      this.setState({
        parqueSelected: plano_temuco,
      });
    }

    if (parseInt(value) === 9) {
      this.setState({
        parqueSelected: plano_valparaiso,
      });
    }

    if (parseInt(value) === 10) {
      this.setState({
        parqueSelected: plano_villa_alemana,
      });
    }

    if (parseInt(value) === 11) {
      this.setState({
        parqueSelected: plan_san_bernando,
      });
    }

  }

  render() {
    const { parqueSelected } = this.state;
    return (
      <div className="main-mapa">
        <Row>
          <Col className="text-center">
            <h2 className="text-center title-mantencion">
              Calendario de Mantención <strong>por Parque</strong>
            </h2>
            <select
              id="select-parque"
              className="form-control"
              onChange={(e) => this.handleFormInput(e)}
            >
              {content.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.parque}
                </option>
              ))}
            </select>
            <img
              src={parqueSelected}
              className="img-parque-mantencion"
              alt=""
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default BoxMapa;
