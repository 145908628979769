/* Icono Parque */
import icon from './../../../../assets/images/Parques/IconTemuco.png';
/* Logos Iconos Jardines Temáticos */
import sendero from './../../../../assets/images/Parques/icon/sendero.png'; // Parque del Sendero
import privado from './../../../../assets/images/Parques/icon/privado.png'; // Jardin Privado
import noble from './../../../../assets/images/Parques/icon/noble.png'; // Jardin Noble
import nuestramadre from './../../../../assets/images/Parques/icon/nuestramadre.png'; // Jardines Nuestra Madre

import familiar from './../../../../assets/images/Parques/icon/familiar.png'; //Tradición Familiar

/* Fotos Jardines Temáticos */
import img_sendero from './../../../../assets/images/Parques/Temuco/img_sendero.jpg'; // Img Parque del Sendero
// import img_privado from './../../../../assets/images/Parques/Temuco/img_privado.jpg'; // Img Jardin Privado
import img_noble from './../../../../assets/images/Parques/Temuco/img_noble.jpg'; // Img Jardin Noble
import img_nuestramadre from './../../../../assets/images/Parques/Temuco/img_nuestramadre.jpg'; // Img Jardines Nuestra Madre

import img_familiar_new from './../../../../assets/images/Parques/new/img_jardin_familiar.png';  // Img Tradición Familiar
import img_privado_new from './../../../../assets/images/Parques/new/img_jardin_privado.png'; // Img Jardin Privado

export default {
    icon: icon,
    carouselItems: [
        {
            image: img_sendero,
            logo: sendero,
            text: 'Es un espacio que cuenta con grandes áreas verdes enmarcadas por hermosas arboleda.',
            nombre: 'Parque del Sendero',
            link: ''
        },
        {
            image: img_privado_new,
            logo: privado,
            text: 'Un espacio privilegiado donde se resguarda la memoria y el descanso de sus seres queridos.',
            nombre: 'Jardín Privado',
            link: ''
        },
        {
            image: img_noble,
            logo: noble,
            text: 'Conmemore con elegancia y distinción el recuerdo de sus seres queridos. El espacio es resguardado por un cierre perimetral de fierro, con puerta y escaño para uso exclusivo de su familia.',
            nombre: 'Jardín Noble',
            link: ''
        },
        {
            image: img_nuestramadre,
            logo: nuestramadre,
            text: 'Un área ubicada a los pies de la Virgen María en un entorno natural. Un espacio grato y reconfortante para honrar a sus seres queridos al amparo de la Virgen.',
            nombre: 'Jardines Nuestra Madre',
            link: ''
        },
        {
            image: img_familiar_new,
            logo: familiar,
            text: 'Un área diseñado para estar en contacto con la naturaleza. La hermosa fuente de agua que rodea el espacio permite una tranquilidad y conexión con sus seres queridos.',
            nombre: 'Tradición Familiar',
            link: ''
        },
    ],

    jardinesTematicos: [
        sendero,
        privado,
        noble,
        nuestramadre
    ],

}





