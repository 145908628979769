import React, { Component } from 'react';
import ListadoJardines from '../../Jardines';
import './JardinesSection.scss';

class JardinesSection extends Component {
    render() {
        const { items } = this.props;
        return (
            items.length > 0 && (
                <div className="jardines-section">
                    <div className="content-title">
                        <h2 className="lato-light">Descubra nuestros <strong>Jardines Temáticos</strong></h2>
                    </div>
                    <ListadoJardines items={items} slug={this.props.slug} />
                </div>
            )
        );
    }
}

export default JardinesSection;