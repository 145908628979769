import React from 'react';
import {
  Alert,
  Row,
  Col,
  Button,
  FormGroup,
  Modal,
  ModalBody,
} from 'reactstrap';
import Api from '../../service/Api';
import Session from '../../service/Session';
import Recuperar from './PopUp/Recuperar';
import Registrar from './PopUp/Registrar';
import logoSendero from './../../assets/images/logo-sendero.svg';
import Validate from '../../service/Validate';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rut: '',
      password: '',
      modal: typeof props.activo === 'undefined' ? true : false,
      txtMsj: '',
      AlertClass: '',
      nameButton: 'Ingresar',
      disabledButton: true,
      ModalRecuperar: false,
      ModalRegistrar: false,
      iscontrato: props.iscontrato,
    };
    this.toggle = this.toggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setForm = this.setForm.bind(this);
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async setForm() {
    if (this.state.rut === '') {
      this.setState({
        txtMsj: 'Debe ingresar Rut',
        AlertClass: 'alert-danger',
      });
      return false;
    }
    if (this.state.password === '') {
      this.setState({
        txtMsj: 'Debe ingresar Password',
        AlertClass: 'alert-danger',
      });
      return false;
    }

    Session.close();
    this.setState({ nameButton: 'Verificando..', disabledButton: false });
    let response = await Api.Login(this.state);
    if (response.result === 'success') {
      let SToken = response.api_token;
      let SRut = this.state.rut.replace('.', '');
      let SND = '';
      let SUsuario = response[1].cliente.USUARIO;
      let SCorreo = response[1].cliente.CORREO;
      let SEmail = response[2].clienteVoyager.email;
      let SFono = response[2].clienteVoyager.telefono;
      let SSession = 1;
      localStorage.setItem('iscontrato', this.state.iscontrato);

      Session.openTmpPopUp(
        SToken,
        SRut,
        SND,
        SUsuario,
        SCorreo,
        SEmail,
        SFono,
        SSession,
        response.menuprorroga
      );
      let contrato = response[0].contratos;

      let mantencion = response[3].detalleMantencion !== '' ? true : false;

      if (typeof contrato !== 'undefined') {
        this.setState({
          nameButton: 'Ingresar',
          disabledButton: true,
        });
        Session.setInfoContratos(contrato);
        if (mantencion) {
          let pesos = response[3].detalleMantencion.PESOS;
          let uf = response[3].detalleMantencion.UF;
          Session.setInfoMantencion(mantencion, pesos, uf);
        }
        if (parseInt(response.menuprorroga) === 1) {
          window.location.assign('/mi-sendero/postergacion/');
        } else {
          window.location.assign('/mi-sendero/mis-datos/');
        }
      } else {
        this.setState({
          txtMsj: 'No contiene contrato',
          AlertClass: 'alert-danger',
          nameButton: 'Ingresar',
          disabledButton: true,
        });
      }
    } else if (response.result === 'error') {
      this.setState({
        txtMsj: 'Usuario y/o contraseña incorrectos',
        AlertClass: 'alert-danger',
        nameButton: 'Ingresar',
        disabledButton: true,
      });
    } else if (response.result === 'noexiste') {
      this.setState({
        txtMsj:
          'Cliente no registrado, favor regístrese primero para acceder a Mi Sendero.',
        AlertClass: 'alert-danger',
        nameButton: 'Ingresar',
        disabledButton: true,
      });
    }
  }
  handleInputChange(event) {
    this.setState({ txtMsj: '' });
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
    if (name === 'rut') {
      // let valor = event.target.value;
      // valor = valor
      //   .replace(/[.-]/g, "")
      //   .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
      const new_rut = Validate.formatterRut(event.target.value);
      this.setState({
        rut: new_rut,
      });
    }

    if (this.state.rut && this.state.password) {
      this.setState({ disabledButton: true });
    } else {
      this.setState({ disabledButton: false });
    }
  }

  render() {
    const { rut, password, txtMsj, AlertClass, nameButton } = this.state;
    const { registrar } = this.props;
    return (
      <>
        <FormGroup row>
          <Col sm={12}>
            <input
              type="text"
              name="rut"
              id="rut"
              onChange={this.handleInputChange}
              value={rut || ''}
              placeholder="Rut"
              className="form-control"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={12}>
            <input
              type="password"
              name="password"
              id="password"
              onChange={this.handleInputChange}
              value={password || ''}
              placeholder="Clave"
              className="form-control"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={12} className="text-center">
            {txtMsj !== '' && (
              <Alert className={'alert ' + AlertClass}>{txtMsj}</Alert>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={12} className="text-center">
            <Button
              id="consulta_rut"
              onClick={this.setForm}
              className="btn btn-verde btn-block button btn-popup"
            >
              {nameButton}
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <div className="col-12 text-center">
            <a
              href='https://sendero.cl/'
              target="_self"
              className="btn btn-verde btn-block button btn-popup"
              >Volver a Sendero</a>
          </div>
        </FormGroup>
        <Row>
          <Col xs={6} md={6} className="text-left">
            <Recuperar iscontrato={this.state.iscontrato} />
          </Col>
          <Col xs={6} md={6} className="text-right">
            <Registrar iscontrato={this.state.iscontrato} />
          </Col>
        </Row>
      </>
    );
  }
}

export default Home;
