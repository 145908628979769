import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class BoxLists extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { nombre, portada, descripcion } = this.props;
    return (
      <div className={"col-6 text-center"}>
        <div className="box-list">
          <div className="title">
            <div className="row justify-content-center align-items-center">
              <div className="col-4 text-center">
                <img src={portada} alt="" className="img-fluid" />
              </div>
              <div className="col-8 text-center">
                <p>{nombre}</p>
              </div>
            </div>
          </div>
          <div className="content">{ReactHtmlParser(descripcion)}</div>
        </div>
      </div>
    );
  }
}

export default BoxLists;
