import React, { Component } from 'react';
import Api from '../../../../service/Api';
import Session from '../../../../service/Session';
class ListPostergar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cuotasSeleccionadas: {},
			rut: Session.getInfoUser().rut,
			usuario: Session.getInfoUser().usuario,
			email: Session.getInfoUser().email,
			result: 0,
			muestra: true,
			mostrarGrid: false
		}
		this.postergar = this.postergar.bind(this);
		this.onClick = this.onClick.bind(this);
		this.verificarPostergar = this.verificarPostergar.bind(this);
	}
	onClick(e, todo) {
		var objeto = {};
		if (e.target.checked) {
			objeto = {
				'CIA': todo.CIA,
				'CONTRATO': todo.CONTRATO,
				'PARQUE': todo.PARQUE,
				'SERIE': todo.SERIE
			};
			this.state.cuotasSeleccionadas[todo.CONTRATO] = objeto;
		} else {
			delete this.state.cuotasSeleccionadas[todo.CONTRATO];
		}
		this.setState({
			muestra: Object.keys(this.state.cuotasSeleccionadas).length ? false : true
		})
	}
	async postergar() {
		const { cuotasSeleccionadas, rut, email, usuario } = this.state;
		if (typeof cuotasSeleccionadas === '') {
			console.info("debe seleccionar contratos");
			return;
		}
		const data = {};
		data.cuotasSeleccionadas = JSON.stringify(cuotasSeleccionadas);
		data.rut = rut;
		data.email = email;
		data.nombre = usuario;
		let response = await Api.generaProrroga(data);
		this.setState({
			result: response.result
		})
	}
	async verificarPostergar() {
		const { rut } = this.state;
		const data = {};
		data.rut = rut;
		let response = await Api.verificarPostergar(data);
		this.setState({
			mostrarGrid: response.result
		})
	}
	componentDidMount() {
		this.verificarPostergar();
	}
	render() {
		const { contratos } = this.props;
		const { result, muestra, mostrarGrid } = this.state;
		return (
			<>
				{mostrarGrid && (
					<div className="content-box-grilla">
						<h3 className="title-lists">
							Postergar Cuotas
					</h3>
						{result === 0 && (
							<>
								<table className="table" id="table-gray">
									<thead>
										<tr className="head">
											<th scope="col">N° Contrato</th>
											<th scope="col">Seleccionar</th>
										</tr>
									</thead>
									<tbody className="body-tr">
										{
											contratos.length > 0 && (
												contratos.map((todo, index) => (
													todo.prorroga === "SI" && (
														<tr key={index}>
															<td>{todo.CONTRATO}</td>
															<td align="center">
																<input type="checkbox"
																	onClick={
																		(e) => { this.onClick(e, todo) }
																	}
																/>
															</td>
														</tr>
													)
												))
											)
										}
									</tbody>
								</table>
								<div className="row">
									<div className="col-md-12 text-right">
										<button className="btn btn-verde button" onClick={this.postergar} disabled={muestra}>Postergar</button>
									</div>
								</div>
								<br />
							</>
						)}
						{result === 1 && (
							<>
								<div className="alert alert-success">Se encuentra registrada la prorroga de cuotas, y se le envió el comprobante a su mail registrado</div>
							</>
						)
						}
						{result === 100 && (
							<>
								<div className="alert alert-success">Se encuentra registrada la prorroga de cuotas, y se le envió el comprobante a su mail registrado</div>
							</>
						)
						}
					</div>
				)}
			</>
		)
	}
}
export default ListPostergar