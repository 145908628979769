import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Session from './../../../service/Session';
import Api from './../../../service/Api';
import TyC from './TyC';

const SessionMantencion = Session.getMantencion();
const SessionUsuario = Session.getInfoUser();
export default class PopUpMantencion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: SessionUsuario.usuario,
            mantencion: SessionMantencion.mantencion,
            pesos: SessionMantencion.pesos,
            uf: SessionMantencion.uf,
            buttonLabel: 'Mantención Perpetua',
            token_ws: 0,
            url: '',
            nameSi: 'SI QUIERO ESTE BENEFICIO',
            disableSi: false
        };
        this.pagar = this.pagar.bind(this);
    }

    async pagar() {
        this.setState({ nameSi: 'PROCESANDO', disableSi: true });
        let dataContrato = Session.getContratos();
        let dataUsuario = Session.getInfoUser();
        let info = dataContrato.contrato[0];
        info.location = window.location.href;
        info.rut = dataUsuario.rut;
        let response = await Api.PagarMantencion(info);
        this.setState({
            token_ws: response.token_ws,
            url: response.next_page
        });
        document.getElementById("form_webpay_mantencion").submit();

    }
    render() {
        const { nombre, pesos, uf, buttonLabel, url, token_ws, nameSi, disableSi } = this.state;

        return (
            <>
                <form name="form_webpay_mantencion" action={url} method="POST" id="form_webpay_mantencion">
                    <input type="hidden" name="token_ws" value={token_ws} />
                </form>
                <Modal isOpen={false} className="modal-mantencion">
                    <ModalHeader>{buttonLabel}</ModalHeader>
                    <ModalBody>
                        <p>{nombre}, tenemos un beneficio para ti:</p>
                        <p>Obtén hoy tu Mantención Perpetua y olvídate de tu mantención anual para siempre</p>
                        <div className="row">
                            <div className="col-md-6 content-montos sinPadding">
                                <div className="title verde">
                                    VALOR UF
									</div>
                                <div className="price">
                                    <p>UF {uf}</p>
                                </div>
                            </div>
                            <div className="col-md-6 content-montos sinPadding">
                                <div className="title azul">
                                    VALOR EN PESOS
									</div>
                                <div className="price">
                                    <p>${new Intl.NumberFormat().format(pesos)}*</p>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <div className="row footer-mantencion">
                        <div className="col-md-6 text-center sinPadding padding-button">
                            <Button className="btn-mantencion" onClick={this.toggle}>NO QUIERO ESTE BENEFICIO</Button>{' '}
                        </div>
                        <div className="col-md-6 text-center sinPadding padding-button">
                            <Button className="btn-mantencion" onClick={this.pagar} disabled={disableSi}>{nameSi}</Button>
                        </div>
                    </div>
                    <TyC />
                </Modal>
            </>
        );
    }
}