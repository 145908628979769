import React, { Component } from 'react';
// Components
import AppRoutes from './routes';
import packageJson from "../package.json";
import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
}

function MainApp(props) {
	return <AppRoutes />
}

export default App;