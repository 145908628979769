import React, { Component } from 'react'
import Api from './../../../../service/Api';
import Session from '../../../../service/Session';
class List extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: '',
			params: {
				rut: Session.getInfoUser().rut,
				typeGrilla: 'servicio'
			}
		}
	}

	async componentDidMount() {
		let data = await Api.getDataGrilla(this.state.params);
		this.setState({
			items: data
		});
	}

	render() {
		let dataServicio = this.props.items;
		return (
			<>
				<div className="content-box-grilla">
					<table className="table" id="table-gray">
						<tbody className="body-tr">
							<tr>
								<td width="30%">{dataServicio.detalle[dataServicio.CONTRATO][0].JARDIN_TEMATICO}</td>
								<td align="center" width="30%">{dataServicio.detalle[dataServicio.CONTRATO][0].PRODUCTO_COMERCIAL}</td>
								<td align="center" width="40%">Contrato N°: {dataServicio.CONTRATO}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default List