import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
class BoxOption extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { nombre, portada, url, orden } = this.props;
    var color = "";
    switch (orden) {
      case 1:
        color = "#f99422";
        break;
      case 2:
        color = "#00b3d3";
        break;
      case 3:
        color = "#95c11f";
        break;
      default:
        break;
    }
    var sectionStyle = {
      backgroundColor: color,
      padding: "2px 25px",
    };
    var sectionStyleWrapper = {
      padding: "20px 10px",
      height: "auto",
    };
    var section = {
      backgroundColor: color,
      height: "2px",
      display: "block",
      width: "70px",
      textAling: "center",
      margin: "10px auto",
    };
    return (
      <Col xs={12} md={4} className="feature-item">
        <div className="feature-item__wrapper" style={sectionStyleWrapper}>
          <Link to={url} id={nombre}>
            <img src={portada} className="feature-item__icon" alt={nombre} />
          </Link>
          <div style={section}></div>
          <div className="feature-item__title">
            <span className="lato-light">{ReactHtmlParser(nombre)}</span>
          </div>
          <Link
            className="more-info btn button"
            style={sectionStyle}
            to={url}
            smooth="true"
            id={nombre}
          >
            Más Info
          </Link>
        </div>
      </Col>
    );
  }
}

export default BoxOption;
