import React, { Component } from 'react'
import Api from './../../../../service/Api';
import Session from '../../../../service/Session';

class List extends Component {
	constructor(props) {
		super(props)
		this.state = {
			items: [],
			params: {
				typeGrilla: 'financiamiento',
				rut: Session.getInfoUser().rut,
				campana: props.items.CIA,
				parque: props.items.PARQUE,
				codigo: props.items.SERIE,
				numero: props.items.CONTRATO,
			},
			servicioDA: false,
			servicioDV: true,
			montoFin: 0,
			contAnterior: 0,
			contVigente: 0
		}
	}
	async componentDidMount() {
		let data = await Api.getDataGrilla(this.state.params);
		this.setState({
			items: data
		});
	}

	onClick(e, valor, Tipo) {
		if (e.target.checked) {
			if (Tipo === 'FA') {
				this.setState({
					servicioDA: true,
					servicioDV: false
				});
			} else {
				this.setState({
					servicioDA: true,
					servicioDV: false
				});
			}
		} else {
			if (Tipo === 'FV') {
				this.setState({
					servicioDA: false,
					servicioDV: true
				});
			} else {
				this.setState({
					servicioDA: false,
					servicioDV: true
				});
			}
		}
		this.setState({
			montoFin: e.target.checked ? this.state.montoFin + valor : this.state.montoFin - valor
		});
		this.props.calcularTotal(e, valor, Tipo, this.state.params.numero);
	}

	render() {
		const { items, servicioDA, servicioDV } = this.state;
		return (
			<>
				<div className="content-box-grilla">
					<h3 className="title-lists">
						Financiamiento Sendero
					</h3>
					<table className="table" id="table-blue">
						<thead>
							<tr className="head">
								<th scope="col">
									Item
								</th>
								<th scope="col">
									Monto
								</th>
								<th scope="col">
									Seleccionar
								</th>
							</tr>
						</thead>
						<tbody className="body-tr">
							{
								items.length <= 0 ? null : (
									<>
										{parseInt(items.MONTO_MOROSO) === 0 ? null : (
											<tr>
												<td>Saldo Anterior</td>
												<td align="center">${new Intl.NumberFormat().format(items.MONTO_MOROSO)}</td>
												<td align="center">
													<input type="checkbox"
														onClick={
															(e) => { this.onClick(e, parseInt(items.MONTO_MOROSO), "FA") }
														}
														disabled={servicioDA}
													/>
												</td>
											</tr>
										)}
										{parseInt(items.MONTO_VIGENTE) === 0 ? null : (
											<tr>
												<td>Cuota Vigente</td>
												<td align="center">${new Intl.NumberFormat().format(items.MONTO_VIGENTE)}</td>
												<td align="center">
													<input type="checkbox"
														onClick={
															(e) => { this.onClick(e, parseInt(items.MONTO_VIGENTE), "FV") }
														}
														disabled={parseInt(items.MONTO_MOROSO) === 0 ? false : servicioDV}
													/>
												</td>
											</tr>
										)}
									</>
								)
							}
						</tbody>
					</table>
					<div className="content-total-grilla text-right">Total Financiamiento Sendero ${new Intl.NumberFormat().format(this.state.montoFin)}</div>
				</div>
			</>
		)
	}
}
export default List