import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import Api from '../../../service/Api';
import pin_map from './../../../assets/images/icons/pin_map.svg';
import ModalCotizar from './../../../components/PopUpCotizar';

class PopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: {},
      modal: false,
      ActivarCotizarPopUp: false,
    };
    this.getOficinas = this.getOficinas.bind(this);
    this.toggle = this.toggle.bind(this);
    this.activarPopUp = this.activarPopUp.bind(this);
  }
  async getOficinas(parque) {
    const data = {};
    data.parque = parque;
    const response = await Api.getOficinas(data);
    this.setState({
      items: response,
    });
  }

  componentWillReceiveProps(props) {
    this.getOficinas(props.parque);
    this.setState({
      modal: props.estadoModal,
      parque: props.parque,
      NombreReal: props.NombreReal,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      ActivarCotizarPopUp: false,
      items: {},
    }));
  }
  activarPopUp() {
    this.setState({
      ActivarCotizarPopUp: true,
    });
  }
  render() {
    const { items } = this.state;
    let data;
    data = '';
    console.log(items);
    if (items.length > 0) {
      data = items.map((todo) => (
        <div key={todo.tipo} className="data-direccion-oficinas">
          <p className="title-oficinas">
            <strong>{ReactHtmlParser(todo.tipo)}:</strong>
          </p>
          <p>{ReactHtmlParser(todo.direccion)}</p>
          <p>
            <strong>Teléfono: </strong>
            {JSON.parse(todo.telefono).map(function (tel, i) {
              let separator =
                typeof todo.telefono[i + 1] === 'undefined' ? '' : ' – ';
              return (
                <span key={i}>
                  <a href={'tel:' + tel} className="tel-link-color">
                    {tel}
                  </a>
                  {separator}
                </span>
              );
            })}
          </p>
          <p>
            <strong>Horario: </strong> {ReactHtmlParser(todo.horario)}
          </p>
          <p>
            <img className="icon-parque-page" src={pin_map} alt="" />
            <a
              href={todo.mapa}
              target="_blank"
              rel="noopener noreferrer"
              className="link-ubicacion"
            >
              Ver Mapa
            </a>
          </p>
        </div>
      ));
    }
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            <h4 className="text-verde title-popup lato-black">
              {this.props.NombreReal}
            </h4>
            <hr />
            <div className="content-pop-up-oficinas">{data}</div>
            <div className="text-center">
              <span onClick={this.activarPopUp} className="btn button">
                Cotizar
              </span>
            </div>
          </ModalBody>
        </Modal>
        <ModalCotizar
          ActivaPopUp={this.state.ActivarCotizarPopUp}
          producto=""
          tipo="Oficinas"
          destino_email={'hzsame7e@robot.zapier.com'}
          type_form="Formulario de oficinas comerciales"
          hidden={true}
        />
      </>
    );
  }
}

export default PopUp;
