import React, { Component } from 'react';
import { Row } from 'reactstrap';
import ProductItem from './ProductItem';
// Assets
import IconCremacion from './../../../../assets/images/icons/Cremacion.png';
import IconParque from './../../../../assets/images/icons/parque.png';
import IconFunerarios from './../../../../assets/images/icons/funerario.png';
import './Products.scss';

class Products extends Component {
    render() {
        const content = [
            {
                title1: "Parques",
                title2: "",
                info: "Contamos con más de 180 hectáreas disponibles y espacios diseñados para los distintos tipos de requerimientos, creencias y presupuestos.",
                link: "/parques",
                icon: IconParque,
            },
            {
                title1: "Parque",
                title2: "Crematorio",
                info: "Lo invitamos a conocer nuestro Crematorio Sendero, una alternativa moderna y de gran calidad, que mantiene nuestro sello cercano y respetuoso.",
                link: "/parque-crematorio",
                icon: IconCremacion,
            },
            {
                title1: "Servicios",
                title2: "Funerarios",
                info: "En Funeraria Sendero  contamos con personal especializado que hará que la despedida de un ser querido sea un momento especial y de unión familiar",
                link: "/servicios-funerarios",
                icon: IconFunerarios,
            },
        ]

        return (
            <div className="products-home">
                <h2 className="products-home__title">Productos</h2>
                <Row className="products-home__container">
                    {content.map((item, index) => (
                        <ProductItem {...item} key={index} />
                    ))}
                </Row>
            </div>
        );
    }
}

export default Products;