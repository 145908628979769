import React from 'react';
import { Alert, Col, FormGroup, Button } from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import Api from '../../../../service/Api';

class Preguntas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            txtMsj: '',
            AlertClass: 'alert-danger',
            idChallenge: 0,
            type: 'valid',
            validate: 0,
            selectedOption: {},
            loading: false,
        }
        this.setForm = this.setForm.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }
    async componentWillMount() {
        this.setState({
            loading: true,
        });
        const params = {};
        const { rut, nd, usuario, correo } = this.props.dataUser;
        params.Rut = rut;
        params.Nd = nd;
        params.type = this.props.type;
        this.setState({
            rut: rut,
            nd: nd,
            usuario: usuario,
            correo: correo,
        });
        let data = await Api.Preguntas(params);
        if (data.ConsultaResult.codigoRetorno === 10000) {
            this.setState({
                items: data.ConsultaResult.DETALLE.DESAFIO.PREGUNTAS,
                idChallenge: data.ConsultaResult.RESUMEN.IDCHALLENGE,
                loading: false,
            });
        } else {
            let msj = '';
            switch (parseInt(data.ConsultaResult.codigoRetorno)) {
                case 10001:
                    msj = 'Error en los parámetros de entrada 1';
                    break;
                case 10005:
                    msj = 'Rut no válido';
                    break;
                case 10006:
                    msj = 'No es posible generar el desafío';
                    break;
                case 10008:
                    msj = 'La cédula de identidad consultada no se encuentra vigente';
                    break;
                case 10009:
                    msj = 'Servicio disponible solo para personas naturales';
                    break;
                case 10010:
                    msj = 'No es posible realizar una verificación de identidad mediante preguntas para este Rut';
                    break;
                case 10011:
                    msj = 'No es posible generar el desafío por superar máximo de intentos permitidos';
                    break;
                case 10018:
                    msj = 'Registro Civil no se encuentra disponible (si es que valida la cédula)';
                    break;
                default:
                    break;
            }
            this.setState({ txtMsj: msj, validate: 1, loading: false, });
        }
    }

    handleOptionChange(changeEvent, tipo, pregunta) {
        var objeto = {};
        objeto = {
            'CODIGO_PREGUNTA': pregunta.trim(),
            'CODIGO_RESPUESTA': changeEvent.target.value.trim()
        };
        this.state.selectedOption[pregunta] = objeto;
    }

    async setForm() {
        const params = {};
        params.Rut = this.state.rut.replace('.', '');
        params.Nd = this.state.nd;
        params.idChallenge = this.state.idChallenge;
        params.selectedOption = JSON.stringify(this.state.selectedOption);
        params.type = 'valid';
        let data = await Api.ValidarPreguntas(params);
        if (data.ConsultaResult.codigoRetorno === 10000) {
            this.props.siguientePaso(3, this.state);
        } else {
            let msj = '';
            switch (data.ConsultaResult.codigoRetorno) {
                case 10001:
                    msj = 'Error en los parámetros de entrada';
                    break;
                case 10005:
                    msj = 'Rut no válido';
                    break;
                case 10006:
                    msj = 'No es posible generar el desafío';
                    break;
                case 10007:
                    msj = 'Verificación de identidad no exitosa';
                    break;
                case 10012:
                    msj = 'Ha superado el tiempo límite para responder, intente nuevamente';
                    break;
                default:
                    break;
            }
            this.setState({ txtMsj: msj });
        }
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <div className="grilla-loading">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={15}
                            color={'#454847'}
                            loading={this.state.loading}
                        />
                        <p>Cargando...</p>
                    </div>
                ) : (
                    <>
                        {this.state.txtMsj !== "" ? (
                            <FormGroup row>
                                <Col sm={12} className="text-center">
                                    <Alert className={'alert ' + this.state.AlertClass}>{this.state.txtMsj}</Alert>
                                    <br />
                                    <Button className="btn btn-verde button btn-popup" onClick={e => this.props.siguientePaso(1, this.state)}>Volver a intentar</Button>
                                </Col>
                            </FormGroup>
                        ) : (
                            <>
                                <div className="text-center">Por favor responda las siguientes preguntas </div>
                                {
                                    this.state.items.map((gh, i) => (
                                        <div className="container-preguntas" key={i}>
                                            <div className="container-preguntas__title">{gh.PREGUNTA}</div>
                                            {gh.ALTERNATIVAS.map((pr, e) => (
                                                <p key={e}><input type="radio" value={pr.CODIGO_RESPUESTA} name={gh.CODIGO_PREGUNTA} onChange={(ev) => { this.handleOptionChange(ev, i, gh.CODIGO_PREGUNTA) }} /> {pr.RESPUESTA}</p>
                                            ))}
                                        </div>
                                    ))
                                }
                                <FormGroup row>
                                    <Col sm={12}>
                                        {this.state.validate !== 1 && (
                                            <Button className="btn btn-verde button btn-popup" onClick={this.setForm}>Siguiente</Button>
                                        )}
                                    </Col>
                                </FormGroup>
                            </>
                        )}
                    </>
                )}
            </>

        );
    }
}
export default Preguntas;