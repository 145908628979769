import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import plus from '../../../assets/images/icons/plus.svg';
import PopUp from './PopUp.js';
const items = [
    {
        id: "arica",
        name: "Sendero Arica"
    },
    {
        id: "iquique",
        name: "Sendero Iquique"
    },
    {
        id: "villa-alemana",
        name: "Sendero Villa Alemana"
    },
    {
        id: "quilpue",
        name: "Sendero Quilpué"
    },
    {
        id: "valparaiso",
        name: "Sendero Valparaíso"
    },
    {
        id: "san-antonio",
        name: "Sendero San Antonio"
    },
    {
        id: "maipu",
        name: "Sendero Maipú"
    },
    {
        id: "san-bernardo",
        name: "Sendero San Bernardo"
    },
    {
        id: "santiago",
        name: "Sendero Santiago"
    },
    {
        id: "rancagua",
        name: "Sendero Rancagua"
    },
    {
        id: "concepcion",
        name: "Sendero Concepción"
    },
    {
        id: "temuco",
        name: "Sendero Temuco"
    }
];

class OficinasVoyager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Ciudad: '',
        }
    }
    onClick(e, ciudad, RealCiudad) {
        e.preventDefault();
        this.setState({
            Ciudad: ciudad,
            RealCiudad: RealCiudad,
            estadoModal: true
        });
    }
    render() {
        const { Ciudad, RealCiudad, estadoModal } = this.state;
        return (
            <>
                <div className="oficinas px-1">
                    <Row>
                        {items.map(item => (
                            <Col md={3} className="px-1">
                                <div className="btn-descargar" onClick={(e) => { this.onClick(e, item.id, item.name) }}>
                                    {item.name}
                                    <img src={plus} alt="" />
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <PopUp parque={Ciudad} NombreReal={RealCiudad} estadoModal={estadoModal} />
                </div>
            </>
        );
    }
}
export default OficinasVoyager;