import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import Api from "../../service/Api";
import { HashLink as Link } from "react-router-hash-link";
import bannerSuccess from "./../../assets/images/xfMPfDxxYzNjnZkw60xH.png";
import ResponseCodeTransbank from "./../../components/responseCodeTransbank.jsx";
import { useParams } from "react-router-dom";

var sectionStyle = {
  backgroundImage: `url(${bannerSuccess})`,
  backgroundSize: "cover",
  backgroundPosition: "top center",
  backgroundRepeat: "no-repeat",
  backgroundColor: "#fff",
  paddingTop: "200px",
};
const End = () => {
  const [show, setShow] = useState(false);
  const [items, setitems] = useState({});
  let {buyorder, status} = useParams();
  const getDetalle = async () => {
    /*const { location } = this.props;
    const { search } = location;*/

    let data = {};
    data.buyOrder = buyorder;
    let response = await Api.getDetalleEcommerce(data);
    if (response.response === 200) {
      setitems(response.result);
      setShow(false);
    } else {
      setitems({});
      setShow(false);
    }
  };
  useEffect(() => {
    getDetalle();
  }, []);

  return (
    <>
      {show ? (
        <div className="text-center spinner">
          <Spinner style={{ width: "5rem", height: "5rem" }} />
        </div>
      ) : items && items.length > 0 ? (
        <Container>
          <Row>
            <Col>
              <br />
              <div className="detalle">
                <div className="text-center">
                  <div className="circle">
                    <i class="fas fa-check"></i>
                  </div>
                  <div className="text-title-detalle">
                    <br />
                    <h3>
                      La compra ha finalizado con éxito. Muchas gracias por
                      confiar en nosotros.
                      <br />
                      A continuación recibirás un mensaje de bienvenida por
                      Whatsapp, donde podrás contactarte directamente con
                      nuestros ejecutivos en caso de requerirlo.
                      <br />
                      Además, recibirás por correo el detalle del Plan
                      contratado.
                    </h3>
                    <br />
                  </div>
                  <div className="title-detalle">
                    <h3>DETALLE DE TU COMPRA</h3>
                  </div>
                </div>
                <div className="col-12 col-md-8 offset-md-2">
                  <div className="back-detalle">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <th>Numero del pedido</th>
                          <td> : {items[0].buyorder}</td>
                        </tr>
                        <tr>
                          <th>Código de autorización</th>
                          <td> : {items[0].authorizationcode}</td>
                        </tr>
                        <tr>
                          <th>Fecha de pago</th>
                          <td> : {items[0].fecha}</td>
                        </tr>
                        <tr>
                          <th>Monto</th>
                          <td>
                            : $
                            {new Intl.NumberFormat("de-DE").format(
                              items[0].amount
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Compañia</th>
                          <td> : {items[0].compania}</td>
                        </tr>
                        <tr>
                          <th>Parque</th>
                          <td> : {items[0].parque}</td>
                        </tr>
                        <tr>
                          <th>Serie</th>
                          <td> : {items[0].serie}</td>
                        </tr>
                        <tr>
                          <th>Contrato</th>
                          <td> : {items[0].contrato}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="col-12 text-center">
              <br />
              <div style={sectionStyle} className="content-footer-success">
                <div className="fondo-green">
                  <p>
                    Recuerda que también contamos con Servicios Funerarios.
                    Descubre todos los planes de Funeraria Sendero{" "}
                    <a
                      href="https://www.funerariasendero.cl/planes"
                      target="_blank"
                      without
                      rel="noopener noreferrer"
                    >
                      aquí.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <Col className="text-center">
              <br />
              <Link to={"/"} className="btn btn-verde button btn-secondary">
                Volver al inicio
              </Link>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <ResponseCodeTransbank code={status} buyorder={buyorder} />
          <Row>
            <Col>
              <br />
              <Link to={"/"} className="btn btn-verde button btn btn-secondary">
                Volver
              </Link>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default End;
